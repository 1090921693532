import React, {useState} from 'react';
import classNames from 'classnames';
import uuid from 'react-uuid';


interface Props {
	title: string;
	value: number
	setValue: (val: number) => void
	prefix?: string
	percentage?:boolean
	wrapperClasses?: string;
}



export const CurrencyInputField = ({title, setValue, value, prefix, percentage, wrapperClasses}: Props) => {
	const [inputStr, setInputStr] = useState<string|undefined>(()=>{
		if (value === 0.0){
			return '';
		} else{
			return (value/100).toFixed(2);
		}
	});

	function roundToTwo(num:number):number  {
		return +(Math.round(Number(num + 'e+2'))  + 'e-2');
	}

	const onChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
		const newInputStr = event.target.value;
		
		if (!percentage && newInputStr.match(/^\d{1,}([,.]\d{0,2})?$/)) {
			setInputStr(newInputStr);
			const resultingIntNumber = roundToTwo( parseFloat(newInputStr.replace(',','.')) * 100);
			setValue(resultingIntNumber);
			//console.log('resultingIntNumber ', resultingIntNumber);

		} else if (percentage && newInputStr.match(/^\d{0,2}([,.]\d{0,2})?$/) ){
			setInputStr(newInputStr);
			const resultingIntNumber =  roundToTwo( parseFloat(newInputStr.replace(',','.')) * 100);
			setValue(resultingIntNumber);
			//console.log('resultingIntNumber percentage', resultingIntNumber);

		} else if(newInputStr === ''){
			setInputStr('');
			setValue(0);
		}
	};

	return (
		<div className={classNames('flex flex-col', wrapperClasses)}>
			<label className="block text-sm font-medium text-gray-700"> {title} in {prefix} </label>
			<input type="text"
				className={' appearance-none rounded-md border border-gray-300 px-3 py-2 ' +
						'placeholder-gray-400 shadow-sm  sm:text-sm focus:outline-none focus:border-blue-500'}
				onChange={onChangeInput} min="0" value={inputStr} placeholder="0,00"
			/>
		</div>
	);
};

export default CurrencyInputField;
