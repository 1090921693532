import TextArea from '../../../../../components/inputField/TextArea';
import React from 'react';
import classNames from 'classnames';


interface Props {
	shortDescription 	:string
	setShortDescription :React.Dispatch<React.SetStateAction<string>>

	longDescription 	:string
	setLongDescription 	:React.Dispatch<React.SetStateAction<string>>

	wrapperClasses?		: string
}

export default function DescriptionTab(
	{
		shortDescription, setShortDescription,
		longDescription, setLongDescription,
		wrapperClasses
	} : Props) {

	return(
		<div className={classNames(wrapperClasses)}>
			<div className="flex flex-row gap-5">
				<TextArea
					title="Kurzbeschreibung"
					onChange={(e)=>setShortDescription(e.target.value)}
					value={shortDescription}
					rows={20}
				/>

				<TextArea
					title="Ausführliche Beschreibung"
					onChange={(e)=> setLongDescription(e.target.value)}
					value={longDescription}
					rows={20}
				/>
			</div>
		</div>
	);
}

