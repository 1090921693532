import {useGetVariantQuery} from '../../../../api/ReduxAPI';
import ImageGallery from '../../../products/detailedView/components/ImageGallery';
import FilesAndDownloadsTable from '../../../products/detailedView/components/FilesAndDownloadsTable';
import React from 'react';
import {useTranslation} from 'react-i18next';
import ProductPropertiesTable from '../../../products/detailedView/components/ProductPropertiesTable';
import FormatDate from '../../../../utils/date';

interface Props {
	productID: string
    variantID: string;
}

export const VariantDetails = (props:Props) => {
    
	const {t} = useTranslation();
	const {data:variant} = useGetVariantQuery({productID : props.productID, variantID : props.variantID});

	return(
		<div style={{width: '1320px', height: '700px'}}>
			{variant &&
				<div className={'flex flex-col'}>
					<div className={'flex gap-10'}>
						<ImageGallery
							wrapperClasses="w-1/4 self-start"
							imageIds={
								(variant.imageIds && variant.planIds) ?
									variant.imageIds.concat(variant.planIds)
									: variant.imageIds
							}
						/>

						<div className="flex flex-col text-2xl gap-2">
							<div className="flex">
								<div>
									{t('productDetailsWithVariantsTable.name')}:&nbsp; &nbsp;
								</div>
								<div className="text-gray-700">
									{variant.name}
								</div>
							</div>

							<div className="flex">
								<div>
									{t('productDetailsWithVariantsTable.manufacturer')}:&nbsp; &nbsp;
								</div>
								<div className="text-gray-700">
									{variant.manufacturer?.name}
								</div>
							</div>

							<div className="flex">
								<div>
									{t('productDetailsWithVariantsTable.sellerCompany')}:&nbsp; &nbsp;
								</div>
								<div className="text-gray-700">
									{variant.company?.name}
								</div>
							</div>

							<div className="flex">
								<div>
									{t('productDetailsWithVariantsTable.category')}:&nbsp; &nbsp;
								</div>
								<div className="text-gray-700">
									{variant.category?.name}
								</div>
							</div>

							<div className="flex text-xl pt-7">
								<div className="flex flex-col items-start">

									<div className="flex">
										<div className="text-xl font-semibold self-center">
											Preis:
										</div>
										<div className="self-center pt-0.5">
											&nbsp;&nbsp;{variant.price ? ((variant.price / 100) + ' €').replace('.', ',') : ''}
										</div>
									</div>

									<div className="flex flex-col text-red-500">

										<div className="flex">
											<div className="text-xl font-semibold self-center">
												Reduziert:
											</div>
											<div className="self-center pt-0.5">
												&nbsp;&nbsp;{variant.effectiveSale?.price ? (variant.effectiveSale.price / 100 + ' €').replace('.', ',') : '-'}
											</div>
										</div>

										<div className="flex">
											<div className="text-xl font-semibold self-center">
												Rabatt:
											</div>
											<div className="self-center pt-0.5">
												&nbsp;&nbsp;{variant.effectiveSale ? (variant.effectiveSale.percentage / 100 + ' %').replace('.', ',') : '-'}
											</div>
										</div>

										<div className="flex">
											<div className="text-xl font-semibold self-center">
												Rabatt:
											</div>
											<div className="self-center pt-0.5">
												&nbsp;&nbsp;{variant.effectiveSale?.endDate ? FormatDate(variant.effectiveSale.endDate) : '-'}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="pl-5">
							<div className="flex flex-col w-full pb-5">
								<div
									className="text-2xl font-semibold">{t('productDetailsWithVariantsTable.shortDescription')}</div>
								<div>
									{variant.shortDescription}
								</div>
							</div>

							<div className="flex flex-col w-full">
								<div
									className="text-2xl font-semibold">{t('productDetailsWithVariantsTable.longDescription')}</div>
								<div className="break-normal">
									{variant.longDescription}
								</div>
							</div>
						</div>
					</div>

					<div className="pt-10 flex gap-20">
						<div className="w-3/10">
							<div className="flex justify-center text-2xl font-semibold">
                                Technische Daten
							</div>
							<ProductPropertiesTable
								properties={variant.properties}
							/>
						</div>


						<div className={'w-3/10'}>
							<div className="flex justify-center text-2xl font-semibold">
                                Downloads
							</div>

							<FilesAndDownloadsTable
								planIds={variant.planIds}
								dataSheetIds={variant.dataSheetIds}
								dxfFileIds={variant.dxfFileIds}
								stpFileIds={variant.stpFileIds}
							/>
						</div>
					</div>
				</div>
			}
		</div>
	);
};