import React, {useEffect} from 'react';
import { Tab } from '@headlessui/react';
import {endPointGetImage, ENDPOINTS} from '../../../../constants';
import uuid from 'react-uuid';
import classNames from 'classnames';


interface Props {
    imageIds:string[]
    wrapperClasses?	:string
}

function ImageGallery({imageIds, wrapperClasses} : Props) {

	return(
		<div className={classNames(wrapperClasses)}>
			<div className={'w-full h-full'}>
				<Tab.Group as="div" className="flex flex-col-reverse">
					<div className="mx-auto mt-6 hidden w-full max-w-2xl sm:block lg:max-w-none">
						<Tab.List className="grid grid-cols-4 gap-6">
							{imageIds && imageIds.map((imageId:string) => (
								<Tab
									key={imageId}
									className="relative flex h-24 cursor-pointer items-center justify-center rounded-md bg-white text-sm font-medium uppercase text-gray-900 hover:bg-gray-50 focus:outline-none focus:ring focus:ring-opacity-50 focus:ring-offset-4"
								>
									{({ selected }) => (
										<>
											<span className="absolute inset-0 overflow-hidden rounded-md">
												<img
													src={imageId ? endPointGetImage(imageId):''}
													className="h-full w-full object-cover object-center"
													alt={'image preview'}
													id={'img' + uuid()}
												/>
											</span>
											<span
												className={classNames(
													selected ? 'ring-indigo-500' : 'ring-transparent',
													'pointer-events-none absolute inset-0 rounded-md ring-2 ring-offset-2'
												)}
												aria-hidden="true"
											/>
										</>
									)}
								</Tab>
							))}
						</Tab.List>
					</div>

					<Tab.Panels className="aspect-h-1 aspect-w-1 w-full">
						{imageIds && imageIds.map((imageId:string) => (
							<Tab.Panel key={imageId}>
								<img
									src={endPointGetImage(imageId)}
									className="h-full w-full object-cover object-center sm:rounded-lg"
									alt={'image preview'}
									id={'img' + uuid()}
								/>
							</Tab.Panel>
						))}
					</Tab.Panels>
				</Tab.Group>
			</div>
		</div>
	);
}

export default ImageGallery;