import React from 'react';
import {Modal} from '../../../components/modal/Modal';
import IconButton, {IconType} from '../../../components/button/IconButton';
import Button from '../../../components/button/Button';


interface Props {
    isVisible: boolean
    onClose: () => void
    onSuccess: () => void
}

export default function UnlimitedBudgetUserModal({isVisible, onClose, onSuccess}: Props) {

	return (
		<Modal isOpen={isVisible} handleClose={onClose}>
			<div className="relative z-10">
				<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
				<div className="fixed z-10 inset-0 overflow-y-auto">
					<div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">

						<div className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl
      						transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6">


							<IconButton
								wrapperClasses={'flex justify-end'}
								icon={IconType.X_MARK}
								onClick={() => onClose()}/>


                            Alle Bestellungen/Anfragen, welche durch ein zu kleines Budget des Anfragenden oder einer
                            der Vorgesetzten nicht bestätigt werden können, werden zu diesem Nutzer weitergeleitet.
                            Es kann nur einen Benutzer im Unternehmen mit unlimitiertem Budget geben.
                            Sobald sie diesen Nutzer ein unlimitiertes Budget geben, wird es dem anderen Nutzer
                            entzogen.

							<div className="flex justify-end">
								<Button
									wrapperClasses="py-4"
									onClick={() => onSuccess()}
									label="Verstanden"
									icon={IconType.CHECK}/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Modal>
	);
}