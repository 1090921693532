import DataTable, {TableColumn} from 'react-data-table-component';
import React from 'react';
import classNames from 'classnames';
import {Property} from '../../../../interfaces';


const customStyles = {
	head: {
		style: {
			fontSize: '18px',
			fontWeight: 500,
		},
	},
};


interface Props {
    properties      :Property[]
    wrapperClasses?	:string
}


function ProductPropertiesTable ({properties, wrapperClasses} : Props) {

	const colums: TableColumn<Property>[] = [
		{
			name:'Eigenschaft',
			selector: row => row.name,
			center:true,
			width: '180px'
		},
		{
			name:'Wert',
			selector: row => row.value,
			center:true,
			width: '180px'
		},
		{
			name:'Einheit',
			selector: row => row.unit?row.unit:'-',
			center:true,
			width: '180px'
		}
	];

	return (
		<div className={classNames(wrapperClasses)}>
			<DataTable
				columns={colums}
				noDataComponent={<div className={'mt-10'}>Keine technischen Daten vorhanden</div>}
				data={properties.filter(elem => !(elem.name === 'Brand') && !(elem.name === 'brand'))}
				customStyles={customStyles}/>
		</div>
	);
}

export default ProductPropertiesTable;
