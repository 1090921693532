import {VariantBuilder, VariantBuilderState, VariantChangeble} from '../interfaces';
import {Constants} from '../constants';
import Necessity = Constants.Necessity;


export class VariantBuilderMap {

	map: Map<string, VariantBuilder> = new Map<string, VariantBuilder>();

	constructor(variantBuilders?: VariantBuilder[]) {
		if (variantBuilders) {
			for (let i = 0; i < variantBuilders.length; i++) {
				this.map.set(variantBuilders[i].getId(), variantBuilders[i]);
			}
		}
	}

	getAllVariationProperties(excludeKey?: string): (string | number)[] {
		let values: (string | number)[] = [];

		this.map.forEach((builder, key) => {
			if (builder.getState() !== VariantBuilderState.delete &&
				(excludeKey ? (builder.getId() !== excludeKey) : true)) {

				const value: string | number | undefined = builder.getDisplayed().properties
					.find((elem) => elem.necessity === Necessity.Variation)?.value;

				if (value) {
					values = values.concat(value);
				}
			}

		});
		return values;
	}

	getAllNonDeleted(): VariantBuilder[] {
		const builders: VariantBuilder[] = [];
		this.map.forEach((value, key) => {
			if (value.getState() !== VariantBuilderState.delete) {
				builders.push(value);
			}
		});
		return builders;
	}

	getAllChangeablesNonDeleted(): VariantChangeble[] {
		const variantChangebles: VariantChangeble[] = [];
		this.map.forEach((value, key) => {
			if (value.getState() !== VariantBuilderState.delete) {
				variantChangebles.push(value.getChange());
			}
		});
		return variantChangebles;
	}
}