import classNames from 'classnames';
import React, {forwardRef, useEffect, useState} from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import FormatDate from '../../utils/date';

interface Props {
	title:string
	dateString: string
	setDateString:(date: string)=>void
    wrapperClasses?:string
}

export default function CustomDatePicker ({dateString, setDateString, title, wrapperClasses}:Props) {
	const [date, setDate] = useState<Date|null>(
		new Date(
			dateString !== '' ?
				dateString
				:new Date().toDateString()));

	useEffect(() => {
		setDateString(date ? date.toISOString(): '');
	}, [date]);


	const RefInput = forwardRef(({ value, onClick }:any, ref) => (
		<input
			type="text"
			className={'appearance-none rounded-md border border-gray-300 px-3 py-2 ' +
					'placeholder-gray-400 shadow-sm  sm:text-sm focus:outline-none focus:border-blue-500'}
			defaultValue={FormatDate(value)}
			onClick={onClick}
		/>
	));

	return(
		<div className={classNames('relative', wrapperClasses)}>
			<label className="block text-sm font-medium text-gray-700">{title}</label>
			<DatePicker
				className={'relative z-0'}
				onChange={(date) => setDate(date)}
				selected={date}
				customInput={<RefInput/>}/>
		</div>
	);
}