import React, {useState} from 'react';
import Button, {ButtonType} from '../../../components/button/Button';
import {mailRegEx} from '../../../utils';
import axios from 'axios';
import Divider from '../../../components/Divider';
import InputField, {InputFieldType} from '../../../components/inputField/InputField';
import {Modal} from '../../../components/modal/Modal';
import {useAuth} from '../../../providers/auth/useAuth';
import {Constants, ENDPOINTS} from '../../../constants';
import {IconType} from '../../../components/button/IconButton';
import PermissionsSelection from './PermissionsSelection';
import {BudgetRefresh, PermissionGroup, User} from '../../../interfaces';
import SupervisorSelection from './SupervisorSelection';
import Budget from './Budget';


interface Props {
	isVisible: boolean
	onClose: ()=>void
	onSuccess: ()=>void
}

function AddUserModal({isVisible, onClose, onSuccess}:Props) {
	const auth = useAuth();

	const [mail, setMail] = useState<string>('');
	const [firstName, setFirstName] = useState<string>('');
	const [lastName, setLastName] = useState<string>('');
	const [department, setDepartment] = useState<string>('');
	const [pickingNumber, setPickingNumber] = useState<string>('');


	const [hasUnlimitedBudget, setHasUnlimitedBudget] = useState<boolean>(false);
	const [budget, setBudget] = useState<number>(0);

	const [budgetRefresh, setBudgetRefresh] = useState<BudgetRefresh | undefined>(undefined);

	const [selectedSupervisor, setSelectedSupervisor] = useState<User>();
	const [selectedPermissionGroup, setSelectedPermissionGroup] = useState<PermissionGroup | undefined>();
	const [webUsePermission, setWebUsePermission] = useState<boolean>(false);
	const [appUsePermission, setAppUsePermission] = useState<boolean>(false);

	const [inputFieldError, setInputFieldError] = useState<string>('');


	const onFinishButtonClick = async () =>{
		setInputFieldError('');

		if (mail == '') {
			setInputFieldError('E-Mail fehlt');
		} else if (!mailRegEx.test(mail)) {
			setInputFieldError('Ungültige E-Mail');
		}  else if (firstName == '') {
			setInputFieldError('Vorname fehlt');
		} else if (lastName == '') {
			setInputFieldError('Nachname fehlt');
		} else if (selectedPermissionGroup === undefined) {
			setInputFieldError('Berechtigungsgruppe fehlt');
		} else if (department == '') {
			setInputFieldError('Abteilung fehlt');
		} else if (budget > 100000000) {
			setInputFieldError('Budget darf 1000000€ nicht überschreit');
		} else if (!selectedSupervisor && selectedPermissionGroup.name === Constants.PermissionGroupNames.Worker) {
			setInputFieldError('Berechtigungsgruppe braucht einen Vorgesetzten');
		} else {
			axios.post(ENDPOINTS.inviteUser,
				{
					'mail': mail,
					'firstName': firstName,
					'lastName': lastName,
					'department': department,
					'supervisorId':selectedSupervisor?.id,
					'pickingNumber':pickingNumber,
					'budget': budget * 100,
					'budgetRefresh': budgetRefresh,
					'permissionGroupId': selectedPermissionGroup?.id,
					'unlimitedBudget': hasUnlimitedBudget
				})
				.then(function (response) {
					onSuccess();
				})
				.catch(error => {
					if (error.response) {
						setInputFieldError('Nutzer einladen fehlgeschlagen. \n' + String(error.response.data.errorMessage));
					}
				});
		}

		return false;
	};

	const closeModal = () => {
		onClose();
	};

	return (
		<Modal isOpen={isVisible} handleClose={closeModal}>
			<div className="relative z-10">
				<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
				<div className="fixed z-10 inset-0 overflow-y-auto">
					<div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">

						<div className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl
						transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6">



							<div className="flex flex-row justify-end">
								<button type="button" className="text-2xl" onClick={()=>closeModal()}>X</button>
							</div>

							<Divider title="Benutzerdaten"/>
							<InputField
								wrapperclasses="py-2"
								type={InputFieldType.TEXT}
								value={mail}
								title="E-Mail"
								onChange={(e)=>setMail(e.target.value)}/>

							<div className="flex flex-row py-2">
								<InputField
									wrapperclasses="w-1/2 py-2"
									type={InputFieldType.TEXT}
									value={firstName}
									title="Vorname"
									onChange={(e)=>setFirstName(e.target.value)}/>

								<InputField
									wrapperclasses="w-1/2 py-2"
									type={InputFieldType.TEXT}
									value={lastName}
									title="Nachname"
									onChange={(e)=>setLastName(e.target.value)}/>
							</div>

							<InputField
								wrapperclasses="pb-2"
								type={InputFieldType.TEXT}
								value={department}
								title="Abteilung"
								onChange={(e)=>setDepartment(e.target.value)}/>

							<InputField
								wrapperclasses="pb-2"
								type={InputFieldType.TEXT}
								value={pickingNumber}
								title="Komissionierungsnummer"
								onChange={(e)=>setPickingNumber(e.target.value)}/>

							<SupervisorSelection
								selectedSupervisor={selectedSupervisor}
								setSelectedSupervisor={setSelectedSupervisor}
								wrapperClasses="py-2"/>

							<PermissionsSelection
								appUsePermission={appUsePermission}
								setAppUsePermission={setAppUsePermission}
								webUsePermission={webUsePermission}
								setWebUsePermission={setWebUsePermission}
								selectedPermissionGroup={selectedPermissionGroup}
								setSelectedPermissionGroup={setSelectedPermissionGroup}/>

							<Budget
								hasUnlimitedBudget={hasUnlimitedBudget}
								setHasUnlimitedBudget={setHasUnlimitedBudget}
								initialBudget={budget}
								setInitialBudget={setBudget}
								budgetRefresh={budgetRefresh}
								setBudgetRefresh={setBudgetRefresh}

							/>

							<div className="py-4">
								<Button
									label="Fertig"
									icon={IconType.CHECK}
									onClick={onFinishButtonClick}
									className="flex w-full justify-center rounded-md border border-transparent
											bg-blue-800 py-2 px-4 text-sm font-medium text-white shadow-sm
											hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-indigo-500
											focus:ring-offset-2"
								/>
							</div>

							{inputFieldError != '' &&
								<div className="flex justify-center">
									<div className="text-red-600 py-2">{inputFieldError}</div>
								</div>
							}
						</div>
					</div>
				</div>
			</div>
		</Modal>
	);
}

export default AddUserModal;
