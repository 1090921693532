import React, { FC } from 'react';
import classNames from 'classnames';


interface DividerProps {
	title?: string;
	wrapperClasses?: string;
}

const Divider: FC<DividerProps> = (props) => {
	return (
		<div className={classNames(props.wrapperClasses)}>
			<div className="relative flex items-center">
				<div className="flex-grow border-t border-gray-400 "></div>
				{props.title && <span className="flex-shrink mx-4 text-black"> {props.title}</span>}
				<div className="flex-grow border-t border-gray-400"></div>
			</div>
		</div>
	);
};

export default Divider;