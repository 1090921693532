import classNames from 'classnames';
import React, {useEffect, useState} from 'react';
import ComboBox from '../comboBox/ComboBox';
import SearchBar from './SearchBar';


export interface SearchBarItem {
	key: string,
	label: string
}

interface Props {
	searchFields: SearchBarItem[]
	onChange: (query: { [key: string]: string }) => void
    wrapperClasses?: string
}

export default function MultiSearchBar(
	{
		searchFields,
		onChange,
		wrapperClasses
	}: Props) {

	const [selectedSearchField, setSelectedSearchField] = useState<SearchBarItem>(searchFields[0]);
	const [searchValue, setSearchValue] = useState<string>('');

	useEffect(() => {
		onChange({[selectedSearchField.key]: searchValue});
	}, [searchValue, selectedSearchField]);


	return (
		<div className={classNames(wrapperClasses)}>
			<div className={'w-full flex gap-2'}>
				<ComboBox
					wrapperClasses={'w-1/3'}
					title={'Suchfeld'}
					items={searchFields.map(object => ({key: object.key, label: object.label, object: object}))}
					onChangeSelected={setSelectedSearchField}/>

				<SearchBar
					wrapperClasses={'w-2/3'}
					searchStr={searchValue}
					setSearchStr={setSearchValue}/>
			</div>
		</div>
	);
}