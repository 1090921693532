export type BudgetRefresh = {
    value: number
    interval: BudgetInterval
}

export enum BudgetInterval {
    Month = 'MONTH',
    Quarter = 'QUARTER',
    Year = 'YEAR'
}

export const GetBudgetInterval = (key: string) => {
	switch (key) {
	case BudgetInterval.Month:
		return BudgetInterval.Month;
	case BudgetInterval.Quarter:
		return BudgetInterval.Quarter;
	case BudgetInterval.Year:
		return BudgetInterval.Year;
	default:
		throw new Error('key is not a valid budget intervall');
	}
};