import {ChatMessageView} from './ChatMessageHistory';
import {endPointGetImage} from '../../constants';
import {ImageDetailedView} from '../imageDetailedView/imageDetailedView';
import {FormatDateToTime} from '../../utils';
import React, {useEffect, useState} from 'react';
import {ArrowDownTrayIcon} from '@heroicons/react/20/solid';
import axios from 'axios';
import {useLazyGetFileQuery} from '../../api/ReduxAPI';
import {FileInterface} from '../../interfaces';

interface Props {
	ownMessage:boolean
    message:ChatMessageView
}

export const ChatMessage = (props:Props) => {

	const [imageDetailedView, setImageDetailedView] = useState<string>('');
	const [tmpFiles, setTmpFiles] = useState<FileInterface[]>([]);

	const [getFile, {isSuccess}] = useLazyGetFileQuery();

	useEffect(() => {
		const fetchTmpFiles = async () => {
			if (props.message.fileIDs) {

				let newTmpFiles:FileInterface[] = [];

				for (let i = 0; i < props.message.fileIDs.length; i++) {

					const res:FileInterface = await getFile({fileID: props.message.fileIDs[i], withoutData:true}).unwrap();

					newTmpFiles = newTmpFiles.concat(res);
				}
				console.log('set fetched file', newTmpFiles);
				setTmpFiles(newTmpFiles);
			}
		};
		fetchTmpFiles();
	}, []);


	useEffect(() => {
		console.log('tmpFiles', tmpFiles, props.message.fileIDs);
	}, [tmpFiles]);

	return (
		<div className={`w-full flex flex-col ${props.ownMessage ? 'bg-blue-100' : 'bg-gray-100'} border rounded-lg border-b-gray-200`}>

			{tmpFiles.length > 0 &&
				<div className={'flex flex-col items-end gap-2 p-2'}>
					{tmpFiles.map((file:FileInterface) => {

						return <div
							key={file.id}
							className={`p-2 flex ${props.ownMessage ? 'bg-blue-200' : 'bg-gray-200'} border border-gray-200 rounded-md`}
						>
							<div className={'text-sm pr-1 truncate'}>
								{file?.name}
							</div>
							<ArrowDownTrayIcon
								className="h-4 w-4 hover:text-gray-600 cursor-pointer"
								aria-hidden="true"
								type={'button'}
								onClick={() => window.open(axios.defaults.baseURL + '/files/' + file.id + '/download', '_blank')}
							/>
						</div>;
					})
					}
				</div>
			}

			{props.message.imageIDs && props.message.imageIDs.length > 0 &&
					<div className={'flex flex-col'}>
						<div className={'flex gap-1 pl-4 py-2'}>
							{props.message.imageIDs.map((imageID) =>
								<div key={imageID}>
									<img
										src={endPointGetImage(imageID)}
										className="w-20 h-20 object-scale-down cursor-pointer"
										alt={'File'}
										id={imageID}
										onClick={() => setImageDetailedView(imageID)}
									/>
									{imageID === imageDetailedView &&
								<ImageDetailedView
									imageID={imageID}
									isVisible={imageDetailedView !== ''}
									onClose={() => setImageDetailedView('')}
								/>
									}
								</div>
							)}
						</div>
					</div>
			}



			<div className={'pl-4 py-2 break-normal break-all'}>
				{props.message.text}
			</div>


			<div className={'text-xs pl-2 pr-1 pb-1 text-gray-400 self-end'}>
				{FormatDateToTime(props.message.created, 'de-DE')}
			</div>
		</div>
	);
};