import classNames from 'classnames';
import Divider from '../../../../../../components/Divider';
import EditImageSelectionList from './components/EditImageSelectionList';
import {Constants} from '../../../../../../constants';
import React from 'react';
import {FileInterface} from '../../../../../../interfaces';

interface Props {
    imageIds: string[]
    setImageIds: React.Dispatch<React.SetStateAction<string[]>>

    planIds: string[]
    setPlanIds: React.Dispatch<React.SetStateAction<string[]>>

    images: FileInterface[]
    setImages: React.Dispatch<React.SetStateAction<FileInterface[]>>

    plans: FileInterface[]
    setPlans: React.Dispatch<React.SetStateAction<FileInterface[]>>

    wrapperClasses?: string
}


export default function EditProductImageTab(
	{
		imageIds, setImageIds,
		planIds, setPlanIds,
		images, setImages,
		plans, setPlans,
		wrapperClasses
	}: Props) {


	return (
		<div className={classNames(wrapperClasses)}>
			<div className={'flex gap-16'}>
				<div className={'flex-1'}>
					<Divider title="Produktbilder"/>
					<EditImageSelectionList
						wrapperClasses="py-4"
						imageIds={imageIds}
						setImageIds={setImageIds}
						files={images}
						setFiles={setImages}
						fileType={Constants.FileType.ProductImage}/>
				</div>

				<div className={'flex-1'}>
					<Divider title="Technische Zeichnungen"/>
					<EditImageSelectionList
						wrapperClasses="py-4"
						imageIds={planIds}
						setImageIds={setPlanIds}
						files={plans}
						setFiles={setPlans}
						fileType={Constants.FileType.ProductPlan}/>
				</div>
			</div>
		</div>
	);
}