import classNames from 'classnames';
import React, {useState} from 'react';
import InputField, {InputFieldType,} from '../../../../../components/inputField/InputField';
import ManufacturerSelection from '../../components/ManufacturerSelection';
import {Manufacturer} from '../../../../../interfaces';
import CurrencyInputField from '../../../../../components/inputField/CurrencyInputField';
import CustomDatePicker from '../../../../../components/datePicker/CustomDatePicker';
import CheckBox from '../../../../../components/checkBox/CheckBox';
import IntegerInputField from '../../../../../components/inputField/IntegerInputField';


interface Props {
    hasVariants: boolean;

    name: string;
    setName: React.Dispatch<React.SetStateAction<string>>;

    manufacturer: Manufacturer | undefined;
    setManufacturer: React.Dispatch<React.SetStateAction<Manufacturer | undefined>>;

    manufacturerArticleNumber: string;
    setManufacturerArticleNumber: React.Dispatch<React.SetStateAction<string>>;

    isActive: boolean;
    setIsActive: React.Dispatch<React.SetStateAction<boolean>>;

    amountAvailable: number;
    setAmountAvailable: React.Dispatch<React.SetStateAction<number>>;

    price: number;
    setPrice: React.Dispatch<React.SetStateAction<number>>;

	salePercentage: number | undefined
	setSalePercentage: React.Dispatch<React.SetStateAction<number | undefined>>

	saleEndDate: string | undefined
	setSaleEndDate: React.Dispatch<React.SetStateAction<string | undefined>>

    wrapperClasses?: string;
}

export default function CreateProductInformationTab(
	{
		wrapperClasses,
		hasVariants,
		name, setName,
		manufacturer, setManufacturer,
		manufacturerArticleNumber, setManufacturerArticleNumber,
		isActive, setIsActive,
		amountAvailable, setAmountAvailable,
		price, setPrice,
		salePercentage, setSalePercentage,
		saleEndDate, setSaleEndDate
	}: Props) {

	const [isForSale, setIsForSale] = useState<boolean>(false);


	const onChangeIsForSale = (checked: boolean) => {
		setIsForSale(checked);
		if (!checked && setSalePercentage && setSaleEndDate) {
			setSalePercentage(undefined);
			setSaleEndDate(undefined);
		}
	};


	return (
		<div className={classNames(wrapperClasses)}>
			<div className={'flex gap-16'}>
				<div>
					<InputField
						type={InputFieldType.TEXT}
						value={name}
						title="Name"
						onChange={(e) => setName(e.target.value)}/>

					<ManufacturerSelection
						wrapperClasses={'pt-4'}
						selectedManufacturer={manufacturer}
						setSelectedManufacturer={setManufacturer}/>
				</div>

				{!hasVariants &&
					<>
						<InputField
							type={InputFieldType.TEXT}
							value={manufacturerArticleNumber}
							title="Herstellernummer"
							onChange={(e) => setManufacturerArticleNumber(e.target.value)}/>

						<div className={'flex flex-col gap-4'}>
							<CurrencyInputField
								title="Preis"
								prefix="€"
								value={price}
								setValue={setPrice}/>


							<CheckBox
								wrapperClasses={'mt-2'}
								label={'Produkt wird im Sale angeboten?'}
								checked={isForSale}
								onChange={(e) => onChangeIsForSale(e.target.checked)}/>

							{isForSale &&
								<>
									<CurrencyInputField
										title="Reduziert"
										prefix="%"
										percentage={true}
										value={salePercentage ?? 0}
										setValue={(value) => setSalePercentage(value)}/>

									<CustomDatePicker
										dateString={saleEndDate ?? ''}
										setDateString={(date) => setSaleEndDate(date)}
										title={'Angebotsende'}/>
								</>
							}
						</div>

						<IntegerInputField
							title="Stückzahl"
							value={amountAvailable}
							setValue={setAmountAvailable}/>

						<CheckBox
							wrapperClasses="pt-6"
							label={'Status / Sichtbar auf dem Marktplatz?'}
							checked={isActive}
							onChange={(e) => {
								setIsActive(e.target.checked);
							}}
						/>
					</>
				}
			</div>
		</div>
	);
}
