import React, {useState} from 'react';
import ImageGallery from '../../../products/detailedView/components/ImageGallery';
import {useGetProductQuery, } from '../../../../api/ReduxAPI';
import {useTranslation} from 'react-i18next';
import DataTable, {TableColumn} from 'react-data-table-component';
import {Variant} from '../../../../interfaces';
import {Constants, endPointGetImage} from '../../../../constants';
import uuid from 'react-uuid';
import FormatDate from '../../../../utils/date';
import Necessity = Constants.Necessity;
import FilesAndDownloadsTable from '../../../products/detailedView/components/FilesAndDownloadsTable';
import {ModalV2} from '../../../../components/modal/ModalV2';
import {VariantDetails} from './VariantDetails';

interface Props {
	productID:string
	concerningVariantIDs?:string[]
}

export const ProductDetailsWithVariantsTable = (props:Props) => {

	const {t} = useTranslation();
	const {data:product} = useGetProductQuery({productID : props.productID});

	const [variantIdDetailModal, setVariantIdDetailModal] = useState<string>('');

	const colums: TableColumn<Variant>[] = [
		{
			name: t('productDetailsWithVariantsTable.table.image'),
			cell: (row, rowIndex, column) => (
				<div>
					{row.imageIds &&
						<img
							src={endPointGetImage(row.imageIds[0])}
							className="w-20 h-20 object-scale-down"
							alt={'image preview'}
							id={'img' + uuid()}
						/>
					}
				</div>
			),
			center: true,
			width: '180px',
		},
		{
			name: t('productDetailsWithVariantsTable.table.variantProperty') + ' (' + product?.variationProperty?.name + ')',
			cell: (row, rowIndex, column) => (
				<div>
					{row.properties?.find(elem=>elem.necessity === Necessity.Variation)?.value}
				</div>
			),
			center:true,
			width: '240px'
		},
		{
			name:t('productDetailsWithVariantsTable.table.articleNumber') ,
			selector: row => row.articleNumber,
			center:true,
			width: '180px'
		},
		{
			name:t('productDetailsWithVariantsTable.table.manufacturerArticleNumber') ,
			selector: row => row.manufacturerArticleNumber,
			center:true,
			width: '180px'
		},
		{
			name:'Status',
			cell: (row, rowIndex, column) =>
				<div className="flex flex-col items-center">
					{row.active?
						<span className="inline-flex rounded-full bg-green-200 px-2 font-semibold leading-5 text-black">
							Aktiv
						</span>:
						<span className="inline-flex rounded-full bg-yellow-300 px-2 font-semibold leading-5 text-black">
							Inaktiv
						</span>
					}
				</div>,
			center:true,
			width: '100px'
		},
		{
			name:t('productDetailsWithVariantsTable.table.amountAvailable') ,
			selector: row => row.amountAvailable,
			center:true,
			width: '180px'
		},
		{
			name:t('productDetailsWithVariantsTable.table.price') ,
			cell: (row, rowIndex, column) =>

				<div className="flex flex-col">
					<div>
						{t('productDetailsWithVariantsTable.table.price')}: {row.price? ((row.price/100) + ' €').replace('.',',') :''}
					</div>

					<div className="flex flex-col text-red-500">
						<div>
							{t('productDetailsWithVariantsTable.table.reduced')}: {row.effectiveSale?.price ? (row.effectiveSale.price / 100 + ' €').replace('.', ',') : '-'}
						</div>
						<div>
							{t('productDetailsWithVariantsTable.table.reducedPercentage')}: {row.effectiveSale ? (row.effectiveSale.percentage / 100 + ' %').replace('.', ',') : '-'}
						</div>
						<div>
							{t('productDetailsWithVariantsTable.table.saleEnddate')}: {row.effectiveSale?.endDate ? FormatDate(row.effectiveSale.endDate) : '-'}
						</div>
					</div>
				</div>,
			center:true,
			width:'180px'
		},
	];

	return(
		<div className={'flex flex-col'} style={{width: '1320px', height: '700px'}}>
			{product &&
				<div className={'w-full h-full'}>
					<div className={'flex gap-10'}>
						<ImageGallery
							wrapperClasses="w-1/4 self-start"
							imageIds={
								(product.imageIds && product.planIds) ?
									product.imageIds.concat(product.planIds)
									: product.imageIds
							}
						/>

						<div className="flex flex-col text-2xl gap-2">
							<div className="flex">
								<div>
									{t('productDetailsWithVariantsTable.name')}:&nbsp; &nbsp;
								</div>
								<div className="text-gray-700">
									{product.name}
								</div>
							</div>

							<div className="flex">
								<div>
									{t('productDetailsWithVariantsTable.manufacturer')}:&nbsp; &nbsp;
								</div>
								<div className="text-gray-700">
									{product.manufacturer?.name}
								</div>
							</div>

							<div className="flex">
								<div>
									{t('productDetailsWithVariantsTable.sellerCompany')}:&nbsp; &nbsp;
								</div>
								<div className="text-gray-700">
									{product.company?.name}
								</div>
							</div>

							<div className="flex">
								<div>
									{t('productDetailsWithVariantsTable.category')}:&nbsp; &nbsp;
								</div>
								<div className="text-gray-700">
									{product.category?.name}
								</div>
							</div>

							<div>
								<div className="text-2xl font-semibold">
									{t('productDetailsWithVariantsTable.downloads')}:
								</div>

								<FilesAndDownloadsTable
									planIds={product.planIds}
									dataSheetIds={product.dataSheetIds}
									dxfFileIds={product.dxfFileIds}
									stpFileIds={product.stpFileIds}
								/>
							</div>
						</div>

						<div className="pl-5">
							<div className="flex flex-col w-full pb-5">
								<div className="text-2xl font-semibold">{t('productDetailsWithVariantsTable.shortDescription')}</div>
								<div>
									{product.shortDescription}
								</div>
							</div>

							<div className="flex flex-col w-full">
								<div className="text-2xl font-semibold">{t('productDetailsWithVariantsTable.longDescription')}</div>
								<div className="break-normal">
									{product.longDescription}
								</div>
							</div>
						</div>
					</div>

					<div className={'text-2xl pt-10'}>
						{t('productDetailsWithVariantsTable.variants')}
					</div>

					<div className={'w-full h-full'}>
						<DataTable
							columns={colums}
							data={product.variants}
							pointerOnHover={true}
							onRowClicked={(row) => setVariantIdDetailModal(row.id)}
						/>


						<ModalV2
							isOpen={variantIdDetailModal !== ''}
							handleClose={()=>setVariantIdDetailModal('')}
						>
							<VariantDetails
								productID={props.productID}
								variantID={variantIdDetailModal}
							/>
						</ModalV2>
					</div>
				</div>
			}
		</div>
	);
};