import {Outlet, useNavigate, useParams} from 'react-router-dom';
import React from 'react';
import {APP_ROUTES, routeVariantsOverview} from '../../../../constants';
import {ChevronRightIcon} from '@heroicons/react/20/solid';

export default function DetailedVariantHeader(){
	const {productId} = useParams<string>();
	const {productName} = useParams<string>();
	const {variantValue} = useParams<string>();
	const {variantPropertyName} = useParams<string>();

	const navigate = useNavigate();

	return(
		<>
			<div className="flex justify-start pl-8">
				<h1 className="text-2xl self-center cursor-pointer hover:text-gray-500"
					onClick={() => navigate(APP_ROUTES.products)}>Produkte
				</h1>

				<div className="self-center px-1.5 pt-1"><ChevronRightIcon width={30}/></div>
				<h1 className="text-2xl self-center cursor-pointer hover:text-gray-500"
					onClick={() => navigate(routeVariantsOverview(productId??'', productName??''))}
				>
					{productName}
				</h1>

				<div className="self-center px-1.5 pt-1"><ChevronRightIcon width={30}/></div>
				<h1 className="text-2xl text-gray-500 self-center">{variantPropertyName}</h1>

				<div className="self-center px-1.5 pt-1"><ChevronRightIcon width={30}/></div>
				<h1 className="text-2xl text-gray-500 self-center">{variantValue}</h1>

			</div>
			<div>
				<Outlet/>
			</div>
		</>
	);
}