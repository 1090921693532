import DataTable, {ConditionalStyles, ExpanderComponentProps, TableColumn} from 'react-data-table-component';
import {Order, OrderPosition, Variant} from '../../../../interfaces';
import {useTranslation} from 'react-i18next';
import React, {useState} from 'react';
import {endPointGetImage} from '../../../../constants';
import uuid from 'react-uuid';
import {ModalV2} from '../../../../components/modal/ModalV2';
import {VariantDetails} from './VariantDetails';

const customStyles = {
	rows: {
		style: {
			background: '#F5F5F5',
			minHeight: '100px',
		},
	},
	headCells: {
		style: {
			background: '#F5F5F5',
		},
	},
	cells: {
		style: {
			background: '#F5F5F5',
		},
	},
};


interface Props extends ExpanderComponentProps<Order> {
	concerningIDs?:string[]
}

export const ExpandableOrderPositionTable  = (props:Props) => {

	const {t} = useTranslation();
	const [variantIDClicked, setVariantIDClicked] = useState<string>('');
	const [productIDClicked, setProductIDClicked] = useState<string>('');

	const colums: TableColumn<OrderPosition>[] = [
		{
			name: t('expandableOrderPositionTable.positionNumber'),
			selector: row => row.positionNumber,
			center:true,
			width: '180px'
		},
		{
			name: t('expandableOrderPositionTable.articleNumber'),
			selector: row => row.item.articleNumber,
			center:true,
			width: '180px'
		},
		{
			name: t('expandableOrderPositionTable.image'),
			cell: (row, rowIndex, column) => (
				<div>
					{row.item.imageIds && (row.item.imageIds.length > 0) ?
						<img
							src={endPointGetImage(row.item.imageIds[0])}
							className="w-20 h-20 object-scale-down"
							alt={''}
							id={'img' + uuid()}
						/>
						:<></>
					}
				</div>
			),
			center: true,
			width: '180px',
		},
		{
			name:t('expandableOrderPositionTable.name'),
			selector: row => row.item.name,
			center:true,
			width: '180px'
		},
		{
			name: t('expandableOrderPositionTable.manufacturer'),
			selector: row => row.item.manufacturer?.name ?? '',
			center:true,
			width: '180px'
		},
		{
			name:'Preis',
			cell: (row, rowIndex, column) => {
				if (row.item.effectiveSale) {
					return <div>
						{t('expandableOrderPositionTable.price')}: {((row.item.price / 100) + ' €').replace('.', ',')}
					</div>;
				} else {
					return <div>
						{t('expandableOrderPositionTable.price')}: {((row.item.price / 100) + ' €').replace('.', ',')}
					</div>;
				}
			},
			center:true,
			width:'210px'
		},
		{
			name: t('expandableOrderPositionTable.amount'),
			selector: row => row.amount,
			center:true,
			width: '180px'
		},
		{
		},
	];


	const conditionalRowStyles: ConditionalStyles<OrderPosition>[] | undefined = [
		{
			when: row => props.concerningIDs?.find((concerningID) => concerningID === row.item.id) !== undefined,
			style: {
				opacity:'30%',
			},
		},
	];

	return(
		<>
			<DataTable
				columns={colums}
				data={props.data.positions}
				customStyles={customStyles}
				conditionalRowStyles={conditionalRowStyles}
				onRowClicked={(row) => {
					setVariantIDClicked(row.item.id);
					setProductIDClicked(row.item.productId);
				}}
				pointerOnHover={true}
			/>
			<ModalV2 
				isOpen={variantIDClicked !== '' && productIDClicked !== ''}
				handleClose={()=>{
					setVariantIDClicked('');
					setProductIDClicked('');
				}}
			>
				<VariantDetails
					productID={productIDClicked}
					variantID={variantIDClicked}
				/>
			</ModalV2>
		</>
	);
};