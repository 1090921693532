import React, {useEffect, useState} from 'react';
import {Constants} from '../../../../../constants';
import ComboBox from '../../../../../components/comboBox/ComboBox';
import {Category, CategoryStatus} from '../../../../../interfaces/category';
import classNames from 'classnames';
import {API} from '../../../../../api';
import {useErrorHandling} from '../../../../../providers/error/ErrorProvider';


interface Props {
	setSelectionValid: React.Dispatch<React.SetStateAction<boolean>>
	setDeepestCategoryId: React.Dispatch<React.SetStateAction<string>>
	wrapperClasses?: string
}

export default function CategoriesSelection(
	{
		setSelectionValid,
		setDeepestCategoryId,
		wrapperClasses
	}:Props) {

	const emptyCategory = {
		id: '',
		name: '',
		amountProducts: 0,
		imageId: '',
		status: CategoryStatus.CategoryStatusActive,
		filterIds: [],
		filters: [],
		children: []
	};

	const errorHandling = useErrorHandling();

	const [categories, setCategories] = useState<Category[]>([]);
	const [selectedCategories, setSelectedCategories] = useState<Category[]>([emptyCategory]);


	useEffect(() => {
		const __loadCategories = async () => {
			loadCategories().then();
		};
		__loadCategories().then();
	}, []);


	const loadCategories = async () => {
		try {
			const res = await API.Category.list(
				{
					sortPrimary: 'name',
					sortDirectionPrimary: Constants.SortDirection.ASC
				}
			);
			setCategories(res.data['categories']);
		} catch (e: any) {
			errorHandling(e);
		}
	};


	function handleSelectedCategory(category: Category | undefined, index: number) {
		const newArr:Category[] = structuredClone(selectedCategories);

		if (category === undefined) {
			newArr[index] = emptyCategory;
			newArr.splice(index + 1);
			setSelectionValid(false);
			setSelectedCategories(newArr);
			if (index > 0) {
				setDeepestCategoryId(newArr[index - 1].id);
			} else {
				setDeepestCategoryId('');
			}
		} else {
			newArr.splice(index + 1);
			newArr[index] = category;
			setDeepestCategoryId(category.id);

			// push new category for next subCategory
			if (index === (newArr.length - 1) && categories.find(elem => elem.parentId === category.id) !== undefined) {
				newArr.push(emptyCategory);
				setSelectionValid(false);
			} else {
				setSelectionValid(true);
			}
			setSelectedCategories(newArr);
		}
	}

	return(
		<div className={classNames(wrapperClasses)}>
			<div className="flex flex-col gap-2">
				{selectedCategories && selectedCategories.map((category, index) =>
					<ComboBox
						title={'Kategorie ' + String(index)}
						items={(index === 0) ?
							categories.filter(elem => elem.parentId === null).map(item => (
								{
									key: item.id,
									label: item.name,
									object: item
								}
							))
							: categories.filter(elem => elem.parentId === selectedCategories[index - 1].id).map(item => (
								{
									key: item.id,
									label: item.name,
									object: item
								}
							))
						}
						onChangeSelected={(item) => handleSelectedCategory(item, index)}/>
				)
				}
			</div>
		</div>
	);
}
