import React, {Fragment, useEffect, useState} from 'react';
import {Menu, Transition} from '@headlessui/react';
import {useAuth} from '../../../providers/auth/useAuth';
import { BellIcon, UserCircleIcon , BellSlashIcon} from '@heroicons/react/24/outline';
import {
	NotificationDisplayed,
	NotificationStatus,
	useNotification
} from '../../../providers/notification/NotificationProvider';
import {HiOutlineInformationCircle} from 'react-icons/hi';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {useErrorHandling} from '../../../providers/error/ErrorProvider';
function classNames(...classes: string[]) {
	return classes.filter(Boolean).join(' ');
}

const TopBar = () => {
	const auth = useAuth();
	const navigate = useNavigate();
	const {t} = useTranslation();
	const notifications = useNotification();
	const errorHandling = useErrorHandling();


	const onSignOutClick = () => {
		auth.logout();
	};

	const userNavigation = [
		{ name: 'Ausloggen', onClick: onSignOutClick },
	];

	return (
		<div className="flex flex-1 gap-3 pt-2">

			{/* Notifications dropdown*/}
			<Menu as="div" className="relative">
				<Menu.Button className={'hover:text-gray-500'}>
					{notifications.unreadNotifications?.length > 0 ?
						<BellIcon className="h-6 w-6" aria-hidden="true"/>
						:
						<BellSlashIcon className="h-6 w-6" aria-hidden="true"/>
					}
				</Menu.Button>

				<Transition
					as={Fragment}
					enter="transition ease-out duration-100"
					enterFrom="transform opacity-0 scale-95"
					enterTo="transform opacity-100 scale-100"
					leave="transition ease-in duration-75"
					leaveFrom="transform opacity-100 scale-100"
					leaveTo="transform opacity-0 scale-95"
				>
					<Menu.Items className="absolute right-0 z-10 min-w-96 p-3 rounded-md bg-white shadow-lg ring-1
						ring-black ring-opacity-5 focus:outline-none"
					>
						{
							notifications.unreadNotifications?.length > 0 ?
								<div className={'flex justify-center py-1'}>
									<button
										className={'hover:underline self-center'}
										onClick={() => notifications.readAllNotifications()}
									>
										{t('notifications.actions.readAllNotifications')}
									</button>
								</div>
								:
								<div className={'flex justify-center'}>
									{t('notifications.noUnreadNotifications')}
								</div>
						}

						{notifications.unreadNotifications.map((notification: NotificationDisplayed) => (
							<Menu.Item key={notification.id}>
								<div className={'p-1'}>
									<div className={'flex border-2 place-content-between rounded-md'}>
										<div className={'self-center pl-2'}>
											<HiOutlineInformationCircle
												className="h-6 w-6 text-yellow-400"
												aria-hidden="true"
											/>
										</div>
										<div className={'pl-3 flex flex-col'}>
											<div className={'font-bold pt-3'}>{notification.title}</div>
											<div className={'text-gray-700 pt-1 pb-3'}>{notification.message}</div>
										</div>
										{notification.deeplink && notification.deeplink !== '' &&
											<div className={'self-center px-3'}>
												<button
													className={'text-sm font-bold p-1 border-2 rounded-md cursor-pointer ' +
														'hover:bg-gray-50 hover:text-gray-500'}
													onClick={() => {
														notifications.readNotification(notification.id);
														navigate(notification.deeplink ?? '');}
													}
												>
													{t('notifications.actions.deeplink')}
												</button>
											</div>
										}
									</div>
								</div>
							</Menu.Item>
						))
						}
					</Menu.Items>
				</Transition>
			</Menu>

			{/* Profile dropdown */}
			<Menu as="div" className="relative">

				<Menu.Button
					className="flex max-w-xs items-center rounded-full text-sm hover:text-gray-500">
					<span className="sr-only">Open user menu</span>
					<UserCircleIcon className="h-6 w-6" aria-hidden="true"/>
				</Menu.Button>

				<Transition
					as={Fragment}
					enter="transition ease-out duration-100"
					enterFrom="transform opacity-0 scale-95"
					enterTo="transform opacity-100 scale-100"
					leave="transition ease-in duration-75"
					leaveFrom="transform opacity-100 scale-100"
					leaveTo="transform opacity-0 scale-95"
				>
					<Menu.Items className="absolute right-0 z-10 mt-2 w-48 rounded-md
										bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
						<a className={'pl-3.5 text-sm text-black'}>
							{auth.user?.firstName} {auth.user?.lastName}
						</a>
						{userNavigation.map((item) => (
							<Menu.Item key={item.name}>
								{({ active }) => (
									<a
										onClick={item.onClick}
										className={classNames(
											active ? 'bg-gray-100' : '',
											'block py-2 px-4 text-sm text-gray-700 cursor-pointer',
										)}
									>
										{item.name}
									</a>
								)}
							</Menu.Item>
						))}
					</Menu.Items>
				</Transition>
			</Menu>
		</div>
	);
};

export default TopBar;