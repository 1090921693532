import {Category} from './category';
import {Company} from './company';

export type CategorySale = {
    id: string
    saleType: SaleType
    categoryId: string
    category: Category | undefined
    categoryIdsHierarchy: string[]
    variantIds: string[]
    percentage: number
    endDate: string | undefined
    sellerId: string
    buyerId: string | undefined
    buyer: Company | undefined
    isDeleted: boolean
}

export type VarinatSale = {
    id: string
    saleType: SaleType
    percentage: number
    endDate: string | undefined
    variantId: string
    sellerId: string
    buyerId: string | undefined
    buyer: Company | undefined
    price: number | undefined
    isDeleted: boolean
}

export type Sale = {
    saleType: SaleType
    percentage: number
    endDate: string | undefined
    price: number | undefined
    buyerId: string | undefined
}

export enum SaleType {
    normalSaleType = 'NORMAL_SALE',
    specialConditionSaleType = 'SPECIAL_CONDITION'
  }