import {Variant} from '../../../../../interfaces';
import React from 'react';
import DataTable, {TableColumn} from 'react-data-table-component';
import {Modal} from '../../../../../components/modal/Modal';
import Button from '../../../../../components/button/Button';

interface Props {
	message: string
    variants: Variant[]
    isVisible: boolean
    onClose: () => void
    onSuccess: () => void
}


export const DeleteVariantSaleModal: React.FC<Props> = (
	{
		message,
		variants,
		isVisible,
		onClose,
		onSuccess
	}: Props) => {

	const colums: TableColumn<Variant>[] = [
		{
			name: 'Name',
			selector: row => row.name,
			width: '180px'
		},
		{
			name: 'Hersteller',
			selector: row => row.manufacturer?.name ?? '',
			width: '180px'
		},
	];

	return (
		<Modal
			isOpen={isVisible}
			handleClose={onClose}
			children={
				<>
					<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>

					<div
						className="relative flex items-end sm:items-center justify-center min-h-full p-5 text-center sm:p-0"
					>

						<div
							className="relative bg-white rounded-lg  pt-5 pb-4 text-left shadow-xl transform transition-all sm:my-8 w-1/4 h-max sm:p-10"
						>

							<div className={'pl-1 text-red-600'}>
								{message}
							</div>

							<div className={'relative flex flex-col gap-3'}>
								<DataTable
									columns={colums}
									data={variants}/>

								<div className={'flex flex-col items-center'}>
									<div className={'flex justify-center gap-2 mt-5'}>
										<Button
											label={'Abbrechen'}
											onClick={() => onClose()}/>
										<Button
											label={'Löschen'}
											onClick={() => onSuccess()}/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</>
			}
		/>
	);
};