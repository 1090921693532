import React, {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {APP_ROUTES} from '../../constants';


const PasswordUpdateConfirmation = () => {
	const navigate = useNavigate();

	useEffect(() => {
		setTimeout(function (){
			navigate(APP_ROUTES.login);
		},3000);
	});

	return(
		<div className={'flex flex-col justify-center items-center h-full text-2xl'}>
			<img
				className="mx-auto h-12 w-auto"
				src="/inopartLogo.jpeg"
				alt="Your Company"
			/>
			<p className="py-5">Passwort zurücksetzen erfolgreich.<br/> Sie werden automatisch zum Login zurückgeleitet.</p>
		</div>
	);
};

export default PasswordUpdateConfirmation;