import classNames from 'classnames';
import IconButton, {IconType} from '../../../../../../../components/button/IconButton';
import {ButtonType} from '../../../../../../../components/button/Button';
import React, {useEffect, useState} from 'react';
import {FileInterface} from '../../../../../../../interfaces';


interface Props {
    files      		: FileInterface[]
    setFiles   		: React.Dispatch<React.SetStateAction<FileInterface[]>>
	fileType		: string
    wrapperClasses?	:string
}

function ImageSelectionList({files, setFiles, fileType, wrapperClasses} : Props) {
	const fileRef = React.useRef<HTMLInputElement>(null);
	const [newFile, setNewFile] = useState<File | null>(null);


	useEffect(() => {
		if (newFile) {
			const reader = new FileReader();
			reader.onload = () => {

				const readerArr = (reader.result as string).split(',');
				if (readerArr.length == 2){
					const v = {id:'', name:newFile.name, fileType:fileType, contentType:'', data:readerArr[1]};
					setFiles(oldArr => [...oldArr, v]);
					setNewFile(null);
				}
			};
			reader.readAsDataURL(newFile);
		}
	}, [newFile]);


	function DeleteFileFromArray(index:number) {
		let newArr: FileInterface[] = [];
		newArr = files.flat(1);
		newArr.splice(index, 1);
		setFiles(newArr);
	}

	return(
		<div className={classNames(wrapperClasses)}>

			<div className="flex-wrap py-2 pl-4 flex gap-2 border rounded-lg">

				{files && files.map((image, index) => (
					<div className="border rounded-lg hover:border-blue-700 relative group">

						<img
							src={'data:' + image.contentType + ';base64,' + image.data}
							alt={'image preview'}
							className="object-scale-down w-20 h-20 p-1"
						/>

						<IconButton
							wrapperClasses="absolute top-0 left-15 right-0 hidden group-hover:block"
							icon={IconType.TRASH}
							onClick={()=>DeleteFileFromArray(index)}
						/>
					</div>
				))
				}

				<input
					type="file"
					onChange={(e)=>setNewFile(e.target.files ? e.target.files[0] : null)}
					style={{display:'none'}}
					ref={fileRef}
					accept="image/png, image/jpeg"
				/>

				<IconButton
					wrapperClasses="p-6 border rounded-lg"
					icon={IconType.PLUS}
					onClick={()=>fileRef.current?.click()}
				/>
			</div>
		</div>
	);
}

export default ImageSelectionList;
