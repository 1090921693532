import React from 'react';
import { Outlet } from 'react-router-dom';


export default function ProductTableHeader() {
	return (
		<div>
			<div className="flex justify-start pl-8">
				<h1 className="text-2xl text-gray-900">Produkte</h1>
			</div>
			<div>
				<Outlet/>
			</div>
		</div>
	);
}
