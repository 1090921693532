import React, {useEffect, useState} from 'react';
import {BudgetInterval, BudgetRefresh, GetBudgetInterval} from '../../../interfaces';
import classNames from 'classnames';
import CheckBox from '../../../components/checkBox/CheckBox';
import IntegerInputField from '../../../components/inputField/IntegerInputField';
import ComboBox from '../../../components/comboBox/ComboBox';
import UnlimitedBudgetUserModal from './UnlimitedBudgetWarningModal';

const intervalItems: BudgetIntervallItem[] = [
	{key: BudgetInterval.Month, label: 'Monat'},
	{key: BudgetInterval.Quarter, label: 'Quartal'},
	{key: BudgetInterval.Year, label: 'Jahr'}
];

interface BudgetIntervallItem {
	key: string
	label: string
}

interface Props {
	hasUnlimitedBudget: boolean
	setHasUnlimitedBudget: React.Dispatch<React.SetStateAction<boolean>>
	initialBudget: number
	setInitialBudget: React.Dispatch<React.SetStateAction<number>>
	budgetRefresh: BudgetRefresh | undefined
	setBudgetRefresh: React.Dispatch<React.SetStateAction<BudgetRefresh | undefined>>
	wrapperClasses?: string
}

export default function Budget(
	{
		hasUnlimitedBudget,
		setHasUnlimitedBudget,
		initialBudget,
		setInitialBudget,
		budgetRefresh,
		setBudgetRefresh,
		wrapperClasses
	}: Props) {

	const [hasBudgetRefresh, setHasBudgetRefresh] = useState<boolean>(budgetRefresh ? true : false);
	const [unlimitedBudgetWarningVisible, setUnlimitedBudgetWarningVisible] = useState(false);

	useEffect(() => {
		if (hasUnlimitedBudget) {
			setInitialBudget(0);
			setBudgetRefresh(undefined);
		}
	}, [hasUnlimitedBudget]);


	useEffect(() => {
		if (hasBudgetRefresh) {

			if (!budgetRefresh) {
				setBudgetRefresh({value: 0, interval: BudgetInterval.Month});
			}
		} else {
			setBudgetRefresh(undefined);
		}
	}, [hasBudgetRefresh]);


	const onChangeIntervall = (intervall: BudgetIntervallItem) => {
		if (budgetRefresh) {
			budgetRefresh.interval = GetBudgetInterval(intervall.key);
		}
	};


	const onChangeBudgetRefreshValue = (val:number) => {
		const newBudgetRefresh: BudgetRefresh = {value: val, interval: budgetRefresh?.interval as BudgetInterval};
		setBudgetRefresh(newBudgetRefresh);
	};


	return (
		<div className={classNames(wrapperClasses)}>
			<CheckBox
				wrapperClasses={'pt-2'}
				label={'Unlimitiertes Budget'}
				checked={hasUnlimitedBudget}
				disabled={hasBudgetRefresh}
				onChange={(e) => e.target.checked ? setUnlimitedBudgetWarningVisible(true) : setHasUnlimitedBudget(false)}
				title={'Alle Bestellungen/Anfragen, welche durch ein zu kleines Budget des Anfragenden oder einer der Vorgesetzten ' +
					'nicht bestätigt werden können, werden zu diesem Nutzer weitergeleitet. ' +
					'Es kann nur einen Benutzer im Unternehmen mit unlimitiertem Budget geben. ' +
					'Sobald sie diesen Nutzer ein unlimitiertes Budget geben, wird es dem anderen Nutzer entzogen.'}
			/>

			{!hasUnlimitedBudget &&
				<>
					<IntegerInputField
						wrapperClasses="py-2"
						title="Initiales Budget (€)"
						value={initialBudget}
						setValue={setInitialBudget}
					/>
					<CheckBox
						id={'hasBudgetRefresh'}
						wrapperClasses="pl-1 py-2"
						checked={hasBudgetRefresh}
						onChange={(e) => setHasBudgetRefresh(e.target.checked)}
						label="Budget Erneuerung Intervall"/>

					{hasBudgetRefresh &&
						<>
							<ComboBox
								title="Intervall"
								selected={budgetRefresh ?
									{
										key: intervalItems.find(item => item.key === budgetRefresh.interval)?.key ?? '',
										label: intervalItems.find(item => item.key === budgetRefresh.interval)?.label ?? '',
										object: intervalItems.find(item => item.key === budgetRefresh.interval)
									}
									: undefined
								}
								items={intervalItems.map(item => (
									{
										key: item.key,
										label: item.label,
										object: item
									}
								))}
								onChangeSelected={onChangeIntervall}/>

							<IntegerInputField
								wrapperClasses="py-2"
								title="Intervall Budget (€)"
								value={budgetRefresh?.value ?? 0}
								setValue={onChangeBudgetRefreshValue}
							/>
						</>
					}
				</>
			}

			<UnlimitedBudgetUserModal
				isVisible={unlimitedBudgetWarningVisible}
				onClose={() => null}
				onSuccess={() => {
					setHasUnlimitedBudget(true);
					setUnlimitedBudgetWarningVisible(false);
				}}/>
		</div>
	);
}
