import React from 'react';
import {CheckIcon, DocumentPlusIcon} from '@heroicons/react/20/solid';
import {HiOutlineTrash} from 'react-icons/hi';
import classNames from 'classnames';
import {IconType} from './IconButton';

export enum ButtonType {
	Primary ,
	Secondary ,
}

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
	buttonType?: ButtonType;
	icon?: IconType;
	label?:string;
	loading?: boolean;
	onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
	onSubmit?: (event: React.FormEvent<HTMLButtonElement>) => void;
	wrapperClasses?: string;
	disabled?: boolean;
}

const Button = ({icon = IconType.WITHOUT, label, onClick, onSubmit,wrapperClasses, disabled, buttonType = ButtonType.Primary }: Props) => {
	return (
		<div className={classNames(wrapperClasses)}>
			<button
				onClick={onClick}
				onSubmit={onSubmit}
				disabled={disabled}
				className={`${buttonType === ButtonType.Primary ? 
					'bg-blue-600 hover:bg-blue-500 text-white' 
					: 'bg-gray-50 text-black hover:bg-gray-200 border-gray-300'} 
					inline-flex items-center justify-center rounded-md border px-4 py-2 text-sm font-medium shadow-sm 
					focus:outline-none sm:w-auto disabled:bg-gray-300 disabled:cursor-not-allowed`}
			>
				{icon === IconType.WITHOUT
					? null
					:icon === IconType.CHECK ? <CheckIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
						:icon === IconType.PLUS? <DocumentPlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
							:icon === IconType.TRASH? <HiOutlineTrash className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
								:null}
				{label}
			</button>
		</div>
	);
};

export default Button;
