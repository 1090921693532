import React, {useEffect, useState} from 'react';
import classNames from 'classnames';
import InputField, {InputFieldType} from './InputField';


interface Props {
	title : string
	suggestions : string[]
	value : string
	setValue : (value:string)=>void
    wrapperClasses?	: string
}

export default function AutoCompleteInput({title, suggestions, value, setValue, wrapperClasses} : Props) {

	const [filteredSuggestions, setFilteredSuggestions] = useState<string[]>(suggestions);
	const [showSuggestions, setShowSuggestions] = useState<boolean>(false);
	const [activeSuggestionIndex, setActiveSuggestionIndex] = useState<number>(-1);


	useEffect(() => {
		if(!showSuggestions) {
			setActiveSuggestionIndex(-1);
		}
	}, [showSuggestions]);


	function onChangeInput(input:string) {
		if(input !== '') {
			setShowSuggestions(true);
		} else {
			setShowSuggestions(false);
		}

		const newFilteredSuggestions = suggestions.filter(suggestion =>
			suggestion.toLowerCase().indexOf(input.toLowerCase()) > -1
		);

		setFilteredSuggestions(newFilteredSuggestions);
		setValue(input);
	}


	function onKeyPressed(event: React.KeyboardEvent<HTMLInputElement>){
		if (event.key === 'ArrowDown') {
			if (!showSuggestions && (activeSuggestionIndex === 0)) {
				setShowSuggestions(true);
			} else if (activeSuggestionIndex < (filteredSuggestions.length - 1) ) {
				setActiveSuggestionIndex(activeSuggestionIndex + 1);
			}
		} else if (event.key === 'ArrowUp' && showSuggestions && activeSuggestionIndex > 0) {
			setActiveSuggestionIndex(activeSuggestionIndex - 1);

		} else if (event.key === 'Enter' && (activeSuggestionIndex >= 0) && (activeSuggestionIndex < filteredSuggestions.length)) {
			setValue(filteredSuggestions[activeSuggestionIndex]);
			setShowSuggestions(false);

		} else if (event.key === 'Escape') {
			setShowSuggestions(false);
		}
	}

	function onClickSuggestion(index:number) {
		setActiveSuggestionIndex(index);
		setValue(filteredSuggestions[index]);
		setShowSuggestions(false);
	}


	return(
		<div
			className={classNames(wrapperClasses) + 'w-full h-full'}
			id={'autoCompleteInput'}
			onBlur={() => setShowSuggestions(false)}
		>
			<InputField
				id={'inputFieldFromAutoComplete'}
				title={title}
				type={InputFieldType.TEXT}
				onChange={(e)=>onChangeInput(e.target.value)}
				value={value}
				onKeyDown={onKeyPressed}
				onFocus={()=>setShowSuggestions(true)}/>

			<div className={((showSuggestions) ? 'max-h-96 rounded-md py-1 bg-white text-base shadow-lg ring-1 ring-black ' +
				'ring-opacity-5 overflow-y-scroll' : '')}
			id={'suggestions_drop_down'}
			>
				{showSuggestions && filteredSuggestions &&
						filteredSuggestions.map((elem, index)=> {
							return (
								<div className={((activeSuggestionIndex === index)
									? 'cursor-pointer hover:bg-blue-500 pl-3 py-1 bg-blue-600 text-white '
									: 'cursor-pointer hover:bg-gray-200 pl-3 py-1 bg-gray-50')}
								onMouseDown={(e)=>e.preventDefault()} // somehow prevents onBlur form highest div, befor onClick happens...
								onClick={()=>onClickSuggestion(index)}
								key={index}
								style={{ height: 'h-full'}}
								>
									{elem}
								</div>
							);
						})
				}
			</div>
		</div>
	);
}