import {
	BaseQueryFn,
	createApi,
	FetchArgs,
	fetchBaseQuery,
	FetchBaseQueryError
} from '@reduxjs/toolkit/query/react';
import {useStorage} from '../providers/useStorage/useStorage';
import {AuthStorageState} from '../providers/useStorage/stateTypes';
import {ENDPOINTS} from '../constants';
import {ChatInterface, FileInterface, Order, Product, User, Variant} from '../interfaces';
import {
	CreateChatParams, CreateSupportSessionParams, GetFileParams, GetVariantParams, InviteSupporterParams,
	ListChatsParams, ListChatsResp,
	ListNotificationsParams,
	ListNotificationsResp, ListOrderParams, ListOrderResp, ListSupportSessionsParams, ListSupportSessionsResp,
	LoginParams,
	LoginResult
} from './interfaces';
import {UserRegisterParams} from './interfaces';
import {createPathString} from './util';
import {SupportSession} from '../interfaces/supportSession';

enum API_METHODS {
    GET = 'GET',
    POST = 'POST',
    PUT = 'PUT',
    DELETE = 'DELETE',
    PATCH = 'PATCH',
}

export function GetBaseURL():string {
	return process.env.REACT_APP_API_URL??'';
}

const FILE_ENDPOINTS = [''];


const wrappedBaseQuery:  BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError
> = async (args, api, extraOptions) => {

	const { loadState } = useStorage();

	return fetchBaseQuery({
		baseUrl: GetBaseURL(),
		prepareHeaders: (headers) => {

			//exclude images
			if (!FILE_ENDPOINTS.includes(api.endpoint))
				headers.set('Content-Type', 'application/json');

			const authStorageState = loadState<AuthStorageState>('auth');

			if (authStorageState && authStorageState.token !== '')
				headers.set('Authorization', `Bearer ${authStorageState.token}`);

			headers.set('accept', '*/*');
			return headers;
		}
	})(args, api, extraOptions);
};

export const webApi = createApi({
	reducerPath: 'webApi',
	baseQuery: wrappedBaseQuery,
	tagTypes: ['User', 'Notification', 'Chat', 'SupportSession', 'Order', 'Variant', 'Product', 'File'],
	endpoints: (builder) => ({


		// MISC

		getFile: builder.query<FileInterface, GetFileParams>({
			query: ({fileID, withoutData}) => ({
				url: createPathString(ENDPOINTS.file.get, {fileID}),
				method: API_METHODS.GET,
				params: {withoutData: withoutData},
			}),
			providesTags: ['File']
		}),

		//API AUTH

		getAuthUser: builder.query<User, { userID: string }>({
			query: () => ({
				url: ENDPOINTS.getUser,
				method: API_METHODS.GET
			}),
			providesTags: ['User']
		}),
		login: builder.mutation<LoginResult, LoginParams>({
			query: (params) => ({
				method: API_METHODS.POST,
				url: ENDPOINTS.login,
				body: params
			}),
			invalidatesTags: [{ type: 'User' }]
		}),
		registerCompanyUser: builder.mutation<LoginResult, UserRegisterParams>({
			query: ( body ) => ({
				method: API_METHODS.PATCH,
				url: ENDPOINTS.finishCompanyRegistration,
				body: body
			}),
			invalidatesTags: ['User']
		}),

		// NOTIFICATIONS

		listNotifications: builder.query<ListNotificationsResp, ListNotificationsParams>({
			query: (params) => ({
				method: API_METHODS.GET,
				url: ENDPOINTS.notifications.list,
				params: params
			}),
			providesTags: ['Notification']
		}),
		readAllNotifications: builder.mutation<string, void>({
			query: () => ({
				method: API_METHODS.PATCH,
				url: ENDPOINTS.notifications.readAll
			}),
			invalidatesTags: ['Notification']
		}),
		readNotification: builder.mutation<string, {notificationID:string}>({
			query: (notificationID) => ({
				method: API_METHODS.PATCH,
				url: createPathString(ENDPOINTS.notifications.read, notificationID)
			}),
			invalidatesTags: ['Notification']
		}),
		subscribeWebsocket: builder.mutation<string, void>({
			query: () => ({
				method: API_METHODS.POST,
				url: ENDPOINTS.notifications.subscribeWebsocket
			}),
		}),
		unsubscribeWebsocket: builder.mutation<string, void>({
			query: () => ({
				method: API_METHODS.DELETE,
				url: ENDPOINTS.notifications.unsubscribeWebsocket
			}),
		}),

		// CHAT

		createChat: builder.mutation<ChatInterface, CreateChatParams>({
			query: (params) => ({
				method: API_METHODS.POST,
				url: ENDPOINTS.chats.create,
				body: params
			}),
			invalidatesTags: [{ type: 'Chat' }]
		}),
		listChats: builder.query<ListChatsResp, ListChatsParams>({
			query: (params) => ({
				method: API_METHODS.GET,
				url: ENDPOINTS.chats.list,
				params: params
			}),
			providesTags: ['Chat']
		}),
		getChat: builder.query<ChatInterface, { chatID: string }>({
			query: (chatID) => ({
				url: createPathString(ENDPOINTS.chats.get, chatID),
				method: API_METHODS.GET
			}),
			providesTags: ['Chat']
		}),
		participateChat: builder.mutation<string, {chatID:string}>({
			query: (id) => ({
				method: API_METHODS.PATCH,
				url: createPathString(ENDPOINTS.chats.participate, id)
			}),
			invalidatesTags: ['Chat']
		}),
		stopParticipateChat: builder.mutation<string, {chatID:string}>({
			query: (id) => ({
				method: API_METHODS.PATCH,
				url: createPathString(ENDPOINTS.chats.stopParticipate, id)
			}),
			invalidatesTags: ['Chat']
		}),
		subscribeChatWebsocket: builder.mutation<string, {chatID:string}>({
			query: (id) => ({
				method: API_METHODS.POST,
				url: createPathString(ENDPOINTS.chats.subscribeWebsocket, id)
			}),
		}),
		unsubscribeChatWebsocket: builder.mutation<string, {chatID:string}>({
			query: (id) => ({
				method: API_METHODS.DELETE,
				url: createPathString(ENDPOINTS.chats.unsubscribeWebsocket, id)
			}),
		}),

		// SUPPORT-SESSION

		createSupportSession: builder.mutation<SupportSession, CreateSupportSessionParams>({
			query: (params) => ({
				method: API_METHODS.POST,
				url: ENDPOINTS.supportSessions.create,
				body: params
			}),
			invalidatesTags: [{ type: 'SupportSession' }]
		}),
		listSupportSessions: builder.query<ListSupportSessionsResp, ListSupportSessionsParams>({
			query: (params) => ({
				method: API_METHODS.GET,
				url: ENDPOINTS.supportSessions.list,
				params: params
			}),
			providesTags: ['SupportSession']
		}),
		getSupportSession: builder.query<SupportSession, { supportSessionID: string }>({
			query: (supportSessionID) => ({
				url: createPathString(ENDPOINTS.supportSessions.get, supportSessionID),
				method: API_METHODS.GET
			}),
			providesTags: ['SupportSession']
		}),
		participateSupportSession: builder.mutation<string, {supportSessionID:string}>({
			query: (supportSessionID) => ({
				method: API_METHODS.PATCH,
				url: createPathString(ENDPOINTS.supportSessions.participate, supportSessionID)
			}),
			invalidatesTags: ['SupportSession']
		}),
		stopParticipateSupportSession: builder.mutation<string, {supportSessionID:string}>({
			query: (id) => ({
				method: API_METHODS.PATCH,
				url: createPathString(ENDPOINTS.supportSessions.stopParticipate, id)
			}),
			invalidatesTags: ['SupportSession']
		}),
		inviteSupporter: builder.mutation<string, InviteSupporterParams>({
			query: ({supportSessionID, userID}) => ({
				method: API_METHODS.PATCH,
				url: createPathString(ENDPOINTS.supportSessions.inviteSupporter,{
					supportSessionID:supportSessionID,
					userID:userID
				})
			}),
			invalidatesTags: ['SupportSession']
		}),
		closeSupportSession: builder.mutation<string, {supportSessionID:string}>({
			query: (id) => ({
				method: API_METHODS.PATCH,
				url: createPathString(ENDPOINTS.supportSessions.close, id)
			}),
			invalidatesTags: ['SupportSession']
		}),

		// ORDERS

		listOrders: builder.query<ListOrderResp, ListOrderParams>({
			query: (params) => ({
				method: API_METHODS.GET,
				url: ENDPOINTS.orders.list,
				params: params
			}),
			providesTags: ['Order']
		}),
		getOrder: builder.query<Order, { orderID: string }>({
			query: (orderID) => ({
				url: createPathString(ENDPOINTS.orders.get, orderID),
				method: API_METHODS.GET
			}),
			providesTags: ['Order']
		}),

		// Product

		getProduct: builder.query<Product, {productID:string}>({
			query: (productID) => ({
				url: createPathString(ENDPOINTS.products.get, productID),
				method: API_METHODS.GET
			}),
			providesTags: ['Product']
		}),
		getVariant: builder.query<Variant, GetVariantParams>({
			query: (params) => ({
				url: createPathString(ENDPOINTS.products.getVariant, {
					productID:params.productID, variantID:params.variantID
				}),
				method: API_METHODS.GET
			}),
			providesTags: ['Variant']
		}),
	})
});

export const {
	useGetAuthUserQuery,
	useLoginMutation,
	useRegisterCompanyUserMutation,
	useListNotificationsQuery,
	useLazyListNotificationsQuery,
	useReadAllNotificationsMutation,
	useReadNotificationMutation,
	useSubscribeWebsocketMutation,
	useUnsubscribeWebsocketMutation,
	useCloseSupportSessionMutation,
	useCreateChatMutation,
	useCreateSupportSessionMutation,
	useGetChatQuery,
	useLazyGetChatQuery,
	useGetSupportSessionQuery,
	useListChatsQuery,
	useListSupportSessionsQuery,
	useParticipateChatMutation,
	useParticipateSupportSessionMutation,
	useStopParticipateSupportSessionMutation,
	useStopParticipateChatMutation,
	useSubscribeChatWebsocketMutation,
	useUnsubscribeChatWebsocketMutation,
	useInviteSupporterMutation,
	useGetOrderQuery,
	useListOrdersQuery,
	useGetVariantQuery,
	useGetProductQuery,
	useGetFileQuery,
	useLazyGetFileQuery
} = webApi;