import classNames from 'classnames';
import {FileInterface} from '../../../../../../interfaces';
import React from 'react';
import Divider from '../../../../../../components/Divider';
import FileSelectionList from './components/FileSelectionList';
import {Constants} from '../../../../../../constants';

interface Props {
    dataSheets: FileInterface[]
    setDataSheets: React.Dispatch<React.SetStateAction<FileInterface[]>>

    dxfFiles: FileInterface[]
    setDxfFiles: React.Dispatch<React.SetStateAction<FileInterface[]>>

    stpFiles: FileInterface[]
    setStpFiles: React.Dispatch<React.SetStateAction<FileInterface[]>>

    wrapperClasses?: string;
}


export default function CreateProductFilesTab(
	{
		dataSheets, setDataSheets,
		dxfFiles, setDxfFiles,
		stpFiles, setStpFiles,
		wrapperClasses
	}: Props) {


	return (
		<div className={classNames(wrapperClasses)}>
			<div className={'flex gap-16'}>
				<div className={'flex-1'}>
					<Divider title="Datenblätter"/>
					<FileSelectionList
						wrapperClasses="py-4"
						files={dataSheets}
						setFiles={setDataSheets}
						fileType={Constants.FileType.ProductDataSheet}
						accept="application/pdf"/>
				</div>

				<div className={'flex-1'}>
					<Divider title="DXF-Dateien"/>
					<FileSelectionList
						wrapperClasses="py-4"
						files={dxfFiles}
						setFiles={setDxfFiles}
						fileType={Constants.FileType.DxfFile}
						accept=".dxf"/>
				</div>

				<div className={'flex-1'}>
					<Divider title="STP-Dateien"/>
					<FileSelectionList
						wrapperClasses="py-4"
						files={stpFiles}
						setFiles={setStpFiles}
						fileType={Constants.FileType.StpFile}
						accept=".stp"/>
				</div>
			</div>
		</div>
	);
}
