import React, {useEffect, useRef, useState} from 'react';
import {useWebsocketConnection, WebsocketMessage} from '../../providers/websocket/websocketProvider';
import {useAuth} from '../../providers/auth/useAuth';
import {ChatInterface, ChatMessageWebsocketData} from '../../interfaces';
import {ChatMessage} from './ChatMessage';
import {ChatNameIndicatorCircle} from './ChatNameIndicatorCircle';
import {ChatMessageHistoryDateIndicator} from './ChatMessageHistoryDateIndicator';
import uuid from 'react-uuid';

// ChatMessageView needed to build temporary messages coming from the websocket
export interface ChatMessageView {
	fromUserID: string
	text: string
	created:string
	firstNameLetter:string // first letter of firstName is taken for displaying a message
	seen:boolean
	imageIDs:string[] | undefined
	fileIDs:string[] | undefined
}

interface Props {
	chat:ChatInterface|undefined
}

// ChatMessageHistory fetches old chat messages and appends incoming messages from websocket in "real-time"
export default function ChatMessageHistory({chat}: Props) {

	const websocketConn = useWebsocketConnection();
	const auth = useAuth();

	const [messageViews, setMessageViews] = useState<ChatMessageView[]>([]);
	const messagesEndRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (messagesEndRef.current){
			messagesEndRef.current.scrollIntoView({behavior: 'smooth', block:'end', inline: 'nearest'});
		}
	}, [JSON.stringify(messageViews)]);

	
	useEffect(() => {
		if (chat && chat.messages && chat.messages.length > 0){
			setMessageViews(chat.messages.map(msg => ({
				text:msg.text,
				fromUserID:msg.fromUserID,
				created:msg.created,
				firstNameLetter: chat?.participants?.find((user) => user.id === msg.fromUserID)?.firstName[0] ?? '',
				seen:false,
				imageIDs: msg.imageIDs,
				fileIDs:msg.fileIDs
			})));
		}
	}, [chat]);


	useEffect(() => {
		if(websocketConn && websocketConn.websocket){

			websocketConn.websocket.addEventListener('message', (msg)=>{

				const wsMessage:WebsocketMessage = JSON.parse(msg.data);

				if (wsMessage.subscriptionID === chat?.id) {
					const chatMessageData:ChatMessageWebsocketData = JSON.parse(wsMessage.data);

					const newTempSocketMessage:ChatMessageView = {
						created: new Date().toISOString(),
						text: chatMessageData.text,
						fromUserID: chatMessageData.userID,
						seen:false,
						firstNameLetter: chat?.participants?.find((user) => user.id === chatMessageData.userID)?.firstName[0] ?? '',
						imageIDs: chatMessageData.imageIDs,
						fileIDs: chatMessageData.fileIDs
					};
					console.log('newMSG', newTempSocketMessage);
					setMessageViews((prevState) => [...prevState,newTempSocketMessage]);
				}
			});
		}
	}, [websocketConn.websocket]);


	let messageDayIndicator = 0;

	return (
		<div className={'flex flex-col h-3/4 gap-4 px-5 bg-gray-50 overscroll-contain overflow-y-scroll ' +
			'scrollbar-thin scroll-smooth scrollbar-thumb-gray-400 scrollbar-track-gray-300 border rounded-md py-2'}>

			{chat && messageViews.length > 0 && messageViews.map((msg: ChatMessageView, index) => {

				const currentDate:Date = new Date(msg.created);
				const currentDay:number = currentDate.getDay();

				let renderDayIndicator = false;

				if (messageDayIndicator !== currentDay){
					renderDayIndicator = true;
					messageDayIndicator = currentDay;
				}

				if (msg.fromUserID === auth.user?.id) {
					return <div className={'w-1/2 self-end'} key={'ChatMsgHistory' + index}>
						{renderDayIndicator ?
							<div className={'pb-4'}>
								<ChatMessageHistoryDateIndicator date={msg.created}/>
							</div>
							:
							null
						}
						<div key={index} className={'self-end flex justify-end gap-5'}>
							<ChatMessage
								message={msg}
								ownMessage={true}
							/>
							<ChatNameIndicatorCircle letter={msg.firstNameLetter} fromOthers={false}/>
						</div>
					</div>;
				} else {
					return <div className={'w-1/2'} key={'ChatMsgHistory' + index}>
						{renderDayIndicator ?
							<div className={'pb-4'}>
								<ChatMessageHistoryDateIndicator date={msg.created}/>
							</div>
							:
							null
						}
						<div key={index} className={'flex gap-5'}>
							<ChatNameIndicatorCircle letter={msg.firstNameLetter} fromOthers={true}/>
							<ChatMessage
								message={msg}
								ownMessage={false}
							/>
						</div>
					</div>;
				}
			})}

			<div ref={messagesEndRef}/>
		</div>
	);
}