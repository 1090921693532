
export default function FormatDate (date:string): string {
	const tsDate = new Date(date);
	return tsDate.toLocaleDateString('de-DE', {day: 'numeric',	month: 'long', year: 'numeric'});
}



export function FormatDateWithTime(date:string): string {
	const tsDate = new Date(date);
	return tsDate.toLocaleTimeString('de-DE',  {day: 'numeric',	month: 'long', year: 'numeric', hour: '2-digit', minute: '2-digit'}) + ' Uhr';
}

export function FormatDateToTime(date:string, locale:string): string {
	const tsDate = new Date(date);
	return tsDate.toLocaleTimeString(locale, {hour: '2-digit', minute: '2-digit', hourCycle:'h24'});
}

