import {Outlet, useNavigate, useParams} from 'react-router-dom';
import React, {useEffect} from 'react';


export default function EditProductHeader(){
	const {productId} = useParams<string>();
	const navigate = useNavigate();

	return(
		<>
			<div className="flex justify-start pl-8">
				<h1 className="text-2xl self-center">
					Produkt bearbeiten
				</h1>

			</div>
			<div>
				<Outlet/>
			</div>
		</>
	);
}