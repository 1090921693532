import {Variant, VarinatSale} from '../../../../../../interfaces';
import React, {useEffect, useState} from 'react';
import DataTable, {TableColumn} from 'react-data-table-component';
import {Modal} from '../../../../../../components/modal/Modal';
import CurrencyInputField from '../../../../../../components/inputField/CurrencyInputField';
import CustomDatePicker from '../../../../../../components/datePicker/CustomDatePicker';
import Button from '../../../../../../components/button/Button';
import {CompanyPartnership} from '../../../../../../interfaces/companyPartnership';
import {API} from '../../../../../../api';
import {useErrorHandling} from '../../../../../../providers/error/ErrorProvider';
import ComboBox from '../../../../../../components/comboBox/ComboBox';

interface Props {
    variants: Variant[]
    isVisible: boolean
    onClose: () => void
    onSuccess: (percentage: number, endDate: string | undefined, partnerCompanyId: string) => void
}


interface ConflictingSale {
	buyerName: string
	buyerId: string
	variantName: string
	saleId: string
}


export const CreateVariantSpecialConditionModal: React.FC<Props> = ({
	variants,
	isVisible,
	onClose,
	onSuccess
}: Props) => {

	const errorHandling = useErrorHandling();

	const [errorMessage, setErrorMessage] = useState<string>('');
	const [percentage, setPercentage] = useState<number>(0);
	const [endDate, setEndDate] = useState<string | undefined>(undefined);

	const [companyPartnerships, setCompanyPartnerships] = useState<CompanyPartnership[]>([]);
	const [selectedCompanyPartnersip, setSelectedCompanyPartnersip] = useState<CompanyPartnership>();

	const [confictingSales, setConfictingSales] = useState<ConflictingSale[]>([]);


	useEffect(() => {
		setConfictingSales(calcConflictingSales(variants, companyPartnerships));
	}, [companyPartnerships]);


	useEffect(() => {
		if (isVisible) {
			loadCompanyPartnershipsAsSeller().then();
		} else {
			setPercentage(0);
			setEndDate(undefined);
			setErrorMessage('');
			setSelectedCompanyPartnersip(undefined);
			setConfictingSales([]);
		}
	}, [isVisible]);


	function calcConflictingSales(variants: Variant[], companyPartnerships: CompanyPartnership[]): ConflictingSale[] {

		const conflictingSales: ConflictingSale[] = [];

		if (variants && variants.length > 0 && companyPartnerships && companyPartnerships.length > 0) {

			for (let variantIdx = 0; variantIdx < variants.length; variantIdx++) {

				const sales: VarinatSale[] = variants[variantIdx].variantSaleSpecialConditions;

				for (let saleIdx = 0; saleIdx < sales.length; saleIdx++) {

					const found: CompanyPartnership | undefined = companyPartnerships.find((item) => item.buyerId === sales[saleIdx].buyerId);
					if (found) {
						conflictingSales.push({
							saleId: sales[saleIdx].id,
							buyerId: found.buyerId,
							buyerName: found.name,
							variantName: variants[variantIdx].name
						});
					}
				}
			}
		}

		console.log('calcConflictingSales variants companyPartnerships', conflictingSales, variants, companyPartnerships);

		return conflictingSales;
	}


	const loadCompanyPartnershipsAsSeller = async () => {
		try {
			const resp = await API.CompanyPartnership.listAsSeller({});

			setCompanyPartnerships(resp.data['companyPartnerships']);
			console.log('loaded ', resp.data['companyPartnerships']);
		} catch (e: any) {
			errorHandling(e);
		}
	};


	const onFinish = () => {
		if (percentage === 0) {
			setErrorMessage('ungültiger Prozentsatz');
		} else if (endDate ? (new Date(endDate) < new Date()) : false) {
			setErrorMessage('Sale Enddatum muss in der Zukunft liegen');
		} else if (selectedCompanyPartnersip === undefined) {
			setErrorMessage('Sie müssen ein Partnerunternehmen auswählen');
		} else {
			onSuccess(percentage, endDate, selectedCompanyPartnersip ? selectedCompanyPartnersip.buyerId : '');
		}
	};


	const colums: TableColumn<Variant>[] = [
		{
			name: 'Name',
			selector: row => row.name,
			width: '180px'
		},
		{
			name: 'Hersteller',
			selector: row => row.manufacturer?.name ?? '',
			width: '180px'
		},
	];


	return (
		<Modal
			isOpen={isVisible}
			handleClose={onClose}
			children={
				<>
					<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>

					<div
						className="relative flex items-end sm:items-center justify-center min-h-full p-5 text-center sm:p-0"
					>

						<div className="relative bg-white rounded-lg  pt-5 pb-4 text-left shadow-xl
                                           transform transition-all sm:my-8 w-1/4 h-max sm:p-10"
						>

							<div className={'relative flex flex-col gap-3'}>
								<CurrencyInputField
									title={'Prozentsatz'}
									percentage={true}
									value={percentage}
									setValue={setPercentage}/>

								<CustomDatePicker
									title={'Enddatum'}
									dateString={endDate ?? ''}
									setDateString={setEndDate}/>

								<ComboBox
									wrapperClasses={'pb-20'}
									title={'Partnerunternehen'}
									items={companyPartnerships.map(item => ({
										key: item.buyerId,
										label: item.buyer ? item.buyer.name : 'error',
										object: item
									}))}
									onChangeSelected={setSelectedCompanyPartnersip}
									disabledItemKeys={confictingSales.map(item => item.buyerId)}
								/>

								<div className={'text-red-600 z-0'}>
                                    * Sonderkonditionen werden auf folgende Produkte erstellt:
								</div>

								<DataTable
									columns={colums}
									data={variants}/>


								<div className={'flex flex-col items-center'}>
									<div className={'flex justify-center gap-2 mt-5'}>
										<Button
											label={'Abbrechen'}
											onClick={() => onClose()}/>
										<Button
											label={'Erstellen'}
											onClick={() => onFinish()}/>
									</div>
								</div>

								{errorMessage !== '' &&
									<div className="flex justify-center">
										<div className="text-red-600 py-2">{errorMessage}</div>
									</div>
								}
							</div>
						</div>
					</div>
				</>
			}
		/>
	);
};