import React, {Fragment, useEffect, useState} from 'react';
import {Popover, Transition} from '@headlessui/react';
import {ChevronDownIcon, XMarkIcon} from '@heroicons/react/20/solid';
import RangeValuesComboBox, {RangeComboBoxItem} from '../../../components/comboBox/RangeValuesComboBox';

interface RangeFilterProps {
	rangeFilterItem: RangeFilterItem
	onChangeRangeFilterItem: (item: RangeFilterItem) => void
}

export type RangeFilterItem = {
	id: string
	name: string
	unit: string
	possibleValues: number[]
	selectedMinValue: number | undefined
	selectedMaxValue: number | undefined
	relevance: number
}

export const RangeFilter: React.FC<RangeFilterProps> = (
	{
		rangeFilterItem, onChangeRangeFilterItem
	}: RangeFilterProps) => {

	const [minValue, setMinValue] = useState<number | undefined>(rangeFilterItem.selectedMinValue);
	const [maxValue, setMaxValue] = useState<number | undefined>(rangeFilterItem.selectedMaxValue);
	const [maxRange, setMaxRange] = useState<number []>([]);
	const [minRange, setMinRange] = useState<number []>([]);


	useEffect(() => {
		setMinValue(rangeFilterItem.selectedMinValue);
		setMaxValue(rangeFilterItem.selectedMaxValue);
	}, [JSON.stringify(rangeFilterItem)]);


	// setting new min/max range when maxValue changes
	useEffect(() => {
		const range: number[] = structuredClone(rangeFilterItem.possibleValues);

		if (range !== undefined && range.length > 0) {
			if (maxValue !== undefined && minValue !== undefined) {
				setMinRange(range.slice(0, range.findIndex(v => v === maxValue)));
				setMaxRange(range.slice(range.findIndex(v => v === minValue), range.length));
			} else if (maxValue !== undefined) {
				setMinRange(range.slice(0, range.findIndex(v => v === maxValue)));
			} else if (minValue !== undefined) {
				setMinRange(range);
				setMaxRange(range.slice(range.findIndex(v => v === minValue), range.length));
			} else {
				setMinRange(range);
				setMaxRange(range);
			}
		} else {
			throw new Error('filter.filterValues.rangeValues are empty or undefined but neccessery in rangeFilter');
		}
	}, [maxValue]);


	// setting new min/max range when minValue changes
	useEffect(() => {
		const range: number[] | undefined = structuredClone(rangeFilterItem.possibleValues);

		if (range !== undefined && range.length > 0) {
			if (maxValue !== undefined && minValue !== undefined) {
				setMinRange(range.slice(0, range.findIndex(v => v === maxValue)));
				setMaxRange(range.slice(range.findIndex(v => v === minValue), range.length));
			} else if (minValue !== undefined) {
				setMaxRange(range.slice(range.findIndex(v => v === minValue), range.length));
			} else if (maxValue !== undefined) {
				setMinRange(range.slice(0, range.findIndex(v => v === maxValue) + 1));
				setMaxRange(range);
			} else {
				setMinRange(range);
				setMaxRange(range);
			}
		} else {
			throw new Error('filter.filterValues.rangeValues are empty or undefined but neccessery in rangeFilter');
		}
	}, [minValue]);


	function onClickDeleteMinValue() {
		console.log('onClickDeleteMinValue');
		onChangeRangeFilterItem({...rangeFilterItem, selectedMinValue: undefined});
	}


	function onClickDeleteMaxValue() {
		console.log('onClickDeleteMaxValue');
		onChangeRangeFilterItem({...rangeFilterItem, selectedMaxValue: undefined});
	}


	return(
		<Popover
			as="div"
			key={rangeFilterItem.name}
			id={rangeFilterItem.id}
			className="relative inline-block text-left"
		>
			<div className={'flex'}>
				<Popover.Button
					className='group inline-flex items-center justify-center text-sm font-medium text-gray-700 hover:text-gray-900'>
					<span>{rangeFilterItem.name} ({rangeFilterItem.unit})</span>
					<ChevronDownIcon
						className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
						aria-hidden="true"/>
				</Popover.Button>

				{(rangeFilterItem.selectedMinValue || rangeFilterItem.selectedMaxValue) &&

					<span className={'flex'}>

						{rangeFilterItem.selectedMinValue &&
							<div
								className={'flex ml-1.5 cursor-pointer rounded bg-gray-200 px-1.5 py-0.5 text-xs font-semibold tabular-nums text-gray-700 hover:bg-red-500'}
								onClick={() => onClickDeleteMinValue()}
							>
								<span>
									von &nbsp;{rangeFilterItem.selectedMinValue}
								</span>
								<XMarkIcon
									className="h-4 w-4 text-black"
									aria-hidden="true"/>
							</div>
						}
						{rangeFilterItem.selectedMaxValue &&
							<div
								className={'flex ml-1.5 cursor-pointer  rounded bg-gray-200 px-1.5 py-0.5 text-xs font-semibold tabular-nums text-gray-700 hover:bg-red-500'}
								onClick={() => onClickDeleteMaxValue()}
							>
								<span>
									bis &nbsp;{rangeFilterItem.selectedMaxValue}
								</span>
								<XMarkIcon
									className="self-end h-4 w-4 text-black"
									aria-hidden="true"/>
							</div>
						}
					</span>
				}
			</div>


			<Transition
				as={Fragment}
				enter="transition ease-out duration-100"
				enterFrom="transform opacity-0 scale-95"
				enterTo="transform opacity-100 scale-100"
				leave="transition ease-in duration-75"
				leaveFrom="transform opacity-100 scale-100"
				leaveTo="transform opacity-0 scale-95"
			>
				<Popover.Panel
					className="absolute left-0 z-10 mt-2 origin-top-right rounded-md bg-white p-4 shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none"
				>
					<div className={'flex-row w-36'}>
						<form className="space-y-4">
							<RangeValuesComboBox
								items={minRange.map((item: number) => {
									return {label: item.toString(), value: item};
								})}
								selected={minValue ? {label: minValue.toString(), value: minValue} : undefined}
								setSelected={
									(item: RangeComboBoxItem | undefined) =>
										item ? setMinValue(item.value)
											: setMinValue(undefined)
								}
								title={'von'}/>

							<RangeValuesComboBox
								items={maxRange.map((item: number) => {
									return {label: item.toString(), value: item};
								})}
								selected={maxValue ? {label: maxValue.toString(), value: maxValue} : undefined}
								setSelected={
									(item: RangeComboBoxItem | undefined) =>
										item ? setMaxValue(item.value)
											: setMaxValue(undefined)
								}
								title={'bis'}/>
						</form>

						<div className={'flex justify-center pt-4'}>
							<Popover.Button
								className={'border rounded-md border-transparent bg-blue-600 px-4 py-2 text-sm text-white font-medium hover:bg-blue-500'}
								onClick={() => {
									console.log('onClick range apply', {
										...rangeFilterItem,
										selectedMinValue: minValue,
										selectedMaxValue: maxValue
									});
									onChangeRangeFilterItem(
										{
											...rangeFilterItem,
											selectedMinValue: minValue,
											selectedMaxValue: maxValue
										}
									);
								}
								}
							>
								Anwenden
							</Popover.Button>
						</div>

					</div>
				</Popover.Panel>
			</Transition>
		</Popover>
	);
};

