import React from 'react';
import DataTable, {ExpanderComponentProps, TableColumn} from 'react-data-table-component';
import uuid from 'react-uuid';
import {useNavigate} from 'react-router-dom';
import {Constants, endPointGetImage, routeDetailedProduct, routeDetailedVariant} from '../../../../constants';
import {Product, Variant} from '../../../../interfaces';
import FormatDate from '../../../../utils/date';
import calcSalePrice from '../../../../utils/salePriceCalc';
import Necessity = Constants.Necessity;

const customStyles = {
	rows: {
		style: {
			background: '#F5F5F5',
			minHeight: '100px',
		},
	},
	headCells: {
		style: {
			background: '#F5F5F5',
		},
	},
	cells: {
		style: {
			background: '#F5F5F5',
		},
	},
};

const ExpandableVariants: React.FC<ExpanderComponentProps<Product>> = ({ data }) => {
	const navigate = useNavigate();

	function handleOnRowClicked(variant:Variant){
		if (data.variants && data.variants.length > 1) {
			navigate(routeDetailedVariant(
				data.id,
				data.name, variant.id,
				variant.properties.find(item=>item.necessity === Necessity.Variation)?.name ??'',
				variant.properties.find(item=>item.necessity === Necessity.Variation)?.value ??''));
			
		} else if (data.variants && data.variants.length === 1) {
			navigate(routeDetailedProduct(data.id, variant.id, variant.name));
		}
	}

	const colums: TableColumn<Variant>[] = [
		{
			name: 'Produktbild',
			cell: (row, rowIndex, column) =>
				<div>
					{row.imageIds && (row.imageIds.length > 0) ?
						<img
							src={endPointGetImage(row.imageIds[0])}
							className="w-20 h-20 object-scale-down"
							alt={' '}
							id={'img' + uuid()}
						/>
						:<></>
					}
				</div>,
			center:true,
			width: '180px'
		},
		{
			name: 'Variante' + ' (' + data.variationProperty?.name + ')',
			selector: row => row.properties?.find(elem=>elem.necessity === Necessity.Variation)?.value ?? '',
			center:true,
			width: '240px'
		},
		{
			name:'Herstellernummer',
			selector: row => row.manufacturerArticleNumber,
			center:true,
			width: '180px'
		},
		{
			name:'Status',
			cell: (row, rowIndex, column) =>
				<div className="flex flex-col items-center">
					{row.active?
						<span className="inline-flex rounded-full bg-green-200 px-2 font-semibold leading-5 text-black">
					Aktiv
						</span>:
						<span className="inline-flex rounded-full bg-yellow-300 px-2 font-semibold leading-5 text-black">
					Inaktiv
						</span>
					}
				</div>,
			center:true,
			width: '180px'
		},
		{
			name:'Anzahl Verfügbar',
			selector: row => row.amountAvailable,
			center:true,
			width: '180px'
		},
		{
			name:'Preis',
			cell: (row, rowIndex, column) => {
				if (row.effectiveSale) {
					return <div className={'flex flex-col'}>
						<div>
							Preis: {((row.price / 100) + ' €').replace('.', ',')}
						</div>
						<div className={'text-red-600'}>
							Reduziert: {row.effectiveSale ? ((calcSalePrice(row.price, row.effectiveSale.percentage) / 100).toFixed(2) + ' €').replace('.', ',') : '-'}
						</div>
						<div className={'text-red-600'}>
							Rabatt: {row.effectiveSale ? (row.effectiveSale.percentage / 100 + ' %').replace('.', ',') : '-'}
						</div>
						<div className={'text-red-600'}>
							Enddatum: {row.effectiveSale?.endDate ? FormatDate(row.effectiveSale.endDate) : '-'}
						</div>
					</div>;
				} else {
					return <div>
						Preis: {((row.price / 100) + ' €').replace('.', ',')}
					</div>;
				}
			},
			center:true,
			width:'210px'
		},
		{
			name:''
		}
	];

	return(
		<div className="pl-12">
			{data ?
				<DataTable
					columns={colums}
					data={data.variants??[]}
					customStyles={customStyles}
					onRowClicked={(row)=>handleOnRowClicked(row)}
					pointerOnHover={true}
				/>
				:'Keine Produkt-Varianten vorhanden'
			}
		</div>
	);
};

export default ExpandableVariants;
