import React, {useState} from 'react';
import {CheckIcon, ChevronUpDownIcon} from '@heroicons/react/20/solid';
import {Combobox} from '@headlessui/react';
import classNames from 'classnames';


export interface ItemInterface {
	key: string
	label: string
	object: any
}

interface Props {
	title: string;
	selected?: ItemInterface | undefined
	items: ItemInterface[];
	onChangeSelected: (item: any | undefined) => void;
	disabledItemKeys?: string[];
	onChangeInput?: (value: string) => void;
	onClickChevronIcon?: () => void;
	onFocus?: () => void;
	customInputAllowed?: boolean;
	wrapperClasses?: string;
}

const ComboBox = (
	{
		title,
		selected,
		items,
		onChangeSelected,
		disabledItemKeys,
		customInputAllowed,
		onChangeInput,
		onClickChevronIcon,
		onFocus,
		wrapperClasses = '',
	}: Props) => {

	const [selectedItem, setSelectedItem] = useState<ItemInterface | undefined>(selected);
	const [query, setQuery] = useState('');

	let filteredItems: any[];

	if (items) {
		if (query === '') {
			filteredItems = items;
		} else {
			filteredItems = items.filter((item: any) =>
				item.label
					.toString()
					.toLowerCase()
					.replace(/\s+/g, '')
					.includes(query.toLowerCase().replace(/\s+/g, ''))
			);
		}
	} else {
		filteredItems = [];
	}

	return (
		<div className={classNames('flex flex-col', wrapperClasses)}>
			<Combobox
				as="div"
				value={selectedItem}
				nullable={true}
				onChange={(item: ItemInterface) => {
					setSelectedItem(item);
					if (item) {
						onChangeSelected(item.object);
					} else {
						onChangeSelected(undefined);
					}
				}}
			>

				<Combobox.Label className="block text-sm font-medium text-gray-700">
					{title}
				</Combobox.Label>

				<div className="relative">
					<Combobox.Input
						className="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 shadow-sm
						focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 sm:text-sm"
						onChange={(event) => {
							setQuery(event.target.value);
							onChangeInput && onChangeInput(event.target.value);
						}}
						displayValue={(item: any) =>
							item ?
								typeof item === 'string' ?
									item
									: item.label ?? ''
								: ''
						}
						onFocus={onFocus}
					/>

					<Combobox.Button
						className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none"
						onClick={() => {
							setQuery('');
							onClickChevronIcon && onClickChevronIcon();
						}}
					>

						<ChevronUpDownIcon
							className="h-5 w-5 text-gray-400"
							aria-hidden="true"
						/>

					</Combobox.Button>

					{filteredItems && filteredItems.length >= 0 && (
						<Combobox.Options
							className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1
								text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
						>
							{customInputAllowed && query.length > 0 && (
								<Combobox.Option
									className={({active}) =>
										classNames(
											'relative cursor-default select-none py-2 pl-3 pr-9 text-xs',
											active ? 'bg-blue-500 text-white' : 'text-gray-900'
										)
									}
									value={{id: null, name: query}}
								>
									Erstellen: "{query}"
								</Combobox.Option>
							)}

							{filteredItems.map((item: ItemInterface) => (
								<Combobox.Option
									key={item.key}
									defaultValue={undefined}
									value={item}
									disabled={disabledItemKeys ? disabledItemKeys.find(v => v === item.key) !== undefined : false}
									className={({active, disabled}) =>
										classNames(
											'relative z-50 cursor-default select-none py-2 pl-3 pr-9',
											active ? 'bg-blue-600 text-white' : disabled ? 'text-gray-400' : 'text-gray-900'
										)
									}
								>
									{({active, selected}) => (
										<>
											<span className={classNames('block truncate', selected && 'font-semibold')}>
												{item.label ?? ''}
											</span>

											{selected && (
												<span
													className={classNames(
														'absolute inset-y-0 right-0 flex items-center pr-4',
														active ? 'text-white' : 'text-blue-600'
													)}
												>
													<CheckIcon className="h-5 w-5" aria-hidden="true"/>
												</span>
											)}
										</>
									)}
								</Combobox.Option>
							))}
						</Combobox.Options>
					)}
				</div>
			</Combobox>
		</div>
	);
};

export default ComboBox;
