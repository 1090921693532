export const createPathString = (
	endpoint: string,
	query: { [key: string]: string | number | boolean }
): string => {
	if (!endpoint) return '';

	let string = endpoint;

	for (const [key, value] of Object.entries(query)) {
		string = string.replace(`{${key}}`, String(value));
	}

	return string;
};