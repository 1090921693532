import {useNavigate, useParams} from 'react-router-dom';
import {useEffect} from 'react';
import {useAuth} from '../../../providers/auth/useAuth';
import {APP_ROUTES} from '../../../constants';
import {useErrorHandling} from '../../../providers/error/ErrorProvider';


const MailVerificationRedirect = () => {
	const navigate = useNavigate();
	const auth = useAuth();
	const errorHandling = useErrorHandling();
	const {linkHash} = useParams<string>();

	useEffect(() => {
		const login = async () => {
			try {
				await auth.register({body:{linkHash:linkHash??''}});

				navigate(APP_ROUTES.dashboard);
			} catch (e:any) {
				errorHandling(e);
			}
		};

		login().then();
	},[]);

	return(
		<div className="text-4xl">
			Sie haben ihren Account bestätigt. <br/>
			Die Registrierung ist nun abgeschlossen. <br/>
			Sie werden in kürze weitergeleitet. <br/>
		</div>
	);
};

export default MailVerificationRedirect;