import {useEffect, useState} from 'react';
import classNames from 'classnames';
import InputField from '../inputField/InputField';


export interface SearchBarData {
    id: string;
    displayed: string;
}

interface Props {
    // onChangeSearchStr You can use the search string as a query for the backend call
    onChangeSearchStr: (str:string) => void

    // selectedItem The item selected from the dropdown.
    selectedItem: SearchBarData | undefined

    // onChangeSelectedItem When the input field changes, after selecting an item, selectedItem will be set to undefined.
    onChangeSelectedItem: (item:SearchBarData | undefined) => void

    // items to display in the searchBar
    items: SearchBarData[];

    inputFieldPlaceholder: string

    inputFieldTitle?:string

    wrapperClasses?: string;
}

export default function SearchBarV2(
	{
		items,
		onChangeSearchStr,
		onChangeSelectedItem,
		selectedItem,
		inputFieldPlaceholder,
		inputFieldTitle,
		wrapperClasses
	}: Props) {

	const [searchStrDelayed, setSearchStrDelayed] = useState<string>('');
	const [isDropDownVisible, setIsDropDownVisible] = useState<boolean>(false);

	useEffect(() => {
		if(searchStrDelayed === '' || searchStrDelayed === selectedItem?.displayed){
			setIsDropDownVisible(false);
		} else {
			setIsDropDownVisible(true);
		}

		if (selectedItem?.displayed !== searchStrDelayed){
			onChangeSelectedItem(undefined);
			const timeout = setTimeout(() => onChangeSearchStr(searchStrDelayed), 400);
			return () => {
				clearTimeout(timeout);
			};
		}

	}, [searchStrDelayed]);


	function handleOnClickResult(item: SearchBarData) {
		onChangeSelectedItem(item);
		setSearchStrDelayed(item.displayed);
	}

	return (
		<div className={classNames(wrapperClasses)}>
			<div className={'w-full relative'}>
				<InputField
					wrapperclasses={'relative'}
					title={inputFieldTitle ?? ''}
					placeholder={inputFieldPlaceholder}
					value={selectedItem ? selectedItem.displayed : searchStrDelayed}
					onChange={(e) => setSearchStrDelayed(e.target.value)}/>

				{items && isDropDownVisible && (
					<div className={'w-full bg-white flex flex-col border-2 rounded-md absolute top-full z-20'}>
						{
							items && items.map((item: SearchBarData) => (
								<div
									className={'px-2 py-1 rounded-md cursor-pointer hover:bg-gray-100'}
									key={'searchBarData' + item.id}
									onMouseDown={(e) => e.preventDefault()} // somehow prevents onBlur form highest div, befor onClick happens...
									onClick={() => handleOnClickResult({id:item.id, displayed:item.displayed})}>
									{item.displayed}
								</div>
							))
						}
					</div>
				)}
			</div>
		</div>
	);
}