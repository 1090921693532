import {Outlet} from 'react-router-dom';
import React from 'react';

export default function SelectCategoryHeader(){
	return(
		<>
			<div className="flex justify-start pl-8">
				<h1 className="text-2xl self-center">
                    Kategorie für Produkt auswählen
				</h1>
			</div>
			<div>
				<Outlet/>
			</div>
		</>
	);
}