import React, {createContext, useContext} from 'react';
import axios, {AxiosError} from 'axios';
import {APIError, ErrorIdDE, ErrorMessagesDE} from '../../api';
import {toast} from 'sonner';


type ErrorContextType = (e: Error) => void


const ErrorContext = createContext<ErrorContextType>(e => {
	throw e; // default function
});


export const useErrorHandling = () => {
	return useContext(ErrorContext);
};


const formatAxiosError = (error: AxiosError): APIError => {
	let apiErr: APIError = {errorId: '', errorMessage: ''};

	if (!error){
		return {errorId:'', errorMessage:'undefined error'};
	}

	if (error.response) {
		try {
			const axoisErr = error.response;
			apiErr = axoisErr.data as APIError;
		} catch (e) {
			console.log(e);
		}
	} else {
		return {errorId: ErrorIdDE['NETWORK'], errorMessage: ErrorMessagesDE['NETWORK']};
	}

	return {errorId: ErrorIdDE[apiErr.errorId], errorMessage: ErrorMessagesDE[apiErr.errorId] ?? apiErr.errorMessage};
};


interface Props {
    children: React.ReactNode
}


export const ErrorProvider: React.FC<Props> = (props: Props) => {

	const axiosHandler = (error: AxiosError) => {
		const apiError: APIError = formatAxiosError(error);
		toast.error(apiError.errorMessage +  apiError.errorId);
	};

	const handler = (e: Error) => {
		if (axios.isAxiosError(e)) {
			axiosHandler(e);
		} else {
			throw e;
		}
	};

	return (
		<ErrorContext.Provider value={handler}>
			{props.children}
		</ErrorContext.Provider>
	);
};