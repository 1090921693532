import {useNavigate, useParams} from 'react-router-dom';
import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {APP_ROUTES, Constants, endPointProduct} from '../../../../constants';
import ProductManagementTabBar from '../tabs/ProductManagementTabBar';
import Button from '../../../../components/button/Button';
import {IconType} from '../../../../components/button/IconButton';
import InputField, {InputFieldType} from '../../../../components/inputField/InputField';
import ManufacturerSelection from '../components/ManufacturerSelection';
import DescriptionTab from '../tabs/description/DescriptionTab';
import EditVariantsTab from '../tabs/variants/EditVariantsTab';
import {
	FileInterface,
	Manufacturer,
	Product,
	Property,
	VariantBuilder,
	VariantBuilderState
} from '../../../../interfaces';
import {API} from '../../../../api';
import {VariantBuilderMap} from '../../../../utils';
import {useErrorHandling} from '../../../../providers/error/ErrorProvider';
import EditProductFilesTab from './tabs/files/EditProductFilesTab';
import EditProductImageTab from './tabs/images/EditProductImageTab';
import Necessity = Constants.Necessity;


export default function EditProductWithVariants() {
	const {productId} = useParams<string>();
	const navigate = useNavigate();
	const errorHandling = useErrorHandling();

	const [errorMessage, setErrorMessage] = useState<string>('');
	const [tabIndex, setTabIndex] = useState<number>(0);

	const [variantBuilderMap, setVariantBuilderMap] = useState<VariantBuilderMap>(new VariantBuilderMap());

	const [name, setName] = useState<string>('');
	const [manufacturerArticleNumber, setManufacturerArticleNumber] = useState<string>(''); // TODO
	const [manufacturer, setManufacturer] = useState<Manufacturer>();
	const [shortDescription, setShortDescription] = useState<string>('');
	const [longDescription, setLongDescription] = useState<string>('');

	const [product, setProduct] = useState<Product>();

	const [images, setImages] = useState<FileInterface[]>([]);
	const [plans, setPlans] = useState<FileInterface[]>([]);
	const [dataSheets, setDataSheets] = useState<FileInterface[]>([]);
	const [dxfFiles, setDxfFiles] = useState<FileInterface[]>([]);
	const [stpFiles, setStpFiles] = useState<FileInterface[]>([]);

	const [imageIds, setImageIds] = useState<string[]>([]);
	const [planIds, setPlanIds] = useState<string[]>([]);
	const [dataSheetIds, setDataSheetIds] = useState<string[]>([]);
	const [dxfFileIds, setDxfFileIds] = useState<string[]>([]);
	const [stpFileIds, setStpFileIds] = useState<string[]>([]);

	const [properties, setProperties] = useState<Property[]>([]);
	const [isEditPropertiesTableVisible, setIsEditPropertiesTableVisible] = useState<boolean>(false);


	useEffect(() => {
		variantBuilderMap.map.forEach((builder, key) => {
			if (builder.getState() !== VariantBuilderState.delete) {
				builder.adjustProperties(properties);
			}
		});
		setVariantBuilderMap(variantBuilderMap);
	}, [properties]);


	useEffect(() => {
		axios.get(endPointProduct(productId ?? ''), {
			params: {fromCompany: true}
		})
			.then((response) => {
				setProduct(response.data);

				const product:Product = response.data;
				setName(product.name);
				setManufacturer(product.manufacturer);
				setShortDescription(product.shortDescription);
				setLongDescription(product.longDescription);

				if (product.variants) {
					for (let i = 0; i < product.variants.length; i++) {
						const newVariantBuilder: VariantBuilder = new VariantBuilder(VariantBuilderState.update, product.variants[i]);
						variantBuilderMap.map.set(newVariantBuilder.getId(), newVariantBuilder);
					}
					setVariantBuilderMap(variantBuilderMap);
				}

				let newPropertiesArr:Property[] = [];

				if(product.specificProperties){
					newPropertiesArr = newPropertiesArr.concat(product.specificProperties);
				}
				if(product.universalProperties){
					newPropertiesArr = newPropertiesArr.concat(product.universalProperties);
				}
				if(product.variationProperty){
					newPropertiesArr.push(product.variationProperty ?? {name:'',value:'',unit:'', filterId:'', necessity:Necessity.Variation});
				}

				setProperties(structuredClone(newPropertiesArr));

				setImageIds(product.imageIds);
				setPlanIds(product.planIds);
				setDataSheetIds(product.dataSheetIds);
				setDxfFileIds(product.dxfFileIds);
				setStpFileIds(product.stpFileIds);
			})
			.catch(errorHandling);
	}, []);


	const onSaveAndExit = async () => {
		try {
			await API.Product.update(
				productId ?? '',
				name,
				manufacturerArticleNumber,
				undefined,
				manufacturer?.id,
				shortDescription,
				longDescription,
				properties.filter(elem => elem.necessity === Necessity.Universal),
				properties.filter(elem => elem.necessity === Necessity.Specific),
				images.concat(plans).concat(dataSheets).concat(dxfFiles).concat(stpFiles),
				imageIds,
				planIds,
				dataSheetIds,
				dxfFileIds,
				stpFileIds);


			variantBuilderMap.map.forEach((builder, key) => {
				builder.apiCall(productId ?? '').then();
			});

			navigate(APP_ROUTES.products);
		} catch (e: any) {
			errorHandling(e);
		}
	};

	return(
		<div className={'px-8 mt-3 w-full'}>

			<div className={'flex border-b border-gray-200'}>
				<ProductManagementTabBar
					wrapperClasses={'flex gap-5 flex-1'}
					tabIndex={tabIndex}
					setTabIndex={setTabIndex}
					hasVariants={true}/>

				<div className="flex self-center">
					<Button
						label="Abbrechen"
						onClick={()=>navigate(APP_ROUTES.products)}
						icon={IconType.WITHOUT} />
					<Button
						wrapperClasses="pl-5"
						label="Speichern und beenden"
						onClick={()=>onSaveAndExit()}
						icon={IconType.CHECK} />
				</div>
			</div>

			{tabIndex === 0 &&
				<div className={'pt-8 w-1/4'}>
					<InputField
						type={InputFieldType.TEXT}
						value={name}
						title="Name"
						onChange={(e) => setName(e.target.value)}/>

					<ManufacturerSelection
						wrapperClasses={'pt-4'}
						selectedManufacturer={manufacturer}
						setSelectedManufacturer={setManufacturer}/>
				</div>
			}
			{tabIndex === 1 &&
				<EditVariantsTab
					wrapperClasses={'pt-8'}
					isEditPropertiesTableVisible={isEditPropertiesTableVisible}
					setIsEditPropertiesTableVisible={setIsEditPropertiesTableVisible}
					variantBuilderMap={variantBuilderMap}
					setVariantBuilderMap={setVariantBuilderMap}
					properties={properties}
					setProperties={setProperties}
					categoryId={product?.categoryId ?? ''}
					errorMessage={errorMessage}
					setErrorMessage={setErrorMessage}
					isSalePossible={false}/>
			}
			{tabIndex === 2 &&
				<DescriptionTab
					wrapperClasses={'pt-8'}
					shortDescription={shortDescription}
					setShortDescription={setShortDescription}
					longDescription={longDescription}
					setLongDescription={setLongDescription}/>
			}
			{tabIndex === 3 &&
				<EditProductImageTab
					wrapperClasses={'pt-8 w-1/2'}
					imageIds={imageIds}
					setImageIds={setImageIds}
					planIds={planIds}
					setPlanIds={setPlanIds}
					images={images}
					setImages={setImages}
					plans={plans}
					setPlans={setPlans}/>
			}
			{tabIndex === 4 &&
				<EditProductFilesTab
					wrapperClasses={'w-2/3 pt-8'}
					dataSheets={dataSheets}
					setDataSheets={setDataSheets}
					dataSheetIds={dataSheetIds}
					setDataSheetIds={setDataSheetIds}
					dxfFiles={dxfFiles}
					setDxfFiles={setDxfFiles}
					dxfFileIds={dxfFileIds}
					setDxfFileIds={setDxfFileIds}
					stpFiles={stpFiles}
					setStpFiles={setStpFiles}
					stpFileIds={stpFileIds}
					setStpFileIds={setStpFileIds}/>
			}

			{errorMessage != '' &&
				<div className="flex justify-center">
					<div className="text-red-600 py-2">{errorMessage}</div>
				</div>
			}
		</div>
	);
}
