import React, {useEffect, useState} from 'react';
import {User} from '../../interfaces';
import AddUserModal from './components/AddUserModal';
import EditUserModal from './components/EditUserModal';
import DataTable, {TableColumn} from 'react-data-table-component';
import IconButton, {IconType} from '../../components/button/IconButton';
import uuid from 'react-uuid';
import Button, {ButtonType} from '../../components/button/Button';
import {Constants} from '../../constants';
import {PermissionGroupsDisplayed} from './components/PermissionsSelection';
import {API} from '../../api';
import {useAuth} from '../../providers/auth/useAuth';
import {useErrorHandling} from '../../providers/error/ErrorProvider';


export default function UserManagement() {
	const auth = useAuth();
	const errorHandling = useErrorHandling();

	const [loading, setLoading] = useState(false);
	const [nextPageToken, setNextPageToken] = useState<string>('');
	const [totalAmount, setTotalAmount] = useState<number>(0);

	const [isAddUserModalVisible, setIsAddUserModalVisible] = useState<boolean>(false);
	const [isEditUserModalVisible, setIsEditUserModalVisible] = useState<boolean>(false);

	const [users, setUsers] = useState<User[]>([]);
	const [userToEdit, setUserToEdit] = useState<User>();


	const loadCompanyUsers = async (token?: string) => {
		try {
			setLoading(true);
			const resp = await API.Company.listUsers({});

			const resolvedUsers = resp.data['users'];

			for (let i = 0; i < resolvedUsers.length; i++) {
				resolvedUsers[i].name = resolvedUsers[i].firstName + ' ' + resolvedUsers[i].lastName;
				if (resolvedUsers[i].supervisor) {
					resolvedUsers[i].supervisor.name = resolvedUsers[i].supervisor.firstName + ' ' + resolvedUsers[i].supervisor.lastName;
				}
			}

			if (token && token !== '') {
				setUsers(users.concat(resolvedUsers));
			} else {
				setUsers(resolvedUsers);
			}
			setNextPageToken(resp.data['nextPageToken']);
			setTotalAmount(resp.data['totalAmount']);
			setLoading(false);
		} catch (e: any) {
			errorHandling(e);
		}
	};

	useEffect(() => {
		if (auth.user) {
			loadCompanyUsers().then();
		}
	}, [auth.user]);


	const handleEditClick = async (user: User) =>{
		setUserToEdit(user);
		setIsEditUserModalVisible(true);
	};

	const onSuccessAddUserModal = async () => {
		setUsers([]);
		setIsAddUserModalVisible(false);
		loadCompanyUsers('').then();
	};

	const handleOnSuccessEditModal = async () => {
		setUsers([]);
		setUserToEdit(undefined);
		setIsEditUserModalVisible(false);
		loadCompanyUsers('').then();
	};

	function onCloseEditModal(){
		setUserToEdit(undefined);
		setIsEditUserModalVisible(false);
	}

	const colums: TableColumn<User>[] = [
		{
			name: 'Name',
			cell: (row) =>
				<div>
					{row.firstName} {row.lastName}
				</div>,
			width: '180px'
		},
		{
			name: ' E-Mail',
			cell: (row) =>
				<div>
					{row.mail}
				</div>,
			width: '240px'
		},
		{
			name: 'Abteilung',
			selector: row => row.department,
			width: '150px'
		},
		{
			name: 'Vorgesetzter',
			cell: (row) =>
				<div>
					{row.supervisor ?
						<div>{row.supervisor.firstName} {row.supervisor.lastName}</div>
						: '-'}
				</div>,
			width: '150px',
		},
		{
			name: 'Aktuelles Budget',
			cell: (row) =>
				<div>{Math.floor(row.budget / 100)} €</div>,
			center: true,
			width: '130px'
		},
		{
			name: 'Budget Interval',
			cell: (row) =>
				<div>
					{row.budgetRefresh ?
						<div>
							<div>Intervall: {row.budgetRefresh.interval}</div>
							<div>Wert: {row.budgetRefresh.value} €</div>
						</div>
						: <>-</>
					}
				</div>,
			center: true,
			width: '180px'
		},
		{
			name: 'Unlimited Budget',
			cell: (row) =>
				<div>
					{row.unlimitedBudget &&
						<span
							className="inline-flex rounded-full bg-green-200 px-2 text-xs font-semibold leading-5 text-black">
							Aktiv
						</span>
					}
				</div>,
			center: true,
			width: '130px'
		},
		{
			name: 'Berechtigungsgruppe',
			cell: row =>
				<div>
					{PermissionGroupsDisplayed.find(elem => elem.permissionGroup?.name === row.permissionGroup?.name)?.name ?? '-'}
				</div>,
			center: true,
			width: '180px'
		},
		{
			name: 'Status',
			cell: (row) =>
				<div>
					{row.userState == Constants.UserStates.Active &&
						<span className="inline-flex rounded-full bg-green-200 px-2 text-xs font-semibold leading-5 text-black">
							Aktiv
						</span>
					}
					{row.userState == Constants.UserStates.Inactive &&
						<span className="inline-flex rounded-full bg-yellow-300 px-2 text-xs font-semibold leading-5 text-black">
							Inaktiv
						</span>
					}
					{row.userState == Constants.UserStates.Invited &&
						<span className="inline-flex rounded-full bg-blue-300 px-2 text-xs font-semibold leading-5 text-black">
							Eingeladen
						</span>
					}
					{row.userState == Constants.UserStates.Blocked &&
						<span className="inline-flex rounded-full bg-red-500 px-2 text-xs font-semibold leading-5 text-black">
							Blockiert
						</span>
					}
				</div>,
			center: true,
			width: '120px'
		},
		{
			name: '',
			cell: (row) =>
				<div className="justify-self-end">
					<IconButton
						onClick={() => handleEditClick(row)}
						icon={IconType.PENCIL}/>
				</div>,
			center: true,
			grow: 1
		},
	];

	return (
		<div className="px-4 sm:px-6 lg:px-8 mt-8">
			<div className="flex justify-end mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
				<Button
					onClick={()=>setIsAddUserModalVisible(true)}
					label="Nutzer einladen"/>
			</div>

			{users ?
				<div className="flex flex-col">
					<DataTable
						columns={colums}
						data={users}
						progressPending={loading}
						paginationTotalRows={totalAmount}
						key={uuid()}/>

					{(totalAmount > 15) && (users.length < totalAmount) &&
						<Button
							wrapperClasses="py-5 self-center"
							label="load more"
							onClick={()=>loadCompanyUsers(nextPageToken)}/>
					}
				</div>
				:
				<div>
					Keine Benutzer vorhanden...
				</div>
			}


			<AddUserModal
				isVisible={isAddUserModalVisible}
				onClose={()=>setIsAddUserModalVisible(false)}
				onSuccess={onSuccessAddUserModal}/>
			

			{(userToEdit !== undefined) &&
				<EditUserModal
					isVisible={isEditUserModalVisible}
					onClose={()=>onCloseEditModal()}
					onSuccess={()=>handleOnSuccessEditModal()}
					user={userToEdit}/>
			}
		</div>
	);
}
