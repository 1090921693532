import React, {useState} from 'react';
import classNames from 'classnames';
import {numberInputRegEx} from '../../utils/regex';


interface Props {
	title: string;
	value: number
	setValue: (val: number) => void
    placeholder?:string
	wrapperClasses?: string;
}



export const NumberInputField = ({title, setValue, value, placeholder, wrapperClasses}: Props) => {
	const [inputStr, setInputStr] = useState<string|undefined>(()=>{
		if (value === 0.0 || Number.isNaN(value)){
			return '';
		} else{
			return value.toString();
		}
	});


	function roundToTwo(num:number):number  {
		return +(Math.round(Number(num + 'e+2'))  + 'e-2');
	}

	const onChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
		const newInputStr = event.target.value;

		if(newInputStr.length === 1 && newInputStr[0] === '-'){
			setInputStr(newInputStr);
			setValue(-0);

		} else if (numberInputRegEx.test(newInputStr)) {
			setInputStr(newInputStr);
			const resultingIntNumber = roundToTwo( parseFloat(newInputStr.replace(',','.')) );
			setValue(resultingIntNumber);

		} else if(newInputStr === ''){
			setInputStr('');
			setValue(0);
		}
	};

	return (
		<div className={
			classNames('flex flex-col', wrapperClasses)
		}>
			<label className="block text-sm font-medium text-gray-700">{title}</label>
			<input type="text"
				className={'block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 ' +
					'placeholder-gray-400 shadow-sm  sm:text-sm focus:outline-none focus:border-blue-500'}
				onChange={onChangeInput} value={inputStr} placeholder={placeholder??'0'}/>
		</div>
	);
};

export default NumberInputField;
