import React, {useEffect, useState} from 'react';
import {TrashIcon} from '@heroicons/react/24/solid';
import {ImageMimes} from '../../constants';

interface FileUploadPreview {
    name: string;
    contentType:string
    data:string
}

interface Props {
    fileList: FileList
	onDeleteFiles: ()=>void
}

export const ChatFileUploadPreviews = (props:Props) => {

	const [filePreviews, setFilePreviews] = useState<FileUploadPreview[]>([]);

	useEffect(() => {
		if(props.fileList) {
			const fileArr:File[] = Array.from(props.fileList);

			for (let i = 0; i < fileArr.length; i++) {
				const reader = new FileReader();
				reader.onload = () => {

					const readerArr = (reader.result as string).split(',');
					if (readerArr.length == 2){
						setFilePreviews(oldArr => [...oldArr,
							{name: fileArr[i].name, contentType:fileArr[i].type, data:readerArr[1]}
						]);
					}
				};
				reader.readAsDataURL(fileArr[i]);
			}
		}
	}, [props.fileList]);

    
	return(
		<div className={'flex border border-gray-200 rounded-lg'}>
			{filePreviews.map((filePreview) => {
				return <div
					key={filePreview.name}
					className="border rounded-lg hover:border-blue-700 relative group"
				>
					{ImageMimes.includes(filePreview.contentType) ?
						<img
							key={'ChatImageUploadPreviews' + filePreview.name}
							src={'data:' + filePreview.contentType + ';base64,' + filePreview.data}
							alt={filePreview.name}
							className="object-scale-down w-20 h-20 p-1"
						/>
						:
						<div className={'w-20 h-20 p-1 break-all truncate'}>
							{filePreview.name}
						</div>
					}

				</div>;
			})}
			<div className={'px-3 self-center'}>
				<TrashIcon
					className="h-8 w-8 text-gray-400 hover:text-gray-700 cursor-pointer self-center"
					aria-hidden="true"
					type={'button'}
					onClick={() => props.onDeleteFiles()}
				/>
			</div>
		</div>
	);
};