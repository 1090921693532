import {Variant, VarinatSale} from '../../../../../../interfaces';
import React, {useEffect, useState} from 'react';
import DataTable, {ExpanderComponentProps, TableColumn} from 'react-data-table-component';
import FormatDate from '../../../../../../utils/date';
import IconButton, {IconType} from '../../../../../../components/button/IconButton';
import ConfirmationModal from '../../../../../../components/modal/ConfirmationModal';


const customStyles = {
	rows: {
		style: {
			background: '#F5F5F5',
			minHeight: '100px',
		},
	},
	headCells: {
		style: {
			background: '#F5F5F5',
		},
	},
	cells: {
		style: {
			background: '#F5F5F5',
		},
	},
};


interface Props extends ExpanderComponentProps<Variant> {
    onDelete: (variantId: string) => void
}


export const ManageVariantSpecialConditionExpand = ({data, onDelete}: Props) => {

	const [varinatSales, setVarinatSales] = useState<VarinatSale[]>([]);
	const [isDeleteModalVisible, setIsDeleteModalVisible] = useState<boolean>(false);
	const [selectedVariantId, setSelectedVariantId] = useState<string>();


	useEffect(() => {
		setVarinatSales(structuredClone(data.variantSaleSpecialConditions));
	}, []);


	function onClickDeleteButton(variantId: string) {
		setIsDeleteModalVisible(true);
		setSelectedVariantId(variantId);
	}


	function onCloseDeleteModal() {
		setIsDeleteModalVisible(false);
		setSelectedVariantId(undefined);
	}


	function onSuccessDeleteModal() {
		if (!selectedVariantId) {
			throw new Error('onSuccessDeleteModal: variantSaleIdToDelete is undefined');
		}
		setIsDeleteModalVisible(false);
		onDelete(selectedVariantId);
		setSelectedVariantId('');
	}


	const colums: TableColumn<VarinatSale>[] = [
		{
			name: 'Käufer',
			selector: row => row.buyer?.name ?? 'error',
			center: true,
			width: '180px'
		},
		{
			name: 'Prozentsatz',
			cell: (row, rowIndex, column) => (
				<div>
					<label>{row.percentage / 100}&nbsp;%</label>
				</div>

			),
			center: true,
			width: '180px'
		},
		{
			name: 'Enddatum',
			cell: (row, rowIndex, column) => (
				<div>
					<label>{row.endDate ? FormatDate(row.endDate) : '-'}</label>
				</div>

			),
			center: true,
			width: '180px'
		},
		{
			name: '',
			grow: 1,
			cell: (row, rowIndex, column) =>
				<div className="flex items-center gap-2 pr-4">
					<IconButton
						onClick={() => onClickDeleteButton(row.variantId)}
						icon={IconType.TRASH}
						title='Sonderkondition löschen'/>
				</div>
		},
	];


	return (
		<div className={'pl-24'}>
			<DataTable
				customStyles={customStyles}
				columns={colums}
				data={varinatSales}
			/>

			<ConfirmationModal
				isVisible={isDeleteModalVisible}
				onClose={onCloseDeleteModal}
				onSuccess={onSuccessDeleteModal}
				text={'Sind sie sicher, dass sie diese Sonderkondition löschen möchten?'}/>
		</div>
	);
};