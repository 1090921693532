import NumberInputField from '../../../../../../components/inputField/NumberInputField';
import InputField, {InputFieldType} from '../../../../../../components/inputField/InputField';
import IntegerInputField from '../../../../../../components/inputField/IntegerInputField';
import CheckBox from '../../../../../../components/checkBox/CheckBox';
import Button from '../../../../../../components/button/Button';
import {Modal} from '../../../../../../components/modal/Modal';
import React, {useEffect, useState} from 'react';
import {Property, VariantChangeble} from '../../../../../../interfaces';
import {Constants} from '../../../../../../constants';
import PropertyField from '../../../components/PropertyField';
import CurrencyInputField from '../../../../../../components/inputField/CurrencyInputField';
import CustomDatePicker from '../../../../../../components/datePicker/CustomDatePicker';
import Necessity = Constants.Necessity;

interface Props {
	existingVariationPropertyValues: (string | number)[]
	existingVariant?: VariantChangeble
    variationProperty: Property
    universalProperties: Property[]
    specificProperties: Property[]
	isSalePossible: boolean
    isVisible: boolean
    onClose: () => void
	onSuccess?: (VariantChangeble: VariantChangeble) => void
}

export const VariantChangeModal: React.FC<Props> = (
	{
		existingVariationPropertyValues,
		existingVariant,
		variationProperty,
		universalProperties,
		specificProperties,
		isSalePossible,
		isVisible,
		onClose = () => null,
		onSuccess
	}: Props) => {

	const [errorMessage, setErrorMessage] = useState<string>('');

	const [active, setActive] = useState<boolean>(existingVariant?.active ?? false);
	const [amountAvailable, setAmountAvailable] = useState<number>(existingVariant?.amountAvailable ?? 0);
	const [price, setPrice] = useState<number>(existingVariant?.price ?? 0);
	const [isForSale, setIsForSale] = useState<boolean>(false);
	const [salePercentage, setSalePercentage] = useState<number | undefined>(existingVariant?.saleChangeable?.percentage);
	const [saleEndDate, setSaleEndDate] = useState<string | undefined>(existingVariant?.saleChangeable?.endDate);
	const [manufacturerArticleNumber, setManufacturerArticleNumber] = useState<string>(existingVariant?.manufacturerArticleNumber ?? '');

	const [variantValue, setVariantValue] = useState<string|number>(
		existingVariant?.properties.find(elem => elem.necessity === Necessity.Variation)?.value ?? '');

	const [specificPropValues, setSpecificPropValues] = useState<Property[]>(
		existingVariant?.properties.filter(elem => elem.necessity === Necessity.Specific) ?? specificProperties);


	useEffect(() => {
		console.log('VariantChangeModal existingVariant', existingVariant);
	}, []);


	const onChangeSpecificPropertyValue = (filterId:string, value:string|number) => {
		const newArr = structuredClone(specificPropValues);
		const indexToEdit = newArr.findIndex(elem=>elem.filterId === filterId);
		newArr[indexToEdit].value = value;
		setSpecificPropValues(newArr);
	};

	const onFinish = () => {
		if (onSuccess) {
			if (variantValue == '') {
				setErrorMessage('Varianten-Eigenschaft fehlt');
			} else if (manufacturerArticleNumber === '') {
				setErrorMessage('Herstellernummer fehlt');
			} else if (!price || price === 0) {
				setErrorMessage('Preis fehlt');
			} else if (isSalePossible ? salePercentage ? (salePercentage <= 0) : false : false) {
				setErrorMessage('sale Prozent fehlt');
			} else if (isSalePossible ? saleEndDate ? (new Date(saleEndDate) < new Date()) : false : false) {
				setErrorMessage('Sale Enddatum muss in der Zukunft liegen');
			} else if (amountAvailable === 0 || Number.isNaN(amountAvailable)) {
				setErrorMessage('Stückzahl fehlt');
			} else if (existingVariationPropertyValues.includes(variantValue)) {
				setErrorMessage('Varianten-Parameter existiert bereits');
			} else {
				onSuccess(
					{
						shortDescription: '',
						longDescription: '',
						active: active,
						amountAvailable: amountAvailable,
						price: price,
						saleChangeable: salePercentage ? {percentage: salePercentage, endDate: saleEndDate} : undefined,
						manufacturerArticleNumber: manufacturerArticleNumber,
						properties: universalProperties.concat(specificPropValues).concat({
							...variationProperty,
							value: variantValue
						})
					}
				);
				closeModal();
			}
		}
	};
    
	const closeModal = () => {
		setActive(false);
		setAmountAvailable(0);
		setPrice(0);
		setSaleEndDate(undefined);
		setSalePercentage(undefined);
		setManufacturerArticleNumber('');
		setVariantValue('');
		setSpecificPropValues([]);
		onClose();
	};


	const onChangeIsForSale = (checked: boolean) => {
		setIsForSale(checked);
		if (!checked && setSalePercentage && setSaleEndDate) {
			setSalePercentage(undefined);
			setSaleEndDate(undefined);
		}
	};


	return(
		<Modal isOpen={isVisible} handleClose={closeModal}>
			<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
			<div className="flex items-end sm:items-center justify-center min-h-full p-5 text-center sm:p-0">
				<div className="relative bg-white rounded-lg  pt-5 pb-4 text-left overflow-hidden shadow-xl
                    transform transition-all sm:my-8 w-1/3 h-max sm:p-10">

					<div className={'flex flex-col'}>
						<div className={'text-2xl self-center'}>Variante bearbeiten</div>
						<div className={'flex gap-20'}>

							<div className={'flex-1'}>
								{variationProperty && variationProperty.unit?
									<NumberInputField
										wrapperClasses={'mt-4'}
										title={'Variante / ' + variationProperty?.name + (variationProperty?.unit? ' ('
											+ variationProperty?.unit + ')':'')}
										value={Number(variantValue)}
										setValue={(val)=>setVariantValue(val)}/>
									:
									<PropertyField
										wrapperClasses={'pt-4'}
										title={'Variante / ' + variationProperty?.name}
										filterId={variationProperty.filterId}
										value={String(variantValue)}
										onChange={(value:string)=>setVariantValue(value)}/>
								}
								{specificPropValues && specificPropValues.map((elem) => {
									if (elem.unit) {
										return <NumberInputField
											wrapperClasses={'pt-4'}
											title={elem.name + (elem.unit? ' (' + elem.unit + ')':'')}
											value={Number(elem.value)}
											setValue={(val) => onChangeSpecificPropertyValue(elem.filterId, val)}/>;
									} else {
										return <PropertyField
											wrapperClasses={'pt-4'}
											title={elem.name}
											filterId={elem.filterId}
											value={String(elem.value)}
											onChange={(value:string)=>onChangeSpecificPropertyValue(elem.filterId, value)}/>;
									}
								}
								)}
								<InputField title={'Hersteller Art. Nummer'}
									wrapperclasses={'mt-4'}
									value={manufacturerArticleNumber}
									onChange={(event) => setManufacturerArticleNumber(event.target.value)}
									type={InputFieldType.TEXT}/>

								<div className={'pt-4 flex flex-col gap-4'}>
									<CurrencyInputField
										title="Preis"
										prefix="€"
										value={price}
										setValue={setPrice}/>

									{isSalePossible &&
										<>
											<CheckBox
												wrapperClasses={''}
												label={'Produkt wird im Sale angeboten?'}
												checked={isForSale}
												onChange={(e) => onChangeIsForSale(e.target.checked)}/>

											{isForSale &&
										<div className={'flex flex-col gap-4'}>
											<CurrencyInputField
												title="Reduziert"
												prefix="%"
												percentage={true}
												value={salePercentage ?? 0}
												setValue={(value) => setSalePercentage(value)}/>

											<CustomDatePicker
												dateString={saleEndDate ?? ''}
												setDateString={(date) => setSaleEndDate(date)}
												title={'Angebotsende'}/>
										</div>
											}
										</>
									}
								</div>

								<IntegerInputField
									wrapperClasses={'pt-4'}
									title="Stückzahl"
									value={amountAvailable}
									setValue={setAmountAvailable}/>

								<CheckBox
									wrapperClasses="pt-4 pl-0.5"
									label={'Status / Sichtbar auf dem Marktplatz?'}
									checked={active}
									onChange={(e)=>{setActive(e.target.checked);}}/>
							</div>
						</div>

						{errorMessage != '' &&
                            <div className="flex justify-center"><div className="text-red-600 pt-4">{errorMessage}</div></div>
						}

						<div className={'flex flex-row self-end mt-6 gap-6'}>
							<Button label={'Abbrechen'} onClick={closeModal}/>
							<Button label={'Fertig'} onClick={onFinish}/>
						</div>
					</div>
				</div>
			</div>
		</Modal>
	);
};