import {ChatMessage} from './chatMessage';
import {User} from './user';

export type ChatInterface = {
    id:string
    participantIDs: string[]
    participants: User[]
    chatType:ChatType
    messages:ChatMessage[]
    created:string
    closed:string
}

export enum ChatType {
    complaint = 'COMPLAINT',
}

