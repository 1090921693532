import InputField from '../inputField/InputField';
import classNames from 'classnames';
import {useEffect, useState} from 'react';

interface SearchBarData {
	id: string;
	name: string;
}

interface Props {
	searchStr: string
	setSearchStr: React.Dispatch<React.SetStateAction<string>>

	showDropdown?: boolean
	onClickResult?: (id: string, name: string) => void
	results?: SearchBarData[];
	wrapperClasses?: string;
}

export default function SearchBar(
	{
		results,
		searchStr,
		setSearchStr,
		onClickResult,
		showDropdown,
		wrapperClasses
	}: Props) {
	const [searchStrDelayed, setSearchStrDelayed] = useState<string>(searchStr);

	useEffect(() => {
		const timeout = setTimeout(() => setSearchStr(searchStrDelayed), 400);
		return () => {
			clearTimeout(timeout);
		};
	}, [searchStrDelayed]);

	function handleOnClickResult(id: string, name: string) {
		if (onClickResult) {
			onClickResult(id, name);
		}
		setSearchStr('');
	}

	return (
		<div className={classNames(wrapperClasses)}>
			<div className={'w-full relative'}>
				<InputField
					wrapperclasses={'relative'}
					title={'Suche'}
					value={searchStrDelayed}
					onChange={(e) => setSearchStrDelayed(e.target.value)}/>

				{showDropdown && results && searchStr !== '' && (
					<div className={'w-full bg-white flex flex-col border-2 rounded-md absolute top-full z-20'}>
						{
							results && results.map((elem: SearchBarData) => (
								<div
									className={'px-2 py-1 rounded-md cursor-pointer hover:bg-gray-100'}
									onMouseDown={(e) => e.preventDefault()} // somehow prevents onBlur form highest div, befor onClick happens...
									onClick={() => handleOnClickResult(elem.id, elem.name)}>
									{elem.name}
								</div>
							))
						}
					</div>
				)}
			</div>
		</div>
	);
}
