import React, {ChangeEvent, FC, useState} from 'react';
import classNames from 'classnames';
import {AiFillEye, AiFillEyeInvisible} from 'react-icons/ai';


interface Props {
    title: string;
    placeholder?: string;
    onChange:    React.ChangeEventHandler<HTMLInputElement>
    value: string;
    type: string;
    wrapperClasses?: string;
}

const  PasswordInputField: FC<Props> = (props) => {
	const [visible, setVisible] = useState<boolean>(false);

	const onVisible = () => {
		setVisible(!visible);
	};

	return (
		<div className={
			classNames('', props.wrapperClasses)
		}>
			<label className="block text-sm font-medium text-gray-700"> {props.title} </label>

			<div className="relative">
				<input {...props} type={visible?'text':'password'} className=" w-full rounded-md border border-gray-300 px-3 py-2
			placeholder-gray-400 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"/>

				<div className="absolute top-3 right-5">
					{visible ? <AiFillEyeInvisible onClick={onVisible}/> : <AiFillEye onClick={onVisible}/> }
				</div>
			</div>

		</div>
	);
};

export default PasswordInputField;