import classNames from 'classnames';
import React, {useEffect, useState} from 'react';
import {FileInterface} from '../../../../../../../interfaces';
import axios from 'axios';
import {ENDPOINTS} from '../../../../../../../constants';
import IconButton, {IconType} from '../../../../../../../components/button/IconButton';
import {ButtonType} from '../../../../../../../components/button/Button';


interface Props {
    fileIds:string[]
	setFileIds: React.Dispatch<React.SetStateAction<string[]>>

	files: FileInterface[]
	setFiles: React.Dispatch<React.SetStateAction<FileInterface[]>>

	fileType		:string
	accept			:string
    wrapperClasses?	:string
}

function EditFilesList(
	{
		fileType,
		fileIds, setFileIds,
		files, setFiles,
		accept,
		wrapperClasses
	} : Props) {

	const fileRef = React.useRef<HTMLInputElement>(null);
	const [newFile, setNewFile] = useState<File | null>(null);
	const [fetchedFiles, setFetchedFiles] = useState<FileInterface[]>([]);


	useEffect(() => {
		if (fileIds && fileIds.length > 0){

			axios.get(ENDPOINTS.getFiles, {params:{fileIds:fileIds}})
				.then((response) => {
					setFetchedFiles(response.data['files']);
				})
				.catch(errors => {
					console.log(errors);
				});
		}
		else {
			setFetchedFiles([]);
		}
	}, [fileIds]);


	useEffect(() => {
		if (newFile) {
			const reader = new FileReader();
			reader.onload = () => {

				const arrBase64 = (reader.result as string).split(',');
				if (arrBase64.length == 2){
					setFiles(files.concat([{fileType:fileType, data:arrBase64[1], id:'', name:newFile.name, contentType:''}]));
				}
			};
			reader.readAsDataURL(newFile);
		}
	}, [newFile]);


	function DeleteNewFile(index:number) {
		const newFiles:FileInterface[] = structuredClone(files);
		newFiles.splice(index, 1);
		setFiles(newFiles);
	}

	function DeleteFetchedFile(index:number) {
		const newFiles:FileInterface[] = structuredClone(fetchedFiles);

		const newFileIds:string[] = structuredClone(fileIds);
		const indexToDelete:number = newFileIds.findIndex(elem=>elem === newFiles[index].id);
		newFileIds.splice(indexToDelete, 1);
		setFileIds(newFileIds);

		newFiles.splice(index, 1);
		setFetchedFiles(newFiles);
	}

	return(
		<div className={classNames(wrapperClasses)}>

			<div className="w-96 flex flex-col py-2 px-4 gap-2 border rounded-lg">

				{fetchedFiles && fetchedFiles.map((file, index) => (
					<div className="flex flex-row p-2 border rounded-lg hover:border-blue-700 relative group">
						<div>
							{file.name}
						</div>

						<div className="flex flex-row gap-1 absolute right-1.5 top-1.5 ">
							<IconButton
								wrapperClasses="hidden group-hover:block"
								icon={IconType.TRASH}
								onClick={()=>DeleteFetchedFile(index)}/>
						</div>
					</div>
				))}

				{files && files.map((file, index) => (
					<div className="flex flex-row p-2 border rounded-lg hover:border-blue-700 relative group">
						<div>
							{file.name}
						</div>

						<div className="flex flex-row gap-1 absolute right-1.5 top-1.5 ">
							<IconButton
								wrapperClasses="hidden group-hover:block"
								icon={IconType.TRASH}
								onClick={()=>DeleteNewFile(index)}/>
						</div>
					</div>
				))}

				<input
					type="file"
					onChange={(e)=>setNewFile(e.target.files?e.target.files[0]:null)}
					style={{display:'none'}}
					ref={fileRef}
					accept={accept}/>

				<IconButton
					wrapperClasses="self-center"
					icon={IconType.PLUS}
					onClick={()=>fileRef.current?.click()}/>
			</div>
		</div>
	);
}

export default EditFilesList;
