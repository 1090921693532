// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace Constants {
    // eslint-disable-next-line @typescript-eslint/no-namespace
    export namespace Permissions {
        export const OrderBundlesPermit = 'ORDER_BUNDLES.PERMIT';
        export const WebUse = 'WEB_USE';
        export const AppUse = 'APP_USE';
    }

    // eslint-disable-next-line @typescript-eslint/no-namespace
    export namespace PermissionGroupNames {
        export const Worker = 'DEFAULT_WORKER';
        export const FOREMAN = 'DEFAULT_FOREMAN';
        export const PRODUCTMANAGER = 'DEFAULT_PRODUCT_MANAGER';
        export const CTO = 'DEFAULT_CTO';
    }

    // eslint-disable-next-line @typescript-eslint/no-namespace
    export namespace UserStates {
        export const Active = 'ACTIVE';
        export const Inactive = 'INACTIVE';
        export const Blocked = 'BLOCKED';
        export const Invited = 'INVITED';
    }

    // eslint-disable-next-line @typescript-eslint/no-namespace
    export namespace SortDirection {
        export const ASC = 'ASC';
        export const DESC = 'DESC';
    }

    // eslint-disable-next-line @typescript-eslint/no-namespace
    export namespace OrderBundleStatus {
        export const OrderBundleInProcess = 'IN_PROCESS';
        export const OrderBundleFinished = 'FINISHED';
    }

    // eslint-disable-next-line @typescript-eslint/no-namespace
    export namespace OrderBundleSortField {
        export const BundleDate = 'date';
        export const BundleNumber = 'bundleNumber';
    }

    // eslint-disable-next-line @typescript-eslint/no-namespace
    export namespace ExaminationStatus {
        export const ExaminationStatusRequested = 'REQUESTED';
        export const ExaminationStatusDenied = 'DENIED';
        export const ExaminationStatusPermitPending = 'PERMIT_PENDING';
        export const ExaminationStatusPermitted = 'PERMITTED';
        export const ExaminationStatusForwarded = 'FORWARDED';
    }

    // eslint-disable-next-line @typescript-eslint/no-namespace
    export namespace FileType {
        export const ProductImage = 'PRODUCT_IMAGE';
        export const ProductPlan = 'PRODUCT_PLAN';
        export const ProductDataSheet = 'PRODUCT_DATA_SHEET';
        export const CategoryImage = 'CATEGORY_IMAGE';
        export const ManufacturerImage = 'MANUFACTURER_IMAGE';
        export const MachineTypeImage = 'MACHINE_TYPE_IMAGE';
        export const CompanyLogoImage = 'COMPANY_LOGO_IMAGE';
        export const FilterIconImage = 'FILTER_ICON_IMAGE';
        export const DxfFile = 'DXF_FILE';
        export const StpFile = 'STP_FILE';
    }

    // eslint-disable-next-line @typescript-eslint/no-namespace
    export namespace Necessity {
        export const Variation = 'VARIATION';
        export const Specific = 'SPECIFIC';
        export const Universal = 'UNIVERSAL';
    }

}

export const ImageMimes:Array<string> = ['image/png', 'image/jpeg', 'image/svg', 'image/gif'];