import FormatDate from '../../utils/date';
import React from 'react';


interface Props {
    date:string
}

export const ChatMessageHistoryDateIndicator = (props:Props) => {
	return(
		<div className={'flex items-center'}>
			<div className="flex-grow border-t border-gray-200"></div>
			<div className={'py-0.5 px-3 flex justify-center border rounded-xl bg-gray-50'}>
				<div className={''}>
					{FormatDate(props.date)}
				</div>
			</div>
			<div className="flex-grow border-t border-gray-200 "></div>
		</div>
	);
};