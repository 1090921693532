import {Outlet} from 'react-router-dom';
import React from 'react';

export default function CategorySaleOverviewHeader() {
	return (
		<div>
			<div className="flex justify-start pl-8">
				<h1 className="text-2xl text-gray-900">Rabatt auf Kategorien Übersicht</h1>
			</div>
			<div>
				<Outlet/>
			</div>
		</div>
	);
}