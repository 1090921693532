import React from 'react';
import {Modal} from './Modal';
import Button, {ButtonType} from '../button/Button';
import IconButton, {IconType} from '../button/IconButton';

interface Props {
	isVisible: boolean
	text: string
	buttonTitle?:string
	onClose: () => void
	onSuccess: () => void
}

function ConfirmationModal({onClose, onSuccess, text, buttonTitle = 'Akzeptieren', isVisible}: Props) {

	return(
		<Modal isOpen={isVisible} handleClose={onClose}>
			<div className="relative z-10">
				<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
				<div className="fixed z-10 inset-0 overflow-y-auto">
					<div className="flex items-end sm:items-center justify-center min-h-full p-5 text-center sm:p-0">

						<div className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl
						transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6">

							<div className="flex flex-col gap-5">
								<div className={'self-end'}>
									<IconButton
										onClick={() => onClose()}
										icon={IconType.X_MARK}/>
								</div>

								<div>{text}</div>

								<div className={'flex flex-col items-center'}>
									<div className={'flex justify-center gap-2'}>
										<Button
											buttonType={ButtonType.Secondary}
											label={'Abbrechen'}
											onClick={() => onClose()}/>
										<Button
											label={buttonTitle}
											onClick={() => onSuccess()}/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Modal>
	);
}

export default ConfirmationModal;