import React from 'react';
import { Outlet } from 'react-router-dom';

const BaseLayout = () => {
	return (
		<>
			<main className="content-container h-screen w-screen">
				<Outlet/>
			</main>
		</>
	);
};

export default BaseLayout;