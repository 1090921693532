import {Outlet, useNavigate, useParams} from 'react-router-dom';
import React from 'react';

export default function CreateProductHeader(){
	const navigate = useNavigate();

	return(
		<>
			<div className="flex justify-start pl-8">
				<h1 className="text-2xl self-center cursor-pointer hover:text-gray-500">
					Produkt erstellen
				</h1>
			</div>
			<div>
				<Outlet/>
			</div>
		</>
	);
}