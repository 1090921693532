import React, {useEffect, useState} from 'react';
import Button from '../../../../components/button/Button';
import {IconType} from '../../../../components/button/IconButton';
import {useNavigate, useParams} from 'react-router-dom';
import {APP_ROUTES} from '../../../../constants';
import DescriptionTab from '../tabs/description/DescriptionTab';
import PropertiesTab from '../tabs/properties/PropertiesTab';
import ProductManagementTabBar from '../tabs/ProductManagementTabBar';
import {checkProperties} from '../helpers';
import {
	FileInterface,
	Manufacturer,
	Product,
	Property,
	Variant,
	VariantBuilder,
	VariantBuilderState
} from '../../../../interfaces';
import {API} from '../../../../api';
import {useErrorHandling} from '../../../../providers/error/ErrorProvider';
import EditProductInformationTab from './tabs/EditProductInformationTab';
import EditProductImageTab from './tabs/images/EditProductImageTab';
import EditProductFilesTab from './tabs/files/EditProductFilesTab';


export default function EditProductWithoutVariants() {
	const {productId} = useParams<string>();
	const navigate = useNavigate();
	const errorHandling = useErrorHandling();

	const [errorMessage, setErrorMessage] = useState<string>('');
	const [fetchComplete, setFetchComplete] = useState<boolean>(false);

	const [variantBuilder, setVariantBuilder] = useState<VariantBuilder>(new VariantBuilder(VariantBuilderState.update));

	const [tabIndex, setTabIndex] = useState<number>(0);

	const [name, setName] = useState<string>('');
	const [manufacturer, setManufacturer] = useState<Manufacturer>();
	const [categoryId, setCategoryId] = useState<string>('');
	const [shortDescription, setShortDescription] = useState<string>('');
	const [longDescription, setLongDescription] = useState<string>('');
	const [isActive, setIsActive] = useState<boolean>(false);

	const [manufacturerArticleNumber, setManufacturerArticleNumber] = useState<string>('');
	const [amountAvailable, setAmountAvailable] = useState<number>(0);
	const [price, setPrice] = useState<number>(0);
	const [properties, setProperties] = useState<Property[]>([]);

	const [images, setImages] = useState<FileInterface[]>([]);
	const [plans, setPlans] = useState<FileInterface[]>([]);
	const [dataSheets, setDataSheets] = useState<FileInterface[]>([]);
	const [dxfFiles, setDxfFiles] = useState<FileInterface[]>([]);
	const [stpFiles, setStpFiles] = useState<FileInterface[]>([]);

	const [imageIds, setImageIds] = useState<string[]>([]);
	const [planIds, setPlanIds] = useState<string[]>([]);
	const [dataSheetIds, setDataSheetIds] = useState<string[]>([]);
	const [dxfFileIds, setDxfFileIds] = useState<string[]>([]);
	const [stpFileIds, setStpFileIds] = useState<string[]>([]);

	useEffect(() => {
		setFetchComplete(false);

		const fetchProduct = async () => {
			try {
				return await API.Product.getFromCompany(productId ?? '');
			} catch (e: any) {
				errorHandling(e);
			}
		};

		fetchProduct().then((resp) => {
			const product: Product = resp?.data;
			if (!product.variants || product.variants.length !== 1) {
				throw new Error('product dont have exactly one variant');
			}
			const variant: Variant = resp?.data.variants[0];
			setVariantBuilder(new VariantBuilder(VariantBuilderState.update, variant));

			setManufacturerArticleNumber(variant.manufacturerArticleNumber);
			setPrice(variant.price);
			setAmountAvailable(variant.amountAvailable);
			setIsActive(variant.active);
			setCategoryId(variant.categoryId);
			setShortDescription(variant.shortDescription);
			setLongDescription(variant.longDescription);
			setImageIds(variant.imageIds);
			setPlanIds(variant.planIds);
			setDataSheetIds(variant.dataSheetIds);
			setDxfFileIds(variant.dxfFileIds);
			setStpFileIds(variant.stpFileIds);
			setName(variant.name);
			setProperties(variant.properties);

			setManufacturer(product.manufacturer);

			setFetchComplete(true);
		})
			.catch(errorHandling);
	}, []);

	// setting state of variantBuilder
	useEffect(() => {
		const newVariantBuilder: VariantBuilder = variantBuilder;
		newVariantBuilder.setChange(
			{
				properties: properties,
				active: isActive,
				shortDescription: shortDescription,
				longDescription: longDescription,
				saleChangeable: undefined,
				price: price,
				amountAvailable: amountAvailable,
				manufacturerArticleNumber: manufacturerArticleNumber,
			});
		setVariantBuilder(newVariantBuilder);

	}, [name, properties, isActive, shortDescription, longDescription, price, amountAvailable, manufacturerArticleNumber, manufacturer]);


	const onSaveAndExit = async () => {
		setErrorMessage('');

		const files = images.concat(plans).concat(dataSheets).concat(dxfFiles).concat(stpFiles);

		if (!manufacturer){
			setErrorMessage('Hersteller fehlt');
		} else if (name === ''){
			setErrorMessage('Name fehlt');
		} else if (manufacturerArticleNumber === ''){
			setErrorMessage('Herstellernummer fehlt');
		} else if (categoryId === ''){
			setErrorMessage('Kategorie fehlt');
		} else if (amountAvailable === 0 || Number.isNaN(amountAvailable)) {
			setErrorMessage('Stückzahl fehlt');
		} else if (!price || price === 0) {
			setErrorMessage('Preis fehlt');
		} else if (!checkProperties(properties)) {
			setErrorMessage('Eigenschaften fehlerhaft');
		} else {

			try {
				await API.Product.update(
					productId ?? '',
					name,
					manufacturerArticleNumber,
					categoryId,
					manufacturer.id,
					shortDescription,
					longDescription,
					[],
					[],
					files,
					imageIds, planIds, dataSheetIds, dxfFileIds, stpFileIds
				);


				await variantBuilder?.apiCall(productId ?? '');
				navigate(APP_ROUTES.products);

			} catch (e: any) {
				errorHandling(e);
			}
		}
	};

	return (
		<div className={'px-8 mt-3 w-full'}>

			<div className={'flex border-b border-gray-200'}>
				<ProductManagementTabBar
					wrapperClasses={'flex gap-5 flex-1'}
					tabIndex={tabIndex}
					setTabIndex={setTabIndex}
					hasVariants={false}/>

				<div className="flex self-center">
					<Button
						label="Abbrechen"
						onClick={()=>navigate(APP_ROUTES.products)}
						icon={IconType.WITHOUT} />
					<Button
						wrapperClasses="pl-5"
						label="Speichern und beenden"
						onClick={()=>onSaveAndExit()}
						icon={IconType.CHECK} />
				</div>
			</div>

			{fetchComplete &&
				<>
					{tabIndex === 0 &&
						<div className={'pt-8'}>

							<EditProductInformationTab
								wrapperClasses={'pt-8'}
								hasVariants={false}
								name={name}
								setName={setName}
								manufacturer={manufacturer}
								setManufacturer={setManufacturer}
								manufacturerArticleNumber={manufacturerArticleNumber}
								setManufacturerArticleNumber={setManufacturerArticleNumber}
								isActive={isActive}
								setIsActive={setIsActive}
								amountAvailable={amountAvailable}
								setAmountAvailable={setAmountAvailable}
								price={price}
								setPrice={setPrice}/>
						</div>
					}
					{tabIndex === 1 &&
						<PropertiesTab
							wrapperClasses={'w-1/3 pt-8'}
							categoryId={categoryId}
							properties={properties}
							setProperties={setProperties}/>
					}
					{tabIndex === 2 &&
						<DescriptionTab
							wrapperClasses={'pt-8'}
							shortDescription={shortDescription}
							setShortDescription={setShortDescription}
							longDescription={longDescription}
							setLongDescription={setLongDescription}/>
					}
					{tabIndex === 3 &&
						<EditProductImageTab
							wrapperClasses={'pt-8 w-1/2'}
							imageIds={imageIds}
							setImageIds={setImageIds}
							planIds={planIds}
							setPlanIds={setPlanIds}
							images={images}
							setImages={setImages}
							plans={plans}
							setPlans={setPlans}/>
					}
					{tabIndex === 4 &&
						<EditProductFilesTab
							wrapperClasses={'w-2/3 pt-8'}
							dataSheets={dataSheets}
							setDataSheets={setDataSheets}
							dataSheetIds={dataSheetIds}
							setDataSheetIds={setDataSheetIds}
							dxfFiles={dxfFiles}
							setDxfFiles={setDxfFiles}
							dxfFileIds={dxfFileIds}
							setDxfFileIds={setDxfFileIds}
							stpFiles={stpFiles}
							setStpFiles={setStpFiles}
							stpFileIds={stpFileIds}
							setStpFileIds={setStpFileIds}/>
					}
				</>
			}
			{errorMessage != '' &&
				<div className="flex justify-center">
					<div className="text-red-600 py-2">{errorMessage}</div>
				</div>
			}
		</div>
	);
}
