import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {ENDPOINTS} from '../../../constants';
import DeleteAddressModal from './address/DeleteAddressModal';
import AddAddressModal from './address/AddAddressModal';
import ImageButton from '../../../components/button/ImageButton';
import IconButton, {IconType} from '../../../components/button/IconButton';
import EditAddressModal from './address/EditAddressModal';
import DataTable, {TableColumn} from 'react-data-table-component';
import {Address, Company} from '../../../interfaces';


export default function Information(){
	const [isAddModalVisible, setIsAddModalVisible] = useState<boolean>(false);
	const [isEditModalVisible, setIsEditModalVisible] = useState<boolean>(false);
	const [isDeleteModalVisible, setIsDeleteModalVisible] = useState<boolean>(false);
	const [addressToEdit, setAddressToEdit] = useState<Address|undefined>(undefined);
	const [company, setCompany] = useState<Company>();

	const imageFileRef = React.useRef<HTMLInputElement>(null);
	const [imageFile, setImageFile] = useState<File | null>(null);

	useEffect(() => {
		loadCompany().then();
	}, []);

	const loadCompany = async () =>{
		axios.get(ENDPOINTS.ownCompany)
			.then((response) => {
				setCompany(response.data);
			})
			.catch(errors => {
				console.log(errors);
			});
	};

	const updateCompanyLogo = async (data:string) =>{
		axios.put(ENDPOINTS.updateUserCompany ,
			{'imageData': data})
			.then((response) => {
				setCompany(response.data);
			})
			.catch(errors => {
				console.log(errors);
			});
	};


	useEffect(() => {
		if (imageFile) {
			const reader = new FileReader();
			reader.onload = () => {

				const arrB64 = (reader.result as string).split(',');
				if (arrB64.length == 2){
					void updateCompanyLogo(arrB64[1]);
				}
			};
			reader.readAsDataURL(imageFile);
		}
	}, [imageFile]);


	function onCloseAddModal() {
		setIsAddModalVisible(false);
		loadCompany().then();
	}
	
	function onCloseEditModal() {
		setIsEditModalVisible(false);
		setAddressToEdit(undefined);
		loadCompany().then();
	}

	function onCloseDeleteModal() {
		setIsDeleteModalVisible(false);
		setAddressToEdit(undefined);
		loadCompany().then();
	}

	function handleEditClick(address: Address) {
		setAddressToEdit(address);
		setIsEditModalVisible(true);
	}

	function handleDeleteClick(address: Address) {
		setAddressToEdit(address);
		setIsDeleteModalVisible(true);
	}

	const colums: TableColumn<Address>[] = [
		{
			name:'Adresstitel',
			selector: row => row.title,
			center:true,
			width: '240px'
		},
		{
			name:'Adresse',
			cell: (row) =>
				<div className="flex flex-col justify-start py-3">
					<div>{row.country}</div>
					<div>{row.zip}&nbsp;{row.city}</div>
					<div>{row.street}&nbsp;{row.streetNumber}</div>
				</div>,
			width: '240px'
		},
		{
			name:'',
			right:true,
			grow:1,
			cell: (row) =>
				<div className="flex items-center gap-2 pr-4">
					<IconButton
						onClick={() => handleEditClick(row)}
						icon={IconType.PENCIL}
						title='Produkt bearbeiten'/>
					<IconButton
						onClick={() => handleDeleteClick(row)}
						icon={IconType.TRASH}
						title='Produkt löschen'/>
				</div>
		},
	];

	return(
		<div className="px-10 w-1/2">

			<div className="flex gap-5 pt-6">
				<input
					type="file"
					onChange={(e)=>setImageFile(e.target.files?e.target.files[0]:null)}
					style={{display:'none'}}
					ref={imageFileRef}
					accept="image/png, image/jpeg"/>

				<ImageButton
					onClick={()=>imageFileRef.current?.click()}
					imageId={company?company.imageId:''}/>
				
				<div className="pt-7 text-2xl">
					{company?.name}
				</div>
			</div>


			<div className="flex flex-col pt-10 justify-end">
				<div className="flex justify-center text-2xl">Standorte</div>
				<IconButton
					wrapperClasses="self-end"
					icon={IconType.PLUS}
					onClick={()=>setIsAddModalVisible(true)}/>
			</div>

			<DataTable
				columns={colums}
				data={company?.addresses??[]}/>

			{company !== undefined &&
				<>
					<AddAddressModal
						isVisible={isAddModalVisible}
						onSuccess={onCloseAddModal}
						onClose={()=>setIsAddModalVisible(false)}
						company={company}/>

					{addressToEdit !== undefined &&
						<>
							{isEditModalVisible &&
								<EditAddressModal
									isVisible={isEditModalVisible}
									onSuccess={onCloseEditModal}
									onClose={onCloseEditModal}
									company={company}
									address={addressToEdit}/>
							}

							{isDeleteModalVisible &&
								<DeleteAddressModal
									isVisible={isDeleteModalVisible}
									onSuccess={onCloseDeleteModal}
									onClose={onCloseDeleteModal}
									company={company}
									address={addressToEdit}/>
							}
						</>
					}
				</>
			}
		</div>
	);
}
