import classNames from 'classnames';
import Divider from '../../../../../../components/Divider';
import EditFilesList from './components/EditFilesList';
import {Constants} from '../../../../../../constants';
import React from 'react';
import {FileInterface} from '../../../../../../interfaces';

interface Props {
    dataSheets: FileInterface[]
    setDataSheets: React.Dispatch<React.SetStateAction<FileInterface[]>>

    dataSheetIds: string[]
    setDataSheetIds: React.Dispatch<React.SetStateAction<string[]>>

    dxfFiles: FileInterface[]
    setDxfFiles: React.Dispatch<React.SetStateAction<FileInterface[]>>

    dxfFileIds: string[]
    setDxfFileIds: React.Dispatch<React.SetStateAction<string[]>>

    stpFiles: FileInterface[]
    setStpFiles: React.Dispatch<React.SetStateAction<FileInterface[]>>

    stpFileIds: string[]
    setStpFileIds: React.Dispatch<React.SetStateAction<string[]>>

    wrapperClasses?: string
}


export default function EditProductFilesTab(
	{
		dataSheets, setDataSheets,
		dataSheetIds, setDataSheetIds,
		dxfFiles, setDxfFiles,
		dxfFileIds, setDxfFileIds,
		stpFiles, setStpFiles,
		stpFileIds, setStpFileIds,
		wrapperClasses
	}: Props) {


	return (
		<div className={classNames(wrapperClasses)}>
			<div className={'flex gap-16'}>
				<div className={'flex-1'}>
					<Divider title="Datenblätter"/>
					<EditFilesList
						files={dataSheets}
						setFiles={setDataSheets}
						fileIds={dataSheetIds}
						setFileIds={setDataSheetIds}
						fileType={Constants.FileType.ProductDataSheet}
						accept="application/pdf"/>
				</div>

				<div className={'flex-1'}>
					<Divider title="DXF-Dateien"/>
					<EditFilesList
						files={dxfFiles}
						setFiles={setDxfFiles}
						fileIds={dxfFileIds}
						setFileIds={setDxfFileIds}
						fileType={Constants.FileType.DxfFile}
						accept=".dxf"/>
				</div>

				<div className={'flex-1'}>
					<Divider title="STP-Dateien"/>
					<EditFilesList
						files={stpFiles}
						setFiles={setStpFiles}
						fileIds={stpFileIds}
						setFileIds={setStpFileIds}
						fileType={Constants.FileType.StpFile}
						accept=".stp"/>
				</div>
			</div>
		</div>
	);
}