import React from 'react';

const PasswordResetConfirmation = () => {
	return(
		<div className={'flex flex-col justify-center items-center h-full text-2xl'}>
			<img
				className="mx-auto h-12 w-auto"
				src="/inopartLogo.jpeg"
				alt="Your Company"
			/>
			<p className="py-5">Sie erhalten nun einen Link, um ihr Passwort zurück zu setzen.</p>
		</div>
	);
};

export default PasswordResetConfirmation;