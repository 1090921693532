import React from 'react';
import {Manufacturer} from '../../../../../interfaces';
import classNames from 'classnames';
import InputField, {InputFieldType} from '../../../../../components/inputField/InputField';
import ManufacturerSelection from '../../components/ManufacturerSelection';
import CurrencyInputField from '../../../../../components/inputField/CurrencyInputField';
import IntegerInputField from '../../../../../components/inputField/IntegerInputField';
import CheckBox from '../../../../../components/checkBox/CheckBox';

interface Props {
    hasVariants: boolean;

    name: string;
    setName: React.Dispatch<React.SetStateAction<string>>;

    manufacturer: Manufacturer | undefined;
    setManufacturer: React.Dispatch<React.SetStateAction<Manufacturer | undefined>>;

    manufacturerArticleNumber: string;
    setManufacturerArticleNumber: React.Dispatch<React.SetStateAction<string>>;

    isActive: boolean;
    setIsActive: React.Dispatch<React.SetStateAction<boolean>>;

    amountAvailable: number;
    setAmountAvailable: React.Dispatch<React.SetStateAction<number>>;

    price: number;
    setPrice: React.Dispatch<React.SetStateAction<number>>;

    wrapperClasses?: string;
}

export default function EditProductInformationTab(
	{
		wrapperClasses,
		hasVariants,
		name, setName,
		manufacturer, setManufacturer,
		manufacturerArticleNumber, setManufacturerArticleNumber,
		isActive, setIsActive,
		amountAvailable, setAmountAvailable,
		price, setPrice,
	}: Props) {


	return (
		<div className={classNames(wrapperClasses)}>
			<div className={'flex gap-16'}>
				<div>
					<InputField
						type={InputFieldType.TEXT}
						value={name}
						title="Name"
						onChange={(e) => setName(e.target.value)}/>

					<ManufacturerSelection
						wrapperClasses={'pt-4'}
						selectedManufacturer={manufacturer}
						setSelectedManufacturer={setManufacturer}/>
				</div>

				{!hasVariants &&
					<>
						<InputField
							type={InputFieldType.TEXT}
							value={manufacturerArticleNumber}
							title="Herstellernummer"
							onChange={(e) => setManufacturerArticleNumber(e.target.value)}/>
                        
						<CurrencyInputField
							title="Preis"
							prefix="€"
							value={price}
							setValue={setPrice}/>

						<IntegerInputField
							title="Stückzahl"
							value={amountAvailable}
							setValue={setAmountAvailable}
						/>

						<CheckBox
							wrapperClasses="pt-4 pl-0.5"
							label={'Status / Sichtbar auf dem Marktplatz?'}
							checked={isActive}
							onChange={(e) => {
								setIsActive(e.target.checked);
							}}
						/>
					</>
				}
			</div>
		</div>
	)
	;
}