import classNames from 'classnames';
import Divider from '../../../../../../components/Divider';
import ImageSelectionList from './components/ImageSelectionList';
import {Constants} from '../../../../../../constants';
import React from 'react';
import {FileInterface} from '../../../../../../interfaces';


interface Props {
    images: FileInterface[]
    setImages: React.Dispatch<React.SetStateAction<FileInterface[]>>
    plans: FileInterface[]
    setPlans: React.Dispatch<React.SetStateAction<FileInterface[]>>
    wrapperClasses?: string;
}

export default function CreateProductImageTab(
	{
		images, setImages,
		plans, setPlans,
		wrapperClasses
	}: Props) {


	return (
		<div className={classNames(wrapperClasses)}>
			<div className={'pt-8 flex gap-16'}>
				<div className={'flex-1'}>
					<Divider title="Produktbilder"/>
					<ImageSelectionList
						wrapperClasses="py-4"
						files={images}
						setFiles={setImages}
						fileType={Constants.FileType.ProductImage}/>
				</div>

				<div className={'flex-1'}>
					<Divider title="Technische Zeichnungen"/>
					<ImageSelectionList
						wrapperClasses="py-4"
						files={plans}
						setFiles={setPlans}
						fileType={Constants.FileType.ProductPlan}/>
				</div>
			</div>
		</div>
	);
}