import React from 'react';
import {Outlet} from 'react-router-dom';
import SideBar from './components/SideBar';
import TopBar from './components/TopBar';

const Dashboard = () => {
	return (
		<div className="grid grid-cols-[min-content_1fr] grid-rows-[min-content_1fr] h-full ">
			<div className="col-start-1 col-end-2 row-start-1 row-end-3"><SideBar/></div>
			<div className="justify-self-end pr-4 col-start-2 col-end-3 row-start-1 row-end-2"><TopBar/></div>
			<div className="col-start-2 col-end-3 row-start-2 row-end-3"><Outlet/></div>
		</div>
	);
};

export default Dashboard;
