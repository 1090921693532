import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {Constants, ENDPOINTS} from '../../../constants';
import ComboBox from '../../../components/comboBox/ComboBox';
import classNames from 'classnames';
import CheckBox from '../../../components/checkBox/CheckBox';
import {PermissionGroup, PermissionGroupDisplayed} from '../../../interfaces';

export const PermissionGroupsDisplayed: PermissionGroupDisplayed[] = [
	{
		name: 'Universal',
		permissionGroup: {
			name: Constants.PermissionGroupNames.CTO,
			companyId: '',
			permissions: [],
			template: '',
			id: '',
		},
	},
	{
		name: 'Arbeiter',
		permissionGroup: {
			name: Constants.PermissionGroupNames.Worker,
			companyId: '',
			permissions: [],
			template: '',
			id: '',
		},
	},
	{
		name: 'Vorgesetzter',
		permissionGroup: {
			name: Constants.PermissionGroupNames.FOREMAN,
			companyId: '',
			permissions: [],
			template: '',
			id: '',
		},
	},
	{
		name: 'Produkt-Verwalter',
		permissionGroup: {
			name: Constants.PermissionGroupNames.PRODUCTMANAGER,
			companyId: '',
			permissions: [],
			template: '',
			id: '',
		},
	},
];

interface Props {
	selectedPermissionGroup: PermissionGroup | undefined
	setSelectedPermissionGroup: React.Dispatch<React.SetStateAction<PermissionGroup | undefined>>
	webUsePermission: boolean
	setWebUsePermission: React.Dispatch<React.SetStateAction<boolean>>
	appUsePermission: boolean
	setAppUsePermission: React.Dispatch<React.SetStateAction<boolean>>
	wrapperClasses?: string
}

function PermissionsSelection(
	{
		appUsePermission,
		setAppUsePermission,
		webUsePermission,
		setWebUsePermission,
		selectedPermissionGroup,
		setSelectedPermissionGroup,
		wrapperClasses
	} : Props){
	const [permGroupsDisplayed, setPermGroupsDisplayed] = useState<PermissionGroupDisplayed[]>(PermissionGroupsDisplayed);

	useEffect(() => {
		axios.get(ENDPOINTS.listPermissionGroups)
			.then((resp) => {
				const permGroups: PermissionGroup[] = resp.data['permissionGroups'];

				let newDisplayPermGroups: PermissionGroupDisplayed[] = [];
				if (permGroups !== undefined) {
					for (let i = 0; i < permGroups.length; i++) {
						const found = PermissionGroupsDisplayed.find(elem => elem.permissionGroup.name === permGroups[i].name);
						if (found !== undefined) {
							found.permissionGroup = permGroups[i];
							newDisplayPermGroups = newDisplayPermGroups.concat(found);
						}
					}
				}
				setPermGroupsDisplayed(newDisplayPermGroups);
			})
			.catch(errors =>{
				console.log(errors);
			});
	}, []);


	useEffect(() => {
		if (selectedPermissionGroup){

			let res = selectedPermissionGroup.permissions.find((item) => item === Constants.Permissions.AppUse);
			if(res){
				setAppUsePermission(true);
			} else {
				setAppUsePermission(false);
			}

			res = selectedPermissionGroup.permissions.find((item) => item === Constants.Permissions.WebUse);
			if(res){
				setWebUsePermission(true);
			} else {
				setWebUsePermission(false);
			}
		}
	}, [selectedPermissionGroup]);

	function onSelectPermissionGroup(item: PermissionGroupDisplayed) {
		if (item === null) {
			setSelectedPermissionGroup(undefined);
		} else {
			setSelectedPermissionGroup(item.permissionGroup);
		}
	}

	return(
		<div className={classNames(wrapperClasses)}>
			<ComboBox
				wrapperClasses="py-2"
				title="Berechtingungsgruppe"
				items={permGroupsDisplayed.map(item => ({key: item.name, label: item.name, object: item}))}
				onChangeSelected={(item) => onSelectPermissionGroup(item)}
			/>

			<div className="flex gap-5">
				<div className="text-gray-700 text-sm">Darf verwenden: </div>
				<div className="pb-1 flex gap-5">
					<CheckBox
						label="Verwaltung"
						checked={webUsePermission}
						disabled={true}/>
					<CheckBox
						label="Bestell-App"
						checked={appUsePermission}
						disabled={true}/>
				</div>
			</div>


		</div>
	);
}

export default PermissionsSelection;
