import classNames from 'classnames';
import {Popover, Transition} from '@headlessui/react';
import React, {Fragment, useEffect, useState} from 'react';
import {ChevronDownIcon} from '@heroicons/react/20/solid';
import axios from 'axios';
import InputField, {InputFieldType} from '../../../../components/inputField/InputField';
import Button, {ButtonType} from '../../../../components/button/Button';
import {IconType} from '../../../../components/button/IconButton';
import ImageButton from '../../../../components/button/ImageButton';
import {ENDPOINTS} from '../../../../constants';

interface Image {
	data:string
	contentType:string
}

interface Props {
	onClose?(): void
	title: string
	wrapperClasses?: string;
}

const AddManufacturerFlyout = ({title, onClose, wrapperClasses} : Props) => {

	const imageFileRef = React.useRef<HTMLInputElement>(null);
	const [imageFile, setImageFile] = useState<File | null>(null);
	const [imagePreview, setImagePreview] = useState<Image>({data:'',contentType:''});

	const [inputFieldError, setInputFieldError] = useState<string>('');
	const [manufacturerName, setManufacturerName] = useState<string>('');

	useEffect(() => {
		if (imageFile) {
			const reader = new FileReader();
			reader.onload = () => {

				const arrB64 = (reader.result as string).split(',');
				if (arrB64.length == 2){
					setImagePreview({data:arrB64[1], contentType:imageFile.type});
				}
			};
			reader.readAsDataURL(imageFile);
		}
	}, [imageFile]);


	const onFinishClick = async () =>{
		if (manufacturerName === ''){
			setInputFieldError('Kein Herstellername angegeben');
		} else if (imagePreview.data === '') {
			setInputFieldError('Hersteller-Logo fehlt');
		} else {
			axios.post(ENDPOINTS.manufacturers ,
				{'name':manufacturerName, 'imageData':imagePreview?.data})
				.then((response) => {
					onClose&&onClose();
					setManufacturerName('');
					setImagePreview({data:'',contentType:''});
				})
				.catch(errors => {
					console.log(errors);
				});
		}
	};


	return (
		<div className={classNames(wrapperClasses)}>
			<Popover className="relative">
				{({ open }) => (
					<>
						<Popover.Button
							className={classNames(
								open ? 'text-gray-900' : 'text-gray-500',
								'group inline-flex items-center rounded-md bg-white font-medium hover:text-gray-900')}
						>
							<span>{title}</span>
							<ChevronDownIcon
								className={classNames(open ? 'text-gray-600' : 'text-gray-400', 'pt-1 h-5 w-5 ' +
									'group-hover:text-gray-500')} aria-hidden="true"
							/>
						</Popover.Button>

						<Transition
							as={Fragment}
							enter="transition ease-out duration-200"
							enterFrom="opacity-0 translate-y-1"
							enterTo="opacity-100 translate-y-0"
							leave="transition ease-in duration-150"
							leaveFrom="opacity-100 translate-y-0"
							leaveTo="opacity-0 translate-y-1"
						>
							<Popover.Panel className="absolute left-1/2 z-10 mt-3 w-screen max-w-xs -translate-x-1/2 transform px-2 sm:px-0">
								{({ close }) => (
									<div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
										<div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
											<div className="flex flex-col items-center">

												<input
													type="file"
													onChange={(e)=>setImageFile(e.target.files?e.target.files[0]:null)}
													style={{display:'none'}}
													ref={imageFileRef}
													accept="image/png, image/jpeg"
												/>

												<ImageButton
													onClick={()=>imageFileRef.current?.click()}
													previewData={imagePreview.data}
													previewContentType={imagePreview.contentType}
												/>

												<InputField
													wrapperclasses="py-2 text-black"
													type={InputFieldType.TEXT}
													value={manufacturerName} title="Herstellername"
													onChange={(e)=>setManufacturerName(e.target.value)}
												/>

												<div className="flex flex-row justify-end">
													<Button
														label="Fertig"
														icon={IconType.CHECK}
														onClick={()=>onFinishClick().then(value => close())}
														wrapperClasses="pt-5"
													/>
												</div>

												{inputFieldError != '' &&
													<div className="flex justify-center">
														<div className="text-red-600 py-2">{inputFieldError}</div>
													</div>
												}

											</div>
										</div>
									</div>
								)}
							</Popover.Panel>
						</Transition>
					</>
				)}
			</Popover>
		</div>
	);
};

export default AddManufacturerFlyout;