import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User } from '../interfaces';

type AuthReducer = {
    user: User | null;
    userID: string;
    token: string;
    loggedIn: boolean;
    keepLoggedIn: boolean;
};

const initialState = {
	user: null,
	userID: '',
	token: '',
	loggedIn: false,
	keepLoggedIn: false,
} as AuthReducer;

export const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		loginUser: (state, action) => {
			state.user = action.payload.user;
			state.loggedIn = true;
			state.token = action.payload.token;
			state.userID = action.payload.user.id;
		},
		logoutUser: (state) => {
			state.user = initialState.user;
			state.loggedIn = initialState.loggedIn;
			state.token = initialState.token;
			state.keepLoggedIn = initialState.keepLoggedIn;
		},
		setUserID: (state, action: PayloadAction<string>) => {
			state.userID = action.payload;
		},
		updateUserData: (state, action: PayloadAction<User>) => {
			state.user = {
				...state.user,
				...action.payload
			};
		},
		setKeepLoggedIn: (state, action) => {
			state.keepLoggedIn = action.payload;
		},
		setToken: (state, action) => {
			state.token = action.payload.token;
		}
	}
});

export const { loginUser, logoutUser, setKeepLoggedIn, setToken, updateUserData, setUserID } =
    authSlice.actions;

export default authSlice.reducer;