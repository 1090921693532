import {Filter} from './filter';

export type Category = {
    id: string
    name: string
    amountProducts?: number
    imageId: string
    parentId?: string
    filterIds: string[]
    filters: Filter[]
    status: CategoryStatus
    children: Category[]
};

export enum CategoryStatus {
    CategoryStatusActive = 'CATEGORY_STATUS_ACTIVE',
    CategoryStatusHidden = 'CATEGORY_STATUS_HIDDEN'
}

export type CategoryHierarchy = {
    id: string,
    parentId: string,
    name: string,
    imageId: string,
    status: CategoryStatus,
    children?: CategoryHierarchy[],
    filters: Filter[]
}