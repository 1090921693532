import {Outlet, useNavigate, useParams} from 'react-router-dom';
import React from 'react';
import {ChevronRightIcon} from '@heroicons/react/20/solid';
import {APP_ROUTES} from '../../constants';


export default function Order() {
	const {bundleNumber} = useParams<string>();
	const {orderNumber} = useParams<string>();
	const navigate = useNavigate();

	return (
		<div>
			<div className="flex justify-start pl-8">
				<h1 className="text-2xl self-center cursor-pointer hover:text-gray-500"
					onClick={()=>navigate(APP_ROUTES.orders)}>

					Bestellungsbündel
				</h1>

				<div className="self-center px-1.5 pt-1"><ChevronRightIcon width={30}/></div>

				<h1 className="text-2xl text-gray-500 self-center">{bundleNumber}</h1>

				<div className="self-center px-1.5 pt-1"><ChevronRightIcon width={30}/></div>

				<h1 className="text-2xl self-center">Bestellung</h1>

				<div className="self-center px-1.5 pt-1"><ChevronRightIcon width={30}/></div>

				<h1 className="text-2xl text-gray-500 self-center">{orderNumber}</h1>
			</div>
			<div>
				<Outlet/>
			</div>
		</div>
	);
}