import axios from 'axios';
import {
	AppliedFilter, ChatType, CreateFileInterface,
	FileInterface, NotificationType,
	Property,
	Queries,
	SaleChangeble,
	SaleType,
	VariantChangeble
} from '../interfaces';
import {SupportSessionType} from '../interfaces/supportSession';
import {NotificationStatus} from '../providers/notification/NotificationProvider';


export const API = {
	User: {
		listSubordinates: async function (userId: string) {
			return await axios.get('/user/' + userId + '/subordinates');
		}
	},

	VariantSale:{
		get: async function (variantId: string){
			return await axios.get('variantSales/' + variantId);
		},

		softDelete: async function (variantId: string){
			return await axios.put('variantSales/' + variantId + '/delete');
		},

		softDeleteMany: async function (variantIds: string[]) {
			return await axios.put('variantSales/delete',
				{
					variantIds: variantIds
				}
			);
		},

		list: async function(queries: any){
			return await axios.get('variantSales',{params: queries});
		}
	},

	CategorySale: {
		create: async function (
			categoryId: string,
			saleType: SaleType,
			percentage: number,
			endDate: string | undefined,
			partnerCompanyId: string | undefined
		) {
			return await axios.post('categories/' + categoryId + '/sale',
				{
					saleType: saleType,
					percentage: percentage,
					endDate: endDate,
					partnerCompanyId: partnerCompanyId
				});
		},

		list: async function (queries: any) {
			return await axios.get('categorySales', {params: queries});
		},

		get: async function (id: string) {
			return await axios.get('categorySales/' + id);
		},

		softDelete: async function (id: string) {
			return await axios.put('categorySales/' + id + '/delete');
		},
	},

	Variant: {
		create: async function (
			productId: string,
			properties: Property[],
			manufacturerArticleNumber: string,
			active: boolean,
			shortDescription: string,
			longDescription: string,
			price: number,
			amountAvailable: number,
			sale: SaleChangeble | undefined) {
			return await axios.post('products/' + productId + '/variants',
				{
					properties: properties,
					manufacturerArticleNumber: manufacturerArticleNumber,
					active: active,
					shortDescription: shortDescription,
					longDescription: longDescription,
					price: price,
					amountAvailable: amountAvailable,
					saleChangeable: sale
				});
		},

		update: async function (
			productId: string,
			variantId: string,
			active: boolean | undefined,
			price: number | undefined,
			saleChangeable: SaleChangeble | undefined,
			amountAvailable: number | undefined,
			manufacturerArticleNumber: string | undefined,
			shortDescription: string | undefined,
			longDescription: string | undefined,
			properties: Property[] | undefined,
		) {
			return await axios.put('products/' + productId + '/variants/' + variantId,
				{
					active: active,
					price: price,
					saleChangeable: saleChangeable,
					amountAvailable: amountAvailable,
					manufacturerArticleNumber: manufacturerArticleNumber,
					shortDescription: shortDescription,
					longDescription: longDescription,
					properties: properties,
				});
		},

		delete: async function (productId: string, variantId: string) {
			return await axios.put('products/' + productId + '/variants/' + variantId + '/delete');
		},

		list: async function (appliedFilters: AppliedFilter[], queries: any | undefined) {
			return await axios.post('variants/list', {appliedFilters: appliedFilters}, {params: queries});
		},

		listFromCompany: async function (appliedFilters: AppliedFilter[], queries: any | undefined) {
			return await axios.post('variants/list/fromCompany', {appliedFilters: appliedFilters}, {params: queries});
		},

		createSales: async function (
			variantIds: string[],
			percentage: number,
			endDate: string | undefined,
			partnerCompanyId?: string | undefined
		) {
			return await axios.post('variants/sale',
				{
					variantIds: variantIds,
					percentage: percentage,
					endDate: endDate,
					partnerCompanyId: partnerCompanyId
				});
		},
	},

	Product: {
		createSingle: async function (
			properties: Property[],
			name: string,
			manufacturerArticleNumber: string,
			active: boolean,
			shortDescription: string,
			longDescription: string,
			price: number,
			categoryId: string,
			manufacturerId: string,
			amountAvailable: number,
			files: FileInterface[],
			saleChangeable: SaleChangeble | undefined,
		) {
			return await axios.post('/products', {
				properties: properties,
				name: name,
				manufacturerArticleNumber: manufacturerArticleNumber,
				active: active,
				shortDescription: shortDescription,
				longDescription: longDescription,
				price: price,
				categoryId: categoryId,
				manufacturerId: manufacturerId,
				amountAvailable: amountAvailable,
				files: files,
				variantSaleCreate: saleChangeable,
			});
		},

		createWithVariants: async function (
			name: string,
			shortDescription: string,
			longDescription: string,
			categoryId: string,
			manufacturerId: string,
			files: FileInterface[],
			variationProperty: Property,
			universalProperties: Property[],
			specificProperties: Property[],
			createNewVariants: VariantChangeble[]
		) {
			return await axios.post('products/withVariants', {
				name: name,
				shortDescription: shortDescription,
				longDescription: longDescription,
				categoryId: categoryId,
				manufacturerId: manufacturerId,
				files: files,
				variationProperty: variationProperty,
				universalProperties: universalProperties,
				specificProperties: specificProperties,
				createNewVariants: createNewVariants
			});
		},

		getFromCompany: async function (id: string) {
			return await axios.get('products/' + id, {params: {fromCompany: true}});
		},

		update: async function (
			productId: string,
			name: string | undefined,
			manufacturerArticleNumber: string | undefined,
			categoryId: string | undefined,
			manufacturerId: string | undefined,
			shortDescription: string | undefined,
			longDescription: string | undefined,
			universalProperties: Property[],
			specificProperties: Property[],
			files: FileInterface[],
			imageIds: string[],
			planIds: string[],
			dataSheetIds: string[],
			dxfFileIds: string[],
			stpFileIds: string[],
		) {
			return await axios.put('products/' + productId, {
				name: name,
				manufacturerArticleNumber: manufacturerArticleNumber,
				categoryId: categoryId,
				manufacturerId: manufacturerId,
				shortDescription: shortDescription,
				longDescription: longDescription,
				universalProperties: universalProperties,
				specificProperties: specificProperties,
				files: files,
				imageIds: imageIds,
				planIds: planIds,
				dataSheetIds: dataSheetIds,
				dxfFileIds: dxfFileIds,
				stpFileIds: stpFileIds
			});
		}
	},

	Filter: {
		list: async function (queries: Queries) {
			return await axios.get('filters', {params: queries});
		}
	},

	CompanyPartnership: {
		request: async function (partnerCompanyId: string) {
			return await axios.post('companyPartnerships',
				{partnerCompanyId: partnerCompanyId}
			);
		},

		accept: async function (companyPartnershipId: string) {
			return await axios.put('/companyPartnerships/' + companyPartnershipId + '/accept');
		},

		listAsSeller: async function (queries: any) {
			return await axios.get('/companyPartnerships/seller', {params: queries});
		},

		listAsBuyer: async function (queries: any) {
			return await axios.get('/companyPartnerships/buyer', {params: queries});
		},

		delete: async function (partnershipId: string) {
			return await axios.delete('/companyPartnerships/' + partnershipId);
		}
	},

	Company: {
		listSellerCompanies: async function (queries: any) {
			return await axios.get('companies/seller', {params: queries});
		},

		listUsers: async function (queries: any) {
			return await axios.get('companies/own/users', {params: queries});
		},
	},

	Category: {
		list: async function (queries: any) {
			return await axios.get('categories', {params: queries});
		}
	},

	Chats: {
		uploadImages: async function(chatID:string, data:FormData) {
			const headers = {
				'Content-Type': 'multipart/form-data',
			};
			return await axios.post(`/chats/${chatID}/images`, data, {headers});
		}
	},
};
