import ComboBox from '../../../../components/comboBox/ComboBox';
import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {ENDPOINTS} from '../../../../constants';
import AddManufacturerFlyout from './AddManufacturerFlyout';
import classNames from 'classnames';
import {Manufacturer} from '../../../../interfaces';

interface Props {
  selectedManufacturer		:Manufacturer | undefined;
  setSelectedManufacturer	:React.Dispatch<React.SetStateAction<Manufacturer | undefined>>;
  wrapperClasses?			:string
}


function ManufacturerSelection({selectedManufacturer, setSelectedManufacturer, wrapperClasses} : Props) {
	const [manufacturers, setManufacturers] = useState<Manufacturer[]>([]);

	useEffect(() => {
		loadManufacturers().then();
	}, []);

	const loadManufacturers = async () => {
		axios.get(ENDPOINTS.manufacturers, {
			params: {
				sortPrimary: 'name',
				pageSize: 500,
			},
		})
			.then((response) => {
				setManufacturers(response.data['manufacturers']);
			})
			.catch((errors) => {
				console.log(errors);
			});
	};

	return (
		<div className={classNames(wrapperClasses)}>
			{manufacturers &&
				<ComboBox
					title={'Hersteller'}
					items={manufacturers.map(item => ({key: item.id, label: item.name, object: item}))}
					onChangeSelected={setSelectedManufacturer}
				/>
			}
			<div className="flex gap-3 text-xs text-gray-400">
				<div className="pl-1 py-1 ">Hersteller nicht vorhanden? </div>

				<AddManufacturerFlyout
					wrapperClasses="pt-0.5"
					title="Hier anlegen"
					onClose={()=>loadManufacturers()}
				/>
			</div>
		</div>
	);
}

export default ManufacturerSelection;
