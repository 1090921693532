import React, {useState} from 'react';
import Button from '../../../../components/button/Button';
import {IconType} from '../../../../components/button/IconButton';
import {useNavigate} from 'react-router-dom';
import {APP_ROUTES, routeCreateProduct} from '../../../../constants';
import CategoriesSelection from './components/CategoriesSelection';


export default function SelectCategory() {
	const navigate = useNavigate();

	const [errorMessage, setErrorMessage] = useState<string>('');
	const [selectionValid, setSelectionValid] = useState<boolean>(false);
	const [deepestCategoryId, setDeepestCategoryId] = useState<string>('');

	const onSaveAndExit = () => {
		if (selectionValid) {
			navigate(routeCreateProduct(deepestCategoryId));
		} else {
			setErrorMessage('Bitte Kategorie vollständig auswählen');
		}
	};

	return (
		<div className={'px-8 py-5'}>
			<div className={'flex flex-col'}>
				<div className="flex flex-col w-1/4">
					<CategoriesSelection
						setSelectionValid={setSelectionValid}
						setDeepestCategoryId={setDeepestCategoryId}/>
				</div>

				<div className="flex flex-row pt-16">
					<Button
						label="Abbrechen"
						icon={IconType.WITHOUT}
						onClick={()=>navigate(APP_ROUTES.products)}/>

					<Button
						wrapperClasses="pl-5"
						label="Speichern und beenden"
						icon={IconType.CHECK}
						onClick={()=>onSaveAndExit()}/>
				</div>
				{errorMessage != '' &&
					<div className="flex">
						<div className="text-red-600 py-2">{errorMessage}</div>
					</div>
				}
			</div>
		</div>
	);
}
