import {Outlet} from 'react-router-dom';
import React from 'react';

export default function PartnershipsAsSellerHeader() {
	return (
		<div>
			<div className="flex justify-start pl-8">
				<h1 className="text-2xl font-semibold text-gray-900">Partnerunternehmen denen sie Sonderkonditionen
					vergeben</h1>
			</div>
			<div>
				<Outlet/>
			</div>
		</div>
	);
}