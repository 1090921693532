import React from 'react';

export default function DashboardHeader() {
	return (
		<div>
			<div className="flex justify-start pl-8">
				<h1 className="text-2xl font-semibold text-gray-900">Überblick</h1>
			</div>
			<div className="px-4 sm:px-6 md:px-0">

			</div>
		</div>
	);
}
