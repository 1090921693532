import {Outlet} from 'react-router-dom';
import React from 'react';
import {useTranslation} from 'react-i18next';

export default function SupportSessionListLayout() {
	const {t} = useTranslation();
	return (
		<div>
			<div className="flex justify-start pl-8">
				<h1 className="text-2xl font-semibold text-gray-900">{t('supportSessions.heading')}</h1>
			</div>
			<div className={'px-8 py-3'}>
				<Outlet/>
			</div>
		</div>
	);
}