import React from 'react';

interface Props {
    letter:string
    fromOthers:boolean
}

export const ChatNameIndicatorCircle = (props:Props) => {
	return(
		<div className={`flex-none w-10 h-10 border rounded-full border-gray-600' + ${props.fromOthers ? 'bg-gray-300' : 'bg-blue-300'} `}>
			<div className={'pl-3.5 pt-1.5'}>
				{props.letter}
			</div>
		</div>
	);
};