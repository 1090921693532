import React from 'react';

const VerifyFirstHint = () => {
	return(
		<div className={'flex flex-col justify-center items-center h-full text-2xl'}>
			<div className="sm:mx-auto sm:w-full sm:max-w-md">
				<img
					className="mx-auto h-12 w-auto"
					src="/inopartLogo.jpeg"
					alt="Your Company"
				/>
			</div>
			<p className="py-5">Bitte bestätigen sie nun ihre E-Mail, um sich einloggen zu können.</p>
		</div>
	);
};

export default VerifyFirstHint;