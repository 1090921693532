import classNames from 'classnames';
import CategorySelection from '../categorySelection/CategorySelection';
import React, {useEffect, useImperativeHandle, useState} from 'react';
import {FilterList} from '../../features/filtering/FilterList';
import {AppliedFilter, Filter, Queries, Variant} from '../../interfaces';
import {Category} from '../../interfaces/category';
import {API} from '../../api';
import {useErrorHandling} from '../../providers/error/ErrorProvider';
import SearchBar from '../searchBar/SearchBar';


interface Props {
    variants: Variant[]
    setVariants: React.Dispatch<React.SetStateAction<Variant[]>>
    setLoading: React.Dispatch<React.SetStateAction<boolean>>
    setTotalAmount: React.Dispatch<React.SetStateAction<number>>
    setLoadMorePossible: React.Dispatch<React.SetStateAction<boolean>>
	optionalQueries?: Queries
    wrapperClasses?: string
}


export interface ListVariantFunction {
	listVariants(withNextPageToken: boolean): Promise<void>
}


export const VariantSearchAndFiltering = React.forwardRef((
	{
		variants, setVariants,
		setLoading,
		setTotalAmount,
		setLoadMorePossible,
		optionalQueries,
		wrapperClasses
	}: Props, ref) => {

	useImperativeHandle(ref, () => ({listVariants: listVariants}));

	const errorHandling = useErrorHandling();

	const [selectedCategoryId, setSelectedCategoryId] = useState<string | undefined>(undefined);
	const [appliedFilters, setAppliedFilters] = useState<AppliedFilter[]>([]);
	const [filters, setFilters] = useState<Filter[]>([]);
	const [categories, setCategories] = useState<Category[]>([]);
	const [nextPageToken, setNextPageToken] = useState<string>('');
	const [search, setSearch] = useState<string>('');


	useEffect(() => {
		listVariants(false).then();
	}, [search, selectedCategoryId, JSON.stringify(appliedFilters)]);


	const listVariants = async (withNextPageToken: boolean) => {
		try {
			setLoading(true);

			let queries: Queries = {
				pageSize: 15,
				token: withNextPageToken ? nextPageToken : undefined,
				categoryId: selectedCategoryId,
				freeTextSearch: search !== '' ? search : undefined,
			};

			if (optionalQueries) {
				queries = {...queries, ...optionalQueries};
			}

			const resp = await API.Variant.listFromCompany(
				appliedFilters.length > 0 ? appliedFilters : [],
				queries
			);

			if (nextPageToken && nextPageToken !== '@end') {
				setVariants(variants.concat(resp.data['variants']));
			} else {
				setVariants(resp.data['variants']);
			}

			// valid filters and its corresponding values returned which lead to results
			setFilters(resp.data['filters']);
			setCategories(resp.data['categories']);

			console.log('loadVariants filters appliedFilters', resp.data['filters'], appliedFilters);

			if (resp.data['nextPageToken'] === '@end') {
				setLoadMorePossible(false);
			} else {
				setLoadMorePossible(true);
			}

			setNextPageToken(resp.data['nextPageToken']);
			setTotalAmount(resp.data['totalAmount']);
			setLoading(false);
		} catch (e: any) {
			errorHandling(e);
		}
	};


	return (
		<div className={classNames(wrapperClasses)}>
			<div className={'flex flex-col border-double border-b-4'}>

				<CategorySelection
					wrapperClasses={'pb-5'}
					categories={categories}
					onSelectCategory={(categoryId: string | undefined) => setSelectedCategoryId(categoryId)}/>

				<div className={'flex'}>
					<SearchBar
						wrapperClasses={'w-96 pb-4'}
						searchStr={search}
						setSearchStr={setSearch}/>

					<FilterList
						wrapperClasses={'pl-10 pt-6'}
						filters={filters}
						onChange={setAppliedFilters}/>
				</div>
			</div>
		</div>
	);
});