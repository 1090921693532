import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {endPointFilter} from '../../../../constants';
import classNames from 'classnames';
import AutoCompleteInput from '../../../../components/inputField/AutoCompleteInput';
import {Filter} from '../../../../interfaces';

interface Props {
	filterId: string
	title: string
	value: string
	onChange:(value:string) => void
	wrapperClasses? :string
}


function PropertyField({filterId, title, value, onChange, wrapperClasses} : Props) {
	const [filter, setFilter] = useState<Filter>();

	useEffect(() => {
		loadFilter().then();
	}, []);

	async function loadFilter() {
		axios.get(endPointFilter(filterId))
			.then((response) => {
				setFilter(response.data);
			})
			.catch((errors) => {
				console.log(errors);
			});
	}

	return (
		<div className={classNames(wrapperClasses)}>
			{filter &&
				<div className='flex flex-1'>
					<AutoCompleteInput
						wrapperClasses={'flex-1 w-full'}
						title={title}
						suggestions={filter.filterValues?.propertyValues ? filter.filterValues?.propertyValues.map((elem) => (elem.value)) : []}
						value={String(value)}
						setValue={onChange}/>
				</div>
			}
		</div>
	);
}

export default PropertyField;
