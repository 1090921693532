import classNames from 'classnames';
import {useState} from 'react';
import {Category} from '../../interfaces/category';
import IconButton, {IconType} from '../button/IconButton';
import {endPointGetImage} from '../../constants';

interface Props {
	categories: Category[]
	onSelectCategory: (categoryId: string | undefined) => void
    wrapperClasses?: string
}

export default function CategorySelection(
	{
		categories,
		onSelectCategory,
		wrapperClasses
	}: Props
) {


	const [categoryNavigation, setCategoryNavigation] = useState<Category[]>([]);


	function onClickCategory(category: Category) {
		setCategoryNavigation(categoryNavigation.concat(category));
		onSelectCategory(category.id);
	}

	function onClickNavigationItem(navigationIndex: number) {
		onSelectCategory(categoryNavigation[navigationIndex].id);
		const newArr: Category[] = structuredClone(categoryNavigation);
		newArr.splice(navigationIndex + 1);
		setCategoryNavigation(newArr);
	}

	function onClickDeleteCategory(categoryId: string) {
		const indexToDelete: number = categoryNavigation.findIndex((item) => item.id === categoryId);
		const newArr: Category[] = structuredClone(categoryNavigation);
		newArr.splice(indexToDelete, 1);
		setCategoryNavigation(newArr);

		if (newArr.length > 0) {
			onSelectCategory(newArr[newArr.length - 1].id);
		} else {
			onSelectCategory(undefined);
		}
	}

	function onClickMainCategories() {
		setCategoryNavigation([]);
		onSelectCategory(undefined);
	}

	return (
		<div className={classNames(wrapperClasses)}>
			<div className={'flex flex-col'}>

				<div className={'pt-2 flex gap-2 text-xl'}>
					<div
						className={'cursor-pointer'}
						onClick={() => onClickMainCategories()}
					>
						Hauptkategorien
					</div>

					{categoryNavigation && (categoryNavigation.length > 0) &&
						<div>
							&rarr;
						</div>
					}

					{categoryNavigation && categoryNavigation.map((category, index) => (
						<div className={'flex gap-2'} key={'category-navigation' + category.id}>
							<div
								className={'cursor-pointer'}
								onClick={() => onClickNavigationItem(index)}>
								{category.name}
							</div>

							{index < (categoryNavigation.length - 1) &&
								<div>
									&rarr;
								</div>
							}
						</div>
					))
					}

					{categoryNavigation && (categoryNavigation.length > 0) &&
						<div>
							<IconButton
								icon={IconType.X_MARK}
								focus={false}
								onClick={() => onClickDeleteCategory(categoryNavigation[categoryNavigation.length - 1].id)}/>
						</div>
					}
				</div>


				<div className={'w-full pt-5 grid grid-rows-2 grid-flow-col gap-2'}>
					{categories && categories.map((category) => (
						<div className={'flex  rounded-md'} key={'category-overview' + category.id}>
							<img
								src={endPointGetImage(category.imageId)}
								className="w-12 h-12 mt-2 object-scale-down"
								alt={''}
								id={category.imageId}/>

							<div className={'pl-3 pt-5'}>
								<div
									className={'cursor-pointer'}
									key={category.id}
									onClick={() => onClickCategory(category)}
								>
									{category.name}
								</div>
							</div>
						</div>
					))
					}
				</div>
			</div>
		</div>
	);
}
