import React, {useEffect, useState} from 'react';
import {Modal} from '../../../../components/modal/Modal';
import Button from '../../../../components/button/Button';
import CustomDatePicker from '../../../../components/datePicker/CustomDatePicker';
import {Company, Variant} from '../../../../interfaces';
import CurrencyInputField from '../../../../components/inputField/CurrencyInputField';


interface Props {
	numProducts: number
	saleExistsWarning: boolean
	isVisible: boolean
	onClose: () => void
	onSuccess: (percentage: number, endDate: string | undefined, partnerCompanyId: string | undefined) => void
}

export const CreateCategorySaleModal: React.FC<Props> = (
	{numProducts, saleExistsWarning, isVisible, onClose, onSuccess}: Props) => {

	const [errorMessage, setErrorMessage] = useState<string>('');
	const [percentage, setPercentage] = useState<number>(0);
	const [endDate, setEndDate] = useState<string | undefined>(undefined);

	const [partnerCompanies, setPartnerCompanies] = useState<Company[]>([]);
	const [variants, setVariants] = useState<Variant[]>([]);
	const [partnerCompanyId, setPartnerCompanyId] = useState<string | undefined>(undefined);

	useEffect(() => {
		if (!isVisible) {
			setPercentage(0);
			setEndDate(undefined);
			setErrorMessage('');
		}
	}, [isVisible]);


	const onFinish = () => {
		if (percentage === 0) {
			setErrorMessage('ungültiger Prozentsatz');
		} else if (endDate ? (new Date(endDate) < new Date()) : false) {
			setErrorMessage('Sale Enddatum muss in der Zukunft liegen');
		} else {
			onSuccess(percentage, endDate, partnerCompanyId);
		}
	};

	return (
		<Modal
			isOpen={isVisible}
			handleClose={onClose}
			children={
				<>
					<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>

					<div
						className="relative flex items-end sm:items-center justify-center min-h-full p-5 text-center sm:p-0"
					>

						<div className="relative bg-white rounded-lg  pt-5 pb-4 text-left shadow-xl
                                   transform transition-all sm:my-8 w-1/4 h-max sm:p-10"
						>

							<div className={'relative flex flex-col gap-3'}>
								<CurrencyInputField
									title={'Prozentsatz'}
									percentage={true}
									value={percentage}
									setValue={setPercentage}/>

								<CustomDatePicker
									title={'Enddatum'}
									dateString={endDate ?? ''}
									setDateString={setEndDate}/>

								<div className={'text-red-600'}>
									* Wollen sie diesen Kategorie-Rabatt mit {numProducts} Produkten wirklich
									erstellen?
								</div>

								{saleExistsWarning &&
									<div className={'text-red-600'}>
										* Der Kategorie-Rabatt, welcher am tiefsten in der Kategorie-Hierarchie
										liegt, wird präferiert.
									</div>
								}

								<div className={'flex flex-col items-center'}>
									<div className={'flex justify-center gap-2 mt-5'}>
										<Button
											label={'Abbrechen'}
											onClick={() => onClose()}/>
										<Button
											label={'Erstellen'}
											onClick={() => onFinish()}/>
									</div>
								</div>

								{errorMessage !== '' &&
									<div className="flex justify-center">
										<div className="text-red-600 py-2">{errorMessage}</div>
									</div>
								}
							</div>
						</div>
					</div>
				</>
			}
		/>
	);
};
