import React from 'react';
import {Outlet} from 'react-router-dom';


export default function UserManagementHeader() {
	return (
		<div>
			<div className="flex justify-start pl-8">
				<h1 className="text-2xl font-semibold text-gray-900">Benutzerverwaltung</h1>
			</div>
			<div>
				<Outlet/>
			</div>
		</div>
	);
}