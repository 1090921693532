import {createSlice} from '@reduxjs/toolkit';


type NotificationReducer = {
    notifications: Notification[];
    nextPageToken: string;
}

const initialState: NotificationReducer = {
	notifications: [],
	nextPageToken:''
};

export const notificationSlice = createSlice({
	name: 'notification',
	initialState,
	reducers:{
		setNextPageToken: (state, action) => {
			state.nextPageToken = action.payload;
		},
		appendNotifications: (state, action) => {
			state.notifications = state.notifications.concat(action.payload);
		},
		clearNotifications: (state) => {
			state.nextPageToken = '';
			state.notifications = [];
		}
	}
});

export const {setNextPageToken, appendNotifications, clearNotifications} = notificationSlice.actions;

export default notificationSlice.reducer;