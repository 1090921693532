import DataTable, {TableColumn} from 'react-data-table-component';
import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import axios from 'axios';
import uuid from 'react-uuid';
import IconButton, {IconType} from '../../../components/button/IconButton';
import {
	Constants,
	endPointGetImage,
	endPointProduct,
	routeDetailedVariant,
	routeEditProductWithVariants
} from '../../../constants';
import FormatDate from '../../../utils/date';
import {Product, Variant} from '../../../interfaces';
import Necessity = Constants.Necessity;


export default function WithVariantsOverview() {
	const navigate = useNavigate();
	const {productId} = useParams<string>();

	const [product, setProduct] = useState<Product>();
	const [variants, setVariants] = useState<Variant[]>([]);


	const loadProduct = async (productId:string) => {
		axios.get(endPointProduct(productId), {
			params: {fromCompany: true}
		})
			.then((response) => {
				setProduct(response.data);
				setVariants(response.data.variants);
			})
			.catch(errors => {
				console.log(errors);
			});
	};

	useEffect(() => {
		if (productId) {
			loadProduct(productId).then();
		}
	}, []);


	const colums: TableColumn<Variant>[] = [
		{
			name: 'Produktbild',
			cell: (row, rowIndex, column) => (
				<div>
					{row.imageIds &&
						<img
							src={endPointGetImage(row.imageIds[0])}
							className="w-20 h-20 object-scale-down"
							alt={'image preview'}
							id={'img' + uuid()}
						/>
					}
				</div>
			),
			center: true,
			width: '180px',
		},
		{
			name:'Variante (' + product?.variationProperty?.name + ')',
			cell: (row, rowIndex, column) => (
				<div>
					{row.properties?.find(elem=>elem.necessity === Necessity.Variation)?.value}
				</div>
			),
			center:true,
			width: '240px'
		},
		{
			name:'Artikelnummer',
			selector: row => row.articleNumber,
			center:true,
			width: '180px'
		},
		{
			name:'Herstellernummer',
			selector: row => row.manufacturerArticleNumber,
			center:true,
			width: '180px'
		},
		{
			name:'Status',
			cell: (row, rowIndex, column) =>
				<div className="flex flex-col items-center">
					{row.active?
						<span className="inline-flex rounded-full bg-green-200 px-2 font-semibold leading-5 text-black">
							Aktiv
						</span>:
						<span className="inline-flex rounded-full bg-yellow-300 px-2 font-semibold leading-5 text-black">
							Inaktiv
						</span>
					}
				</div>,
			center:true,
			width: '100px'
		},
		{
			name:'Anzahl Verfügbar',
			selector: row => row.amountAvailable,
			center:true,
			width: '180px'
		},
		{
			name:'Preis',
			cell: (row, rowIndex, column) =>
				<div className="flex pl-12">
					<div className="flex flex-col flex-none w-40 items-start">
						<div>
							Preis: {row.price? ((row.price/100) + ' €').replace('.',',') :''}
						</div>

						<div className="flex flex-col text-red-500">
							<div>
								Reduziert: {row.effectiveSale?.price ? (row.effectiveSale.price / 100 + ' €').replace('.', ',') : '-'}
							</div>
							<div>
								Rabatt: {row.effectiveSale ? (row.effectiveSale.percentage / 100 + ' %').replace('.', ',') : '-'}
							</div>
							<div>
								Enddatum: {row.effectiveSale?.endDate ? FormatDate(row.effectiveSale.endDate) : '-'}
							</div>
						</div>
					</div>
				</div>,
			center:true,
			width:'180px'
		},
	];


	return(
		<div className="flex flex-col px-8 py-5 gap-5">
			{product &&
			<div>
				<div className="flex gap-20 text-xl">

					{product.imageIds && product.imageIds.length > 0 &&
						<img
							src={endPointGetImage(product.imageIds[0])}
							className="w-20 h-20 object-scale-down"
							alt={'image preview'}
							id={'img' + uuid()}/>
					}

					<div className="flex flex-col">
						<div className="flex">
							<div>
									Hersteller:&nbsp; &nbsp;
							</div>
							<div className="text-gray-700">
								{product.manufacturer?.name}
							</div>
						</div>

						<div className="flex">
							<div>
									Produktname:&nbsp; &nbsp;
							</div>
							<div className="text-gray-700">
								{product.name}
							</div>
						</div>
					</div>
				</div>

				<div className={'flex justify-between mt-5'}>
					<h1 className="text-2xl">Produktvarianten</h1>
					<IconButton
						icon={IconType.PENCIL}
						onClick={()=>navigate(routeEditProductWithVariants(productId??''))}/>
				</div>
			</div>
			}


			<form>
				<DataTable
					columns={colums}
					data={variants}
					pointerOnHover={true}
					onRowClicked={(row)=>navigate(
						routeDetailedVariant(
							product?.id??'',
							product?.name??'',
							row.id,
							row.properties.find(item=>item.necessity === Necessity.Variation)?.name??'',
							row.properties.find(item=>item.necessity === Necessity.Variation)?.value??''
						))}
				/>
			</form>
		</div>
	);
}
