import {CompanyPartnership, CompanyPartnershipStatus} from '../../interfaces/companyPartnership';
import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {Constants, ENDPOINTS,} from '../../constants';
import DataTable, {SortOrder, TableColumn} from 'react-data-table-component';
import IconButton, {IconType} from '../../components/button/IconButton';
import Button, {ButtonType} from '../../components/button/Button';
import SearchBar from '../../components/searchBar/SearchBar';
import {useErrorHandling} from '../../providers/error/ErrorProvider';
import {API} from '../../api';
import {toast} from 'sonner';

export default function IncomingPartnershipRequests() {
	const errorHandling = useErrorHandling();
	
	const [nextPageToken, setNextPageToken] = useState<string>('');
	const [sortDirectionName, setSortDirectionName] = useState<string>(Constants.SortDirection.ASC);
	const [searchValue, setSearchValue] = useState<string>('');
	const [searchResults, setSearchResults] = useState<CompanyPartnership[]>([]);
	const [partnerships, setPartnerships] = useState<CompanyPartnership[]>([]);

	useEffect(() => {
		loadIncomingPartnershipRequests(sortDirectionName, '');
	}, []);

	function loadIncomingPartnershipRequests(sortDirection: string, token: string) {
		axios.get(ENDPOINTS.listCompanyPartnershipsAsSeller, {
			params: {
				status: CompanyPartnershipStatus.requested,
				token: token,
				pageSize: 15,
				sortPrimary: 'buyer.name',
				sortDirectionPrimary: sortDirection,
			},
		})
			.then((resp) => {
				const fetchedPartnerships: CompanyPartnership[] = resp.data['companyPartnerships'];
				if (token !== '') {
					setPartnerships(partnerships.concat(fetchedPartnerships));
				} else {
					setPartnerships(structuredClone(fetchedPartnerships));
				}
				setNextPageToken(resp.data['nextPageToken']);
			})
			.catch(errorHandling);
	}

	const onSortChange = async (
		selectedColumn: TableColumn<CompanyPartnership>,
		sortDirection: SortOrder,
		sortedRows: any[]
	) => {
		if (sortDirection === 'asc') {
			setSortDirectionName(Constants.SortDirection.ASC);
			loadIncomingPartnershipRequests(Constants.SortDirection.ASC, '');
		} else if (sortDirection == 'desc') {
			setSortDirectionName(Constants.SortDirection.DESC);
			loadIncomingPartnershipRequests(Constants.SortDirection.DESC, '');
		}
	};

	async function acceptPartnership(partnershipId: string, buyerName: string) {
		try {
			await API.CompanyPartnership.accept(partnershipId);

			const message = 'Anfrage zur Partnerschaft mit dem Unternehmen ' + buyerName + ' akzeptiert.';
			toast.success(message);

			const indexToDelete: number = partnerships.findIndex((elem) => elem.id === partnershipId);
			if (indexToDelete >= 0) {
				const newPartnerships: CompanyPartnership[] = partnerships;
				newPartnerships.splice(indexToDelete, 1);
				setPartnerships(newPartnerships);
			}
		} catch (e: any) {
			errorHandling(e);
		}
	}

	async function deletePartnerShip(partnershipId: string, buyerName: string) {
		try {
			await API.CompanyPartnership.delete(partnershipId);

			const message = 'Anfrage zur Partnerschaft von Unternehmen ' + buyerName + ' geloescht.';
			toast.success(message);

			const indexToDelete: number = partnerships.findIndex((elem) => elem.id === partnershipId);
			if (indexToDelete >= 0) {
				const newPartnerships: CompanyPartnership[] = partnerships;
				newPartnerships.splice(indexToDelete, 1);
				setPartnerships(newPartnerships);
			}
		} catch (e: any) {
			errorHandling(e);
		}
	}

	useEffect(() => {
		if (searchValue !== '') {
			search();
		} else {
			setSearchResults([]);
		}
	}, [searchValue]);

	function search() {
		axios.get(ENDPOINTS.listCompanyPartnershipsAsSeller,
			{
				params: {
					searchBuyerName: searchValue,
					status: CompanyPartnershipStatus.requested,
					pageSize: 10
				}
			})
			.then((resp) => {
				const results: CompanyPartnership[] = resp.data['companyPartnerships'];
				if (results) {
					for (let i = 0; i < results.length; i++) {
						results[i].name = results[i].buyer?.name ?? '';
					}
				}
				setSearchResults(results);
			})
			.catch(errorHandling);
	}

	const colums: TableColumn<CompanyPartnership>[] = [
		{
			name: 'Name',
			selector: (row) => row.buyer?.name ?? '',
			center: true,
			sortable: true,
			width: '250px',
		},
		{
			name: 'Aktion',
			right: true,
			grow: 1,
			cell: (row, rowIndex, column) => (
				<div className="flex items-center gap-2 pr-1">
					<IconButton
						onClick={() => acceptPartnership(row.id, row.buyer?.name ?? '').then()}
						icon={IconType.CHECK}
						title="Partnerschaft akzeptieren"/>
					<IconButton
						onClick={() => deletePartnerShip(row.id, row.buyer?.name ?? '').then()}
						icon={IconType.TRASH}
						title="Partnerschaft loeschen"/>
				</div>
			),
		},
	];

	return (
		<div>
			<SearchBar
				wrapperClasses={'w-1/3 pt-12 px-12'}
				results={searchResults}
				searchStr={searchValue}
				setSearchStr={setSearchValue}
				onClickResult={(partnershipId: string, name: string) => acceptPartnership(partnershipId, name)}/>

			<div className={'w-1/3 px-12 flex flex-col'}>
				<DataTable
					columns={colums}
					data={partnerships}
					defaultSortFieldId={1}
					defaultSortAsc={true}
					sortServer
					onSort={onSortChange}/>

				{nextPageToken !== '@end' && (
					<Button
						wrapperClasses="py-5 self-center"
						label="load more"
						onClick={() =>
							loadIncomingPartnershipRequests(sortDirectionName, nextPageToken)
						}
					/>
				)}
			</div>
		</div>
	);
}
