import {User} from './user';
import {ChatInterface} from './chat';
import {Order, OrderBundle} from './orderBundle';
import {Product} from './product';
import {Variant} from './variant';


export type SupportSession = {
    id:string
    requesterID:string
    requester: User | undefined
    supporterIDs: string[]
    supporters: User[] | undefined
    invitedSupporterIDs: string[]
    invitedSupporters: User[] | undefined
    chatID:string
    chat: ChatInterface | undefined
    orderComplaint: OrderComplaint | undefined
    productQuestion: ProductQuestion | undefined
    variantQuestion: VariantQuestion | undefined
    supportSessionType: SupportSessionType
    data: any
    concernSummary:string
    created:string
    closed:string | null
}



export enum SupportSessionType {
    ProductQuestion = 'PRODUCT_QUESTION',
    VariantQuestion = 'VARIANT_QUESTION',
    OrderComplaint = 'ORDER_COMPLAINT',
    GeneralQuestion = 'GENERAL_QUESTION'
}

export interface OrderComplaint {
    order:Order
    concerningVariantIDs:string[]
}

export interface ProductQuestion {
    productID: string
    product: Product
}

export interface VariantQuestion {
    productID: string
    variantID:string
    variant:Variant
}