import React, {useEffect, useState} from 'react';
import DataTable, {ExpanderComponentProps, TableColumn} from 'react-data-table-component';
import axios from 'axios';
import {ENDPOINTS} from '../../constants';
import uuid from 'react-uuid';
import {useNavigate} from 'react-router-dom';
import PriceToComma from '../../utils/priceFormatting';
import FormatDate from '../../utils/date';
import {routeOrderOverview} from '../../constants';
import {Order, OrderBundle} from '../../interfaces/orderBundle';


const customStyles = {
	rows: {
		style: {
			background: '#F5F5F5',
			minHeight: '100px',
		},
	},
	headCells: {
		style: {
			background: '#F5F5F5',
		},
	},
	cells: {
		style: {
			background: '#F5F5F5',
		},
	},
};

const colums: TableColumn<Order>[] = [
	{
		name: 'Bestellungsnummer',
		selector: row => row.orderNumber,
		center:true,
		width: '150px'
	},
	{
		name: 'Verkäufer',
		selector: row => row.vendorName,
		center:true,
		width: '150px'
	},
	{
		name: 'Anzahl Positionen',
		selector: row => row.positions.length,
		center:true,
		width: '150px'
	},
	{
		name: 'Gesamtpreis',
		cell:(row, rowIndex, column) =>
			<div>
				<div>Netto: &nbsp;{PriceToComma(row.priceNet / 100)} €</div>
				<div>Brutto: {PriceToComma(row.priceGross / 100)} €</div>
			</div>,
		center:true,
		width: '150px'
	},
	{
		name:'Lieferprozess',
		width: '300px',
		cell:(row, rowIndex, column) =>
			<div className="flex flex-col py-2 gap-1 justify-center ">
				<div>Status: {row.delivery.status}</div>
				<div>Kürzel: {row.delivery.identificationCode}</div>
				<div>Versanddatum: {FormatDate(row.delivery.departureDate)}</div>
				<div>Lieferdatum: {FormatDate(row.delivery.scheduledDate)}</div>
				<a href={row.delivery.trackingLink}>Tracking-Link</a>
			</div>
	},
	{
		name: ''
	},
];

const ExpandedOrdersRow: React.FC<ExpanderComponentProps<OrderBundle>> = ({ data }) => {
	const navigate = useNavigate();
	const [orders, setOrders] = useState<Order[]>([]);


	const loadOrdersFromOrderBundle = async (bundleId:string) => {
		axios.get(ENDPOINTS.listOrders,
			{
				params:{'bundleId':bundleId}
			})
			.then((response) => {
				setOrders(response.data['orders']);
			})
			.catch(errors => {
				console.log(errors);
			});
	};

	useEffect(() => {
		loadOrdersFromOrderBundle(data.id).then();
	}, []);

	return (
		<div className="pl-12">
			<div>
				<DataTable
					columns={colums}
					data={orders}
					keyField={uuid()}
					customStyles={customStyles}
					onRowClicked={
						(row)=>navigate(routeOrderOverview(data.bundleNumber.toString(), data.id, row.orderNumber, row.id))
					}
					highlightOnHover={true}
					pointerOnHover={true}/>
			</div>
		</div>
	);
};

export default ExpandedOrdersRow;
