import React, {useState} from 'react';
import classNames from 'classnames';

interface Props {
	title: string;
	value: number;
	setValue:  ((val:number) => void)
	wrapperClasses?: string;

}

export const IntegerInputField = ({title, setValue, value, wrapperClasses}: Props) => {
	const [inputStr, setInputStr] = useState<string|undefined>(()=>{
		if (value === 0.0){
			return '';
		} else{
			return value.toString();
		}
	});


	const onChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
		const newInputStr = event.target.value;
		console.log('val', event.target.value);

		if (newInputStr.match(/^[0-9]*$/)) {
			setInputStr(newInputStr);
			setValue(parseInt(newInputStr));
			return;
		} else if(newInputStr === ''){
			setInputStr('');
			setValue(0);
			return;
		}
	};

	return (
		<div className={
			classNames('flex flex-col', wrapperClasses)
		}>
			<label className="block text-sm font-medium text-gray-700"> {title}</label>
			<input type="text"
				className={'block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 ' +
					'placeholder-gray-400 shadow-sm  sm:text-sm focus:outline-none focus:border-blue-500'}
				onChange={onChangeInput} min="0" value={inputStr} placeholder="0"/>
		</div>
	);
};

export default IntegerInputField;
