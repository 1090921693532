import React, {useEffect, useState} from 'react';
import classNames from 'classnames';
import {Constants} from '../../../../../constants';
import CheckBox from '../../../../../components/checkBox/CheckBox';
import NumberInputField from '../../../../../components/inputField/NumberInputField';
import PropertyField from '../../components/PropertyField';
import {Filter, Property} from '../../../../../interfaces';
import {API} from '../../../../../api/API';
import {useErrorHandling} from '../../../../../providers/error/ErrorProvider';
import Necessity = Constants.Necessity;


interface Props {
    categoryId: string;

    properties: Property[];
    setProperties: React.Dispatch<React.SetStateAction<Property[]>>;

	wrapperClasses?	: string
}

export default function PropertiesTab(
	{
		categoryId,
		properties, setProperties,
		wrapperClasses
	} : Props) {

	const errorHandling = useErrorHandling();
	const [filters, setFilters] = useState<Filter[]>([]);


	useEffect(() => {
		void loadFilters(categoryId ?? '');
	}, []);


	const loadFilters = async (categoryId:string) => {
		try {
			const resp = await API.Filter.list(
				{
					categoryId: categoryId,
					withoutManufacturerFilter: true,
					withoutSellerFilter: true,
				},
			);
			let newFilters: Filter[] = resp.data['filters'];
			newFilters = newFilters.filter(elem => elem.name !== 'brand' && elem.name !== 'Brand');
			setFilters(newFilters);
		} catch (e: any) {
			errorHandling(e);
		}
	};


	const onCheckProperty = (checked:boolean, filter:Filter) => {
		const newArr:Property[] = structuredClone(properties);

		if (checked) {
			const prop:Property = {filterId:filter.id, name:filter.name, value:'', unit:filter.unit, necessity:Necessity.Specific};
			newArr.push(prop);
			setProperties(newArr);
		} else {
			const indexToDelete = newArr.findIndex(elem=>elem.filterId === filter.id);
			if (indexToDelete >= 0) {
				newArr.splice(indexToDelete, 1);
				setProperties(newArr);
			}
		}
	};

	const onChangePropertiesInput = (value:string|number, filterId:string) => {
		const newArr:Property[] = structuredClone(properties);
		const indexToEdit:number = newArr.findIndex(elem=>elem.filterId === filterId);
		newArr[indexToEdit].value = value;
		setProperties(newArr);
	};

	return(
		<div className={classNames(wrapperClasses)}>
			<div className={'flex w-full h-full gap-16'}>
				<div>
					{filters && (filters.length > 0) && filters.map((filter, index) => {
						return <CheckBox
							label={filter.name}
							checked={(properties.findIndex(elem=>elem.filterId === filter.id) !== -1)}
							onChange={(e)=>onCheckProperty(e.target.checked, filter)}
						/>;
					})}
				</div>

				<div className={'h-full w-full flex-1 flex flex-col gap-2'}>
					{properties && properties.map((property) =>{
						if (property.unit) {
							return <NumberInputField
								value={Number(property.value)}
								setValue={(val)=>onChangePropertiesInput(val, property.filterId)}
								title={property.name  + (property.unit? ' (' + property.unit + ')':'')}/>;
						} else {
							return <PropertyField
								wrapperClasses={'w-full'}
								title={property.name}
								filterId={property.filterId}
								value={String(property.value)}
								onChange={(value:string)=>onChangePropertiesInput(value, property.filterId)}/>;
						}
					})}
				</div>
			</div>
		</div>
	);
}