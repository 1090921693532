import classNames from 'classnames';
import React, {useEffect, useState} from 'react';
import {Constants} from '../../../../../../constants';
import CheckBox from '../../../../../../components/checkBox/CheckBox';
import Button from '../../../../../../components/button/Button';
import NumberInputField from '../../../../../../components/inputField/NumberInputField';
import {propertyInputRegEx} from '../../../../../../utils';
import PropertyField from '../../../components/PropertyField';
import {Filter, Property} from '../../../../../../interfaces';
import {useErrorHandling} from '../../../../../../providers/error/ErrorProvider';
import {API} from '../../../../../../api';
import Necessity = Constants.Necessity;
import SortASC = Constants.SortDirection.ASC;


interface Props {
	properties: Property[]
	categoryId:string
	onClose: () => void
	onSuccess: (properties: Property[]) => void
    wrapperClasses?:string
}


export default function EditPropertiesTable(
	{
		onClose,
		onSuccess,
		properties,
		categoryId,
		wrapperClasses
	}:Props) {

	const errorHandling = useErrorHandling();

	const [errorMessage, setErrorMessage] = useState<string>('');
	const [changedProperties, setChangedProperties] = useState<Property[]>(properties);
	const [filters, setFilters] = useState<Filter[]>([]);


	useEffect(() => {
		loadFilters().then();
	}, []);


	const loadFilters = async () => {
		try {
			const resp = await API.Filter.list(
				{
					categoryId: categoryId,
					withoutBrandFilter: true,
					withoutSellerFilter: true,
					withoutManufacturerFilter: true,
					sortPrimary: 'name',
					sortDirectionPrimary: SortASC
				}
			);

			setFilters(resp.data['filters']);

		} catch (e: any) {
			errorHandling(e);
		}
	};


	function onCheckFilter(filter:Filter, checked:boolean, necessity:string) {
		const newProperties: Property[] = structuredClone(changedProperties);

		// only one variation property can exist
		if (necessity === Necessity.Variation) {

			if (checked) {
				const idxToDelete: number = newProperties.findIndex((elem) => elem.necessity === Necessity.Variation);

				if (idxToDelete >= 0) {
					newProperties.splice(idxToDelete, 1);
				}

				newProperties.push({
					filterId: filter.id,
					name: filter.name,
					value: '',
					unit: filter.unit,
					necessity: necessity
				});

			} else {
				// search index and splice safer, then direct array index access
				const indexToDelete: number = newProperties.findIndex((elem) => elem.necessity === Necessity.Variation);

				if (indexToDelete >= 0) {
					newProperties.splice(indexToDelete, 1);
				}
			}
		} else {

			// universal and specific properties can exist multiple times
			if(checked) {
				const idxToDelete: number = newProperties.findIndex((elem) => elem.filterId === filter.id);

				if (idxToDelete >= 0) {
					newProperties.splice(idxToDelete, 1);
				}

				newProperties.push({filterId:filter.id, name:filter.name, value:'', unit:filter.unit, necessity:necessity});

			} else {
				const idxToDelete: number = newProperties.findIndex((elem) => elem.filterId === filter.id);

				if (idxToDelete >= 0) {
					newProperties.splice(idxToDelete, 1);
				}
			}
		}

		newProperties.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
		setChangedProperties(newProperties);
	}


	function onChangeFilterValue(filterId: string, value: number | string) {
		const newProperties: Property[] = structuredClone(changedProperties);

		const index:number = newProperties.findIndex((elem)=>elem.filterId === filterId);
		newProperties[index].value = value;
		setChangedProperties(newProperties);
	}


	function onClickFinish() {

		const variantProp: Property | undefined = changedProperties.find(elem => elem.necessity === Necessity.Variation);
		if (variantProp === undefined) {
			setErrorMessage('Bitte Varianten-Eigenschaft auswählen');
			return;
		}

		const universalProps: Property[] = changedProperties.filter((elem) => elem.necessity === Necessity.Universal);

		if (universalProps) {
			for (let i = 0; i < universalProps.length; i++) {
				if (!propertyInputRegEx.test(String(universalProps[i].value))) {
					setErrorMessage('Bitte alle Werte für die universellen Eigenschaften eingeben');
					return;
				}
			}
		}

		onSuccess(changedProperties);
	}


	return(
		<div className={classNames(wrapperClasses)}>

			<div className='flex gap-10 w-full'>

				<div className='flex flex-col flex-1'>

					<div className='pb-5 text-2xl'>Varianten Eigenschaft</div>

					{filters && filters.map((filter) => {
						if (changedProperties.find(elem => elem.filterId === filter.id && elem.necessity === Necessity.Variation) !== undefined
							|| changedProperties.find(elem => elem.filterId === filter.id) === undefined) {
							return <CheckBox
								id={filter.id + 'variant-properties'}
								label={filter.name}
								checked={changedProperties.find((elem) => elem.filterId === filter.id) !== undefined}
								onChange={(e) => onCheckFilter(filter, e.target.checked, Necessity.Variation)}
							/>;
						}
					})}
				</div>

				<div className='flex flex-col flex-1'>

					<div className='pb-5 text-2xl'>Universelle Eigenschaften</div>

					{filters && filters.map((filter) => {
						if (changedProperties.find(elem => elem.filterId === filter.id && elem.necessity === Necessity.Universal) !== undefined
							|| changedProperties.find(elem => elem.filterId === filter.id) === undefined) {

							return <CheckBox
								id={filter.id + 'universal properties'}
								label={filter.name}
								checked={changedProperties.find(elem => elem.filterId === filter.id) !== undefined}
								onChange={(e) => onCheckFilter(filter, e.target.checked, Necessity.Universal)}/>;
						}
					})}


					{changedProperties && changedProperties.map((elem) => {
						if (elem.necessity === Necessity.Universal && elem.unit) {

							return <NumberInputField
								wrapperClasses={'pt-3 w-1/2'}
								value={Number(elem.value)}
								setValue={(val) => onChangeFilterValue(elem.filterId, val)}
								title={elem.name + (elem.unit ? ' (' + elem.unit + ')' : '')}/>;

						} else if (elem.necessity === Necessity.Universal) {

							return <PropertyField
								wrapperClasses={'pt-3 w-1/2'}
								title={elem.name}
								filterId={elem.filterId}
								value={String(elem.value)}
								onChange={(value: string) => onChangeFilterValue(elem.filterId, value)}/>;
						}
					})}
				</div>


				<div className='flex flex-col flex-1'>
					<div className='pb-5 text-2xl'>Spezifische Eigenschaften</div>

					{filters && filters.map((filter) => {
						if (changedProperties.find(elem => elem.filterId === filter.id && elem.necessity === Necessity.Specific) !== undefined
							|| changedProperties.find(elem => elem.filterId === filter.id) === undefined) {

							return <CheckBox
								id={filter.id + 'specific-properties'}
								label={filter.name}
								checked={changedProperties.find((elem) => elem.filterId === filter.id) !== undefined}
								onChange={(e) => onCheckFilter(filter, e.target.checked, Necessity.Specific)}/>;
						}
					})}
				</div>
			</div>

			<div className={'flex gap-5 mt-8 justify-end'}>
				<div className={'flex gap-5'}>
					<Button
						label={'Abbrechen'}
						onClick={() => onClose()}/>
					<Button
						label={'Fertig'}
						onClick={() => onClickFinish()}/>
				</div>
			</div>

			{errorMessage != '' &&
				<div className="flex justify-center">
					<div className="text-red-600 py-2">{errorMessage}</div>
				</div>
			}
		</div>
	);
}