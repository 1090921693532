import React, {Fragment, useEffect, useState} from 'react';
import {Popover, Transition} from '@headlessui/react';
import {ChevronDownIcon, XMarkIcon} from '@heroicons/react/20/solid';

interface Props {
	propertyFilterItem: PropertyFilterItem
	onChangePropertyFilterItem: (item: PropertyFilterItem) => void
}

export type PropertyFilterItem = {
	id: string
	name: string
	possibleValues: string[]
	selectedValues: string[]
	relevance: number
}

export const PropertyFilter: React.FC<Props> = (
	{
		propertyFilterItem,
		onChangePropertyFilterItem
	}: Props) => {


	const [selectedValues, setSelectedValues] = useState<string[]>([]);


	useEffect(() => {
		setSelectedValues(propertyFilterItem.selectedValues);
	}, [JSON.stringify(propertyFilterItem)]);


	const onToggleValue = (value: string) => {
		const newArr: string[] = structuredClone(selectedValues);

		const index = selectedValues.findIndex((item) => item === value);

		if (index >= 0) {
			newArr.splice(index, 1);
			setSelectedValues(newArr);
		} else {
			setSelectedValues(newArr.concat(value));
		}
	};


	function onClickDeleteValue(value: string) {
		const newSelectedValues: string[] = structuredClone(selectedValues);

		const index = selectedValues.findIndex((item) => item === value);
		if (index >= 0) {
			newSelectedValues.splice(index, 1);
			onChangePropertyFilterItem({...propertyFilterItem, selectedValues: newSelectedValues});
		} else {
			throw new Error('onClickDeleteValue without finding value in selectedValues array should not be possible');
		}
	}

	return(
		<Popover
			as="div"
			key={'propertyFilter-popover-' + propertyFilterItem.name}
			id={'propertyFilter-popover-' + propertyFilterItem.id}
			className="relative inline-block text-left"
		>

			<div className={'flex'}>
				<Popover.Button
					className="group inline-flex items-center justify-center text-sm font-medium text-gray-700 hover:text-gray-900">

					<span>
						{propertyFilterItem.name}
					</span>

					<ChevronDownIcon
						className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 hover:text-gray-500"
						aria-hidden="true"/>
				</Popover.Button>

				<div className={'flex'}>
					{propertyFilterItem.selectedValues && propertyFilterItem.selectedValues.map((selectedValue) => (
						<div
							className={'flex ml-1.5 cursor-pointer rounded bg-gray-200 hover:bg-red-500 px-1.5 py-0.5 text-xs font-semibold tabular-nums text-gray-700'}
							onClick={() => onClickDeleteValue(selectedValue)}
						>
							{selectedValue}

							<XMarkIcon
								className="h-4 w-4 text-black"
								aria-hidden="true"/>
						</div>
					))
					}
				</div>
			</div>


			<Transition
				as={Fragment}
				enter="transition ease-out duration-100"
				enterFrom="transform opacity-0 scale-95"
				enterTo="transform opacity-100 scale-100"
				leave="transition ease-in duration-75"
				leaveFrom="transform opacity-100 scale-100"
				leaveTo="transform opacity-0 scale-95"
			>
				<Popover.Panel
					className="absolute right-0 z-10 mt-2 origin-top-right rounded-md bg-white p-4 shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
					{propertyFilterItem.possibleValues.map((possibleValue) => (

						<div key={possibleValue} className="flex items-center">
							<input
								id={`filter-${propertyFilterItem.id}-${possibleValue}`}
								name={`${propertyFilterItem.id}[]`}
								defaultValue={propertyFilterItem.selectedValues.find((v) => v === possibleValue) ?? possibleValue}
								type="checkbox"
								checked={selectedValues.some(v => v === possibleValue)}
								className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
								onChange={() => onToggleValue(possibleValue)}
							/>

							<label
								htmlFor={`filter-${propertyFilterItem.id}-${possibleValue}`}
								className="ml-3 whitespace-nowrap pr-6 text-sm font-medium text-gray-900"
							>
								{possibleValue}
							</label>
						</div>
					))}

					<div className={'flex justify-center pt-4'}>
						<Popover.Button
							className={'border rounded-md border-transparent bg-blue-600 px-4 py-2 text-sm text-white font-medium hover:bg-blue-500'}
							onClick={() =>
								onChangePropertyFilterItem(
									{
										...propertyFilterItem,
										selectedValues: selectedValues
									}
								)
							}
						>
							Anwenden
						</Popover.Button>
					</div>
				</Popover.Panel>
			</Transition>
		</Popover>
	);
};

