import React, {useEffect, useState} from 'react';
import classNames from 'classnames';


interface Props {
    tabIndex:number
    setTabIndex: React.Dispatch<React.SetStateAction<number>>
    hasVariants:boolean
    wrapperClasses?: string
}

const tabsWithoutVariants = [
	{ name: 'Allgemeine Informationen',  index:0 },
	{ name: 'Eigenschaften'	, index:1 },
	{ name: 'Beschreibung'	, index:2 },
	{ name: 'Bilder'		, index:3 },
	{ name: 'Dateien'		, index:4 }];

const tabsWithVariants = [
	{ name: 'Allgemeine Informationen',  index:0 },
	{ name: 'Varianten'		, index:1 },
	{ name: 'Beschreibung'	, index:2 },
	{ name: 'Bilder'		, index:3 },
	{ name: 'Dateien'		, index:4 },
];

export default function ProductManagementTabBar({tabIndex, setTabIndex, hasVariants, wrapperClasses}: Props) {

	const [tabs, setTabs] = useState(hasVariants?tabsWithVariants:tabsWithoutVariants);

	useEffect(() => {
		setTabs(hasVariants?tabsWithVariants:tabsWithoutVariants);
	}, [hasVariants]);

	return(
		<div className={classNames(wrapperClasses)}>
			{tabs.map((tab)=>
				<div
					className={classNames(tabIndex === tab.index  ?
						'border-indigo-500 text-indigo-600 cursor-pointer' :
						'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
					'whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium cursor-pointer'
					)}
					onClick={()=>setTabIndex(tab.index)}
				>
					{tab.name}
				</div>
			)
			}
		</div>
	);
}