import IconButton, {IconType} from '../button/IconButton';
import Button, {ButtonType} from '../button/Button';
import {Modal} from './Modal';
import React, {useEffect, useState} from 'react';
import {User} from '../../interfaces';
import {useErrorHandling} from '../../providers/error/ErrorProvider';
import {API} from '../../api';
import SearchBarV2, {SearchBarData} from '../searchBar/SearchBarV2';
import {useTranslation} from 'react-i18next';

interface Props {
    isVisible: boolean
    heading:string
    text: string
    buttonTitle?:string
	userMailsToExclude?:string[]
    onClose: () => void
    onSuccess: (id:string, mail:string) => void
}

export const UserMailInvitationModal = (props:Props) => {

	const errorHandling = useErrorHandling();
	const {t} = useTranslation();

	const [usersSearchResults, setUsersSearchResults] = useState<User[]>([]);
	const [selectedUser, setSelectedUser] = useState<SearchBarData | undefined>();

	const onChangeSearchStr = async (searchStr:string) => {
		try {
			const resp = await API.Company.listUsers({
				searchMail: searchStr
			});
			const users:User[] = resp.data['users'];

			if(props.userMailsToExclude){
				const filteredUsers: User[] = users.filter(item =>
					props.userMailsToExclude?.findIndex(mail => mail === item.mail) === -1);

				setUsersSearchResults(filteredUsers);
			} else {
				setUsersSearchResults(users);
			}

		} catch (e:any) {
			errorHandling(e);
		}
	};

	return(
		<Modal isOpen={props.isVisible} handleClose={props.onClose}>
			<div className="relative z-10">
				<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
				<div className="fixed z-10 inset-0 overflow-y-auto">
					<div className="flex items-end sm:items-center justify-center min-h-full p-5 text-center sm:p-0">

						<div className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl
						transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6">

							<div className="flex flex-col gap-5">
								<div className={'self-end'}>
									<IconButton
										onClick={() => props.onClose()}
										icon={IconType.X_MARK}/>
								</div>

								<div>{props.text}</div>

								<SearchBarV2
									onChangeSearchStr={onChangeSearchStr}
									onChangeSelectedItem={(item:SearchBarData|undefined) => setSelectedUser(item)}
									selectedItem={selectedUser}
									items={usersSearchResults.map((item) => ({id:item.id, displayed:item.mail}))}
									inputFieldPlaceholder={t('supportSessions.sessionView.inviteSupporterModal.InputFieldPlaceholder')}
								/>

								<div className={'flex flex-col items-center'}>
									<div className={'flex justify-center gap-2'}>
										<Button
											buttonType={ButtonType.Secondary}
											label={'Abbrechen'}
											onClick={() => props.onClose()}/>
										<Button
											label={'Einladen'}
											disabled={selectedUser === undefined}
											onClick={() => props.onSuccess(selectedUser?.id ?? '', selectedUser?.displayed ?? '')}/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Modal>
	);
};