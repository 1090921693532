import {Outlet} from 'react-router-dom';
import React from 'react';


export default function ManageVariantSpecialConditionHeader() {
	return (
		<div>
			<div className="flex justify-start pl-8">
				<h1 className="text-2xl text-gray-900">Sonderkondition auf Produkte löschen</h1>
			</div>
			<div>
				<Outlet/>
			</div>
		</div>
	);
}
