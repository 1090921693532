import React, {ChangeEvent, FormEvent, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import InputField, {InputFieldType} from '../../components/inputField/InputField';
import {mailRegEx} from '../../utils/regex';
import {useAuth} from '../../providers/auth/useAuth';
import {APP_ROUTES} from '../../constants';
import PasswordInputField from '../../components/inputField/PasswordInputField';
import {useErrorHandling} from '../../providers/error/ErrorProvider';

export default function Login() {
	const navigate = useNavigate();
	const auth = useAuth();
	const errorHandling = useErrorHandling();

	const [mail, setMail] = useState<string>('');
	const [password, setPassword] = useState<string>('');
	const [loginFailed, setLoginFailed] = useState<boolean>(false);
	const [inputFieldError, setInputFieldError] = useState<string>('');

	useEffect(() => {
		if (auth.loggedIn) {
			navigate('/');
		}
	});


	const onChangeMail = (event: ChangeEvent<HTMLInputElement>) => {
		setMail(event.target.value);
	};
	const onChangePassword = (event: ChangeEvent<HTMLInputElement>) => {
		setPassword(event.target.value);
	};
	const onClickPasswordForgot = (event: React.MouseEvent<HTMLButtonElement>) =>{
		event.preventDefault();
		navigate(APP_ROUTES.passwordReset);
	};
	const onClickRegister = (event: React.MouseEvent<HTMLButtonElement>) =>{
		event.preventDefault();
		navigate(APP_ROUTES.register);
	};

	const onLoginButtonClick = async (event: FormEvent<HTMLFormElement>) =>{
		event.preventDefault();

		setInputFieldError('');
		setLoginFailed(false);

		if (!mailRegEx.test(mail)) {
			setInputFieldError('Ungültige E-Mail');
		} else if (password == '') {
			setInputFieldError('Passwort fehlt');
		} else {
			try {
				const ok = await auth.login({
					mail: mail,
					password: password,
					keepLoggedIn: true
				});
				navigate('/');
			} catch (e:any){
				errorHandling(e);
			}
		}
		return false;
	};


	return (
		<div>
			<div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
				<div className="sm:mx-auto sm:w-full sm:max-w-md">
					<img
						className="mx-auto h-12 w-auto"
						src="/inopartLogo.jpeg"
						alt="Your Company"
					/>
					<h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">Login</h2>
				</div>

				<div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
					<div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
						<form className="space-y-6" onSubmit={(event) => onLoginButtonClick(event)}>

							<InputField title={'E-Mail-Adresse'} placeholder={''} onChange={onChangeMail} value={mail} type={InputFieldType.TEXT}/>

							<PasswordInputField
								title={'Passwort'}
								onChange={onChangePassword}
								value={password}
								type={'password'}/>
							
							<div className="flex items-center justify-between">
								<div className="text-sm">
									<button type="button" className="font-medium text-blue-800 hover:text-indigo-500" onClick={(event)=> onClickPasswordForgot(event)}>
										Passwort vergessen?
									</button>
								</div>
							</div>

							<div>
								<button
									type={'submit'}
									className="flex w-full justify-center rounded-md border border-transparent bg-blue-800 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
								>
									Einloggen
								</button>
								{inputFieldError != '' &&
									<div className="flex justify-center">
										<div className="text-red-600 py-2">{inputFieldError}</div>
									</div>}
							</div>
						</form>

						<div className="flex justify-center py-4">
							<button type="button" className="hover:text-indigo-500" onClick={(event) => onClickRegister(event)}>Registrieren</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}