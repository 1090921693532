import {useErrorHandling} from '../../../providers/error/ErrorProvider';
import React, {useEffect, useState} from 'react';
import {API} from '../../../api';
import {APP_ROUTES, Constants} from '../../../constants';
import DataTable, {ConditionalStyles, TableColumn} from 'react-data-table-component';
import {useNavigate} from 'react-router-dom';
import {useAuth} from '../../../providers/auth/useAuth';
import {SupportSession} from '../../../interfaces/supportSession';
import IconButton, {IconType} from '../../../components/button/IconButton';
import {FormatDateWithTime} from '../../../utils';
import Button, {ButtonType} from '../../../components/button/Button';
import {useTranslation} from 'react-i18next';
import {ExclamationCircleIcon} from '@heroicons/react/24/outline';
import {
	useListSupportSessionsQuery,
	useParticipateSupportSessionMutation,
	useStopParticipateSupportSessionMutation
} from '../../../api/ReduxAPI';
import {usePagination} from '../../../providers/usePagination';

export default function SupportSessionList(){

	const navigate = useNavigate();
	const errorHandling = useErrorHandling();
	const auth = useAuth();
	const {t} = useTranslation();
	const pagination = usePagination();
	
	const [sortDirection, setSortDirection] = useState<string>(Constants.SortDirection.DESC);
	const [sortField, setSortField] = useState<string>('created');

	const {data:{supportSessions = [], nextPageToken = ''} = {} }  = useListSupportSessionsQuery({
		pageSize:20,
		token: pagination.nextPageToken,
		sortPrimary:sortField,
		sortDirectionPrimary:sortDirection === 'desc' ? 'DESC' : 'ASC'
	});
	const [participate] = useParticipateSupportSessionMutation();
	const [stopParticipate] = useStopParticipateSupportSessionMutation();


	useEffect(() => {
		pagination.newToken(nextPageToken);
	}, [nextPageToken]);


	async function onClickParticipateSession(supportSessionID:string){
		try {
			participate({supportSessionID});
		} catch (e:any){
			errorHandling(e);
		}
	}

	async function onClickStopParticipateSession(supportSessionID:string){
		try {
			stopParticipate({supportSessionID});
		} catch (e:any){
			errorHandling(e);
		}
	}


	const onSortChange = (field:string, direction:string) => {
		setSortField(field);
		setSortDirection(direction);
	};

	const colums: TableColumn<SupportSession>[] = [
		{
			name: t('supportSessions.table.sessionID'),
			cell: (row) => <div>{row.id}</div> ,
			sortable: true,
			sortField:'_id',
			width: '250px',
		},
		{
			name: t('supportSessions.table.sessionType'),
			cell: (row) => <div>{t(`supportSessions.sessionType.${row.supportSessionType}`)}</div> ,
			sortable: true,
			sortField:'supportSessionType',
			width: '200px',
		},
		{
			name: t('supportSessions.table.requester'),
			cell: (row) => {
				return <div className={'flex flex-col'}>
					<div>
						{row.requester?.firstName} {row.requester?.lastName}
					</div>
					<div>
						{row.requester?.company?.name}
					</div>
				</div>;
			},
			center: true,
			grow: 1
		},
		{
			name: t('supportSessions.table.supporters'),
			cell: (row) => {
				return <div>{row.supporters && row.supporters.map((supporter)=>{
					return <div key={supporter.id}>{supporter.firstName} {supporter.lastName}</div>;
				})}</div>;
			},
			center: true,
			grow: 1
		},
		{
			name: t('supportSessions.table.created'),
			cell: (row) => <div>{FormatDateWithTime(row.created)}</div> ,
			sortable: true,
			sortField:'created',
			width: '250px',
		},
		{
			name: t('supportSessions.table.closed'),
			cell: (row) => {
				if(row.closed !== null) {
					return <div>{FormatDateWithTime(row.closed)}</div>;
				} else {
					return <div>--</div>;
				}

			},
			center: true,
			sortable: true,
			sortField:'closed',
			width: '250px',
		},
		{
			name: t('supportSessions.table.participate'),
			cell: (row) => {

				if(!row.closed && row.supporterIDs && row.supporterIDs.find((userID)=> userID === auth.user?.id)){
					return <div className="justify-self-end">
						{t('supportSessions.table.active')}
					</div>;
				} else if(!row.closed && row.invitedSupporterIDs && row.invitedSupporterIDs.find((userID)=> userID === auth.user?.id)){
					return <div>
						{t('supportSessions.table.pending')}
					</div>;
				} else if(!row.closed) {
					return <div>
						{t('supportSessions.table.inactive')}
					</div>;
				}
			},
			sortable:true,
			sortField:'sortUserIsSupporting',
			center: true,
			grow: 1
		},
	];

	const conditionalRowStyles:ConditionalStyles<SupportSession> [] | undefined = [
		{
			when: row => true,
			style: {
				'&:hover': {
					backgroundColor: 'rgb(200, 200, 200)',
				},
			},
		},
	];

	return (
		<div className={'flex flex-col'}>
			<DataTable
				columns={colums}
				data={supportSessions}
				conditionalRowStyles={conditionalRowStyles}
				defaultSortFieldId={4}
				defaultSortAsc={false}
				sortServer
				pointerOnHover={true}
				onRowClicked={(item:SupportSession)=>navigate(APP_ROUTES.getSupportSessionRoute(item.id))}
				onSort={(column, sortDirection)=>onSortChange(column.sortField??'', sortDirection)}
			/>

			{!(nextPageToken === '@end' || nextPageToken === '') &&
				<Button
					wrapperClasses="py-5 self-center"
					label="Mehr laden"
					onClick={() => pagination.loadMore()}/>
			}
		</div>
	);
}