import classNames from 'classnames';
import React, {useEffect, useState} from 'react';
import {ChevronUpDownIcon} from '@heroicons/react/20/solid';
import './comboBox.css';

export interface RangeComboBoxItem {
    label: string
    value: number
}

interface Props {
    items: RangeComboBoxItem[]
    selected: RangeComboBoxItem | undefined
    setSelected: (selected: RangeComboBoxItem | undefined) => void
    title: string
    wrapperClasses?: string;
}

export default function RangeValuesComboBox(
	{
		items,
		selected, setSelected,
		title,
		wrapperClasses
	}: Props) {

	const [query, setQuery] = useState<string>('');
	const [filteredItems, setFilteredItems] = useState<RangeComboBoxItem[]>(items);
	const [optionsVisible, setOptionsVisible] = useState<boolean>(false);
	const [hasRendered, setHasRendered] = useState<boolean>(false);
	const [hoveredOption, setHoveredOption] = useState<RangeComboBoxItem | undefined>();

	useEffect(() => {
		if (!hasRendered) {
			setHasRendered(true);
		}
	}, [hasRendered]);

	useEffect(() => {
		let newFilteredItems: RangeComboBoxItem[] = structuredClone(items);
		if (query !== '') {
			newFilteredItems = newFilteredItems.filter((item) => item.label.toString().toLowerCase().includes(query.toLowerCase()));
		}
		setFilteredItems(newFilteredItems);
		setSelected(undefined);
		setHoveredOption(undefined);

		if (hasRendered) {
			setOptionsVisible(true);
		}
	}, [query]);

	useEffect(() => {
		if (selected) {
			setOptionsVisible(false);
		}
	}, [selected]);

	function onClickOption(item: RangeComboBoxItem) {
		setSelected(item);
	}

	return (
		<div className={classNames(wrapperClasses)}>
			<div className={'w-full relative flex flex-col'}>
				<div>
					{title}
				</div>
				<div className={'w-full flex border rounded-md border-gray-300 focus:border-blue-500 bg-white'}>
					<input
						type={'text'}
						className={'rangeValuesComboBox w-full'}
						value={selected ? selected.label : query ?? undefined}
						onChange={(event) => setQuery(event.target.value)}
						onFocus={() => setOptionsVisible(true)}
						onBlur={() => window.setTimeout(() => setOptionsVisible(false), 10)}/>

					<ChevronUpDownIcon
						className="h-5 w-5 text-gray-400 mt-2.5"
						onClick={() => setOptionsVisible(!optionsVisible)}
						aria-hidden="true"/>
				</div>

				{optionsVisible && filteredItems &&
					<div className={'z-40 w-full absolute mt-1 top-16 left-0 bg-white border rounded-md'}>

						{filteredItems.map((item) =>
							<div
								key={item.label}
								className={classNames('flex h-7 cursor-pointer',
									hoveredOption?.label === item.label ? 'bg-blue-500 text-white' : 'text-gray-900')}
								onMouseDown={(e) => e.preventDefault()}
								onClick={() => onClickOption(item)}
								onMouseEnter={() => setHoveredOption(item)}
							>
								<div className={'ml-4'}>
									{item.label}
								</div>
							</div>
						)}
					</div>
				}
			</div>
		</div>
	);
}