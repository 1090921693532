import FormatDate from '../../../../utils/date';
import PriceToComma from '../../../../utils/priceFormatting';
import React from 'react';
import {Order} from '../../../../interfaces';
import {useTranslation} from 'react-i18next';

interface Props {
    order:Order
}

export const OrderDetails = (props:Props) => {

	const {t} = useTranslation();

    
	return (
		<div className="flex gap-20">
			<div>
				<div className={'text-2xl'}>{t('orderDetails.buyerUser')}</div>
				<div>{props.order.buyerUser?.firstName} {props.order.buyerUser?.lastName} </div>
			</div>

			<div className={'flex flex-col'}>
				<div className={'text-2xl'}>{t('orderDetails.buyerCompany')}</div>
				<div>{props.order.buyerCompany?.name}</div>
			</div>

			<div className={'flex flex-col'}>
				<div className={'text-2xl'}>{t('orderDetails.sellerCompany')}</div>
				<div>{props.order.sellerCompany?.name}</div>
			</div>

			<div>
				<div className="text-2xl">{t('orderDetails.deliveryAddress')}</div>
				<div>{props.order.delivery.address.title}</div>
				<div>{props.order.delivery.address.country}</div>
				<div>{props.order.delivery.address.zip} {props.order.delivery.address.city}</div>
				<div>{props.order.delivery.address.street} {props.order.delivery.address.streetNumber}</div>
			</div>

			<div>
				<div className="text-2xl">{t('orderDetails.created')}</div>
				<div>{FormatDate(props.order.created)}</div>
			</div>

			<div>
				<div className="text-2xl">{t('orderDetails.furtherInformation')}</div>
				<div>Anzahl Artikel: {props.order.positions.length}</div>
				<div>Gesamtpreis netto: {PriceToComma(props.order.priceNet / 100)} €</div>
				<div>Gesamtpreis brutto: {PriceToComma(props.order.priceGross / 100)} €</div>
			</div>
		</div>
	);
};