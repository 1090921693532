import React, {useState} from 'react';
import CreateProductInformationTab from './tabs/CreateProductInformationTab';
import Button from '../../../../components/button/Button';
import {IconType} from '../../../../components/button/IconButton';
import {useNavigate, useParams} from 'react-router-dom';
import {APP_ROUTES, Constants} from '../../../../constants';
import DescriptionTab from '../tabs/description/DescriptionTab';
import CheckBox from '../../../../components/checkBox/CheckBox';
import PropertiesTab from '../tabs/properties/PropertiesTab';
import ProductManagementTabBar from '../tabs/ProductManagementTabBar';
import {ProductIsUploadingModal} from './components/ProductIsUploadingModal';
import {checkProperties} from '../helpers';
import {FileInterface, Manufacturer, Property, VariantChangeble,} from '../../../../interfaces';
import {API} from '../../../../api';
import EditVariantsTab from '../tabs/variants/EditVariantsTab';
import {VariantBuilderMap} from '../../../../utils';
import {useErrorHandling} from '../../../../providers/error/ErrorProvider';
import CreateProductImageTab from './tabs/image/CreateProductImageTab';
import CreateProductFilesTab from './tabs/files/CreateProductFilesTab';
import Necessity = Constants.Necessity;


export default function CreateProduct() {
	const {categoryId} = useParams<string>();
	const navigate = useNavigate();
	const errorHandling = useErrorHandling();

	const [errorMessage, setErrorMessage] = useState<string>('');
	const [isUploadingModalVisible, setIsUploadingModalVisible] = useState<boolean>(false);
	const [tabIndex, setTabIndex] = useState<number>(0);

	const [name, setName] = useState<string>('');
	const [manufacturer, setManufacturer] = useState<Manufacturer>();
	const [manufacturerArticleNumber, setManufacturerArticleNumber] = useState<string>('');
	const [isActive, setIsActive] = useState<boolean>(false);
	const [amountAvailable, setAmountAvailable] = useState<number>(0);
	const [price, setPrice] = useState<number>(0);
	const [salePercentage, setSalePercentage] = useState<number | undefined>(undefined);
	const [saleEndDate, setSaleEndDate] = useState<string | undefined>(undefined);
	const [singleProductproperties, setSingleProductproperties] = useState<Property[]>([]);

	const [shortDescription, setShortDescription] = useState<string>('');
	const [longDescription, setLongDescription] = useState<string>('');

	const [images, setImages] = useState<FileInterface[]>([]);
	const [plans, setPlans] = useState<FileInterface[]>([]);

	const [dataSheets, setDataSheets] = useState<FileInterface[]>([]);
	const [dxfFiles, setDxfFiles] = useState<FileInterface[]>([]);
	const [stpFiles, setStpFiles] = useState<FileInterface[]>([]);


	const [variantBuilderMap, setVariantBuilderMap] = useState<VariantBuilderMap>(new VariantBuilderMap([]));
	const [hasVariants, setHasVariants] = useState<boolean>(false);

	const [isEditPropertiesTableVisible, setIsEditPropertiesTableVisible] = useState<boolean>(true);

	const [variantProperties, setVariantProperties] = useState<Property[]>([]);


	const onCheckHasVariants = (hasVariants:boolean) => {
		setHasVariants(hasVariants);
		setVariantBuilderMap(new VariantBuilderMap([]));
		setVariantProperties([]);
		setIsEditPropertiesTableVisible(true);
		setSingleProductproperties([]);
	};


	const onSaveAndExit = async () => {
		setErrorMessage('');
		if (!manufacturer) {
			setErrorMessage('Hersteller fehlt');
		} else if (name === '') {
			setErrorMessage('Name fehlt');
		} else {

			if (hasVariants) {
				const createNewVariants: VariantChangeble[] = variantBuilderMap.getAllChangeablesNonDeleted();

				if (createNewVariants.length < 2) {
					setErrorMessage('Bitte mindestens 2 Varianten anlegen');
				} else {
					try {
						setIsUploadingModalVisible(true);
						await API.Product.createWithVariants(
							name,
							shortDescription,
							longDescription,
							categoryId ?? '',
							manufacturer.id,
							images.concat(plans).concat(dataSheets).concat(dxfFiles).concat(stpFiles),
							variantProperties.find(elem => elem.necessity === Necessity.Variation) ?? {
								name: '',
								filterId: '',
								necessity: '',
								unit: undefined,
								value: ''
							},
							variantProperties.filter(elem => elem.necessity === Necessity.Universal),
							variantProperties.filter(elem => elem.necessity === Necessity.Specific),
							createNewVariants);
						setIsUploadingModalVisible(false);
						navigate(APP_ROUTES.products);
					} catch (e: any) {
						errorHandling(e);
					}
				}
			} else {
				if (manufacturerArticleNumber === '') {
					setErrorMessage('Herstellernummer fehlt');
				} else if (categoryId === '') {
					setErrorMessage('Kategorie fehlt');
				} else if (amountAvailable === 0 || Number.isNaN(amountAvailable)) {
					setErrorMessage('Stückzahl fehlt');
				} else if (!price || price === 0) {
					setErrorMessage('Preis fehlt');
				} else if (salePercentage && ((salePercentage ? salePercentage : 0) <= 0)) {
					setErrorMessage('Sale Prozentzahl fehlt');
				} else if (saleEndDate && (saleEndDate ? (new Date(saleEndDate) < new Date()) : false)) {
					setErrorMessage('Sale Enddatum auswählen');
				} else if (!checkProperties(singleProductproperties)) {
					setErrorMessage('Eigenschaften sind unvollständig');
				} else {
					try {
						setIsUploadingModalVisible(true);
						await API.Product.createSingle(
							singleProductproperties,
							name,
							manufacturerArticleNumber,
							isActive,
							shortDescription,
							longDescription,
							price,
							categoryId ?? '',
							manufacturer.id,
							amountAvailable,
							images.concat(plans).concat(dataSheets).concat(dxfFiles).concat(stpFiles),
							salePercentage ? {percentage: salePercentage, endDate: saleEndDate} : undefined
						);
						setIsUploadingModalVisible(false);
						navigate(APP_ROUTES.products);
					} catch (e: any) {
						errorHandling(e);
					}
				}
			}
		}
	};

	return (
		<div className={'px-8 mt-3 w-full'}>
			<div className={'flex border-b border-gray-200'}>

				<div className={'flex gap-5 flex-1'}>
					<ProductManagementTabBar
						wrapperClasses={'flex gap-5 flex-1'}
						tabIndex={tabIndex}
						setTabIndex={setTabIndex}
						hasVariants={hasVariants}/>
				</div>


				<div className="flex self-center">
					<Button
						label="Abbrechen"
						onClick={()=>navigate(APP_ROUTES.products)}
						icon={IconType.WITHOUT} />
					<Button
						wrapperClasses="pl-5"
						label="Speichern und beenden"
						onClick={()=>onSaveAndExit()}
						icon={IconType.CHECK} />
				</div>
			</div>


			{tabIndex === 0 &&
				<div className={'pt-8'}>
					<CheckBox
						id={'hasVariants'}
						label={'Soll das Produkt Varianten besitzen?'}
						checked={hasVariants}
						onChange={(e)=>onCheckHasVariants(e.target.checked)}/>

					<CreateProductInformationTab
						wrapperClasses={'pt-8'}
						hasVariants={hasVariants}
						name={name}
						setName={setName}
						manufacturer={manufacturer}
						setManufacturer={setManufacturer}
						manufacturerArticleNumber={manufacturerArticleNumber}
						setManufacturerArticleNumber={setManufacturerArticleNumber}
						isActive={isActive}
						setIsActive={setIsActive}
						amountAvailable={amountAvailable}
						setAmountAvailable={setAmountAvailable}
						price={price}
						setPrice={setPrice}
						salePercentage={salePercentage}
						setSalePercentage={setSalePercentage}
						saleEndDate={saleEndDate}
						setSaleEndDate={setSaleEndDate}/>
				</div>
			}

			{tabIndex === 1 && hasVariants &&
				<EditVariantsTab
					wrapperClasses={'pt-8 w-full'}
					isEditPropertiesTableVisible={isEditPropertiesTableVisible}
					setIsEditPropertiesTableVisible={setIsEditPropertiesTableVisible}
					variantBuilderMap={variantBuilderMap}
					setVariantBuilderMap={setVariantBuilderMap}
					properties={variantProperties}
					setProperties={setVariantProperties}
					categoryId={categoryId??''}
					errorMessage={errorMessage}
					setErrorMessage={setErrorMessage}
					isSalePossible={true}/>
			}

			{tabIndex === 1 && !hasVariants &&
				<PropertiesTab
					wrapperClasses={'w-1/3 pt-8'}
					categoryId={categoryId ?? ''}
					properties={singleProductproperties}
					setProperties={setSingleProductproperties}/>
			}

			{tabIndex === 2 &&
				<DescriptionTab
					wrapperClasses={'pt-8'}
					shortDescription={shortDescription}
					setShortDescription={setShortDescription}
					longDescription={longDescription}
					setLongDescription={setLongDescription}/>
			}

			{tabIndex === 3 &&
				<CreateProductImageTab
					wrapperClasses={'w-1/2'}
					images={images}
					setImages={setImages}
					plans={plans}
					setPlans={setPlans}/>
			}

			{tabIndex === 4 &&
				<CreateProductFilesTab
					wrapperClasses={'w-2/3 pt-8'}
					dataSheets={dataSheets}
					setDataSheets={setDataSheets}
					dxfFiles={dxfFiles}
					setDxfFiles={setDxfFiles}
					stpFiles={stpFiles}
					setStpFiles={setStpFiles}/>
			}

			{errorMessage != '' &&
				<div className="flex justify-center">
					<div className="text-red-600 py-2">{errorMessage}</div>
				</div>
			}

			<ProductIsUploadingModal
				isVisible={isUploadingModalVisible}
				onClose={()=>setIsUploadingModalVisible(false)}/>
		</div>
	);
}
