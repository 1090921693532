import React, {createContext, useContext, useEffect, useState} from 'react';
import {useWebsocketConnection, WebsocketMessage} from '../websocket/websocketProvider';
import {useAuth} from '../auth/useAuth';
import {NotificationType, NotificationWebsocketData} from '../../interfaces';
import {useNavigate} from 'react-router-dom';
import {toast} from 'sonner';
import {HiOutlineInformationCircle} from 'react-icons/hi';
import {APP_ROUTES} from '../../constants';
import {useTranslation} from 'react-i18next';
import {Notification} from '../../interfaces/notification';
import {
	useLazyListNotificationsQuery,
	useReadAllNotificationsMutation, useReadNotificationMutation,
	useSubscribeWebsocketMutation,
	useUnsubscribeWebsocketMutation
} from '../../api/ReduxAPI';
import {useErrorHandling} from '../error/ErrorProvider';


export enum NotificationStatus {
    success,
    failure,
    hint
}

export type NotificationDisplayed = {
	id:string
    status: NotificationStatus
    title: string
    message: string
	deeplink:string | undefined
}

export interface NotificationContextType {
	readAllNotifications:()=>void
	readNotification:(id:string)=>void
	unreadNotifications: NotificationDisplayed[]
}


const NotificationContext = createContext<NotificationContextType>({
	readAllNotifications: () => null,

	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	readNotification:(id: string) => null,
	unreadNotifications: []
});

export const useNotification = () => {
	return useContext(NotificationContext);
};


interface Props {
    children: React.ReactNode
}


export const NotificationProvider: React.FC<Props> = (props: Props) => {

	const websocketConn = useWebsocketConnection();
	const auth = useAuth();
	const navigate = useNavigate();
	const {t} = useTranslation();
	const errorHandling = useErrorHandling();

	const [readAllNotificationsTrigger] = useReadAllNotificationsMutation();
	const [readNotificationTrigger] = useReadNotificationMutation();
	const [listNotifications, {data:listNotificationsResp, isSuccess:notificationsLoaded}] = useLazyListNotificationsQuery();

	const [subscribeWebsocket,{isSuccess:subscribeWebsocketSuccess}] = useSubscribeWebsocketMutation();
	const [unsubscribeWebsocket] = useUnsubscribeWebsocketMutation();

	const [unreadNotifications, setUnreadNotifications] = useState<NotificationDisplayed[]>([]);


	useEffect(() => {
		try {
			subscribeWebsocket();
		} catch (e:any){
			console.error(e);
		}

		return () => {
			if(websocketConn.websocket){
				try {
					unsubscribeWebsocket();
				} catch (e:any){
					errorHandling(e);
				}
			}
		};
	}, []);

	useEffect(() => {
		if(subscribeWebsocketSuccess){
			try {
				listNotifications({read:false});
			} catch (e:any){
				errorHandling(e);
			}
		}
	}, [subscribeWebsocketSuccess]);


	useEffect(() => {
		if(notificationsLoaded){

			if(!listNotificationsResp){
				errorHandling(new Error('listNotifications failed'));
				return;
			}

			const notifications:Notification[] = listNotificationsResp.notifications;
			const notificationsDisplayed:NotificationDisplayed[] = [];

			for (let i = 0; i < notifications.length; i++) {

				const data:Map<string,string> = new Map<string, string>(Object.entries(notifications[i].data));

				const item:NotificationDisplayed = createUnreadNotification(
					notifications[i].id,
					notifications[i].notificationType,
					data
				);
				notificationsDisplayed.push(item);
			}
			setUnreadNotifications(notificationsDisplayed);
		}
	}, [notificationsLoaded]);


	useEffect(() => {
		if (websocketConn && websocketConn.websocket){

			websocketConn.websocket.addEventListener('message', (msg)=>{

				const wsMessage:WebsocketMessage = JSON.parse(msg.data);

				if (wsMessage.subscriptionID === auth.user?.notificationSubscriptionID) {

					const newNotification:NotificationWebsocketData = JSON.parse(wsMessage.data);

					const data:Map<string,string> = new Map<string, string>(Object.entries(newNotification.data));

					sendToastByNotificationType(
						newNotification.notificationID,
						newNotification.notificationType,
						data
					);

					const newUnreadNotification:NotificationDisplayed = createUnreadNotification(
						newNotification.notificationID,
						newNotification.notificationType,
						data
					);

					setUnreadNotifications(unreadNotifications.concat(newUnreadNotification));
				}
			});
		}
	}, [websocketConn.websocket]);


	// ====================================================================================================================
	const sendToastByNotificationType = (notificationID:string, notificationType:NotificationType, data:Map<string,string>) => {

		if(!data) {
			throw Error('sendToastByNotificationType data is undefined');
		}

		switch (notificationType) {

		case NotificationType.SupportSessionMessage:

			if (!data.has('supportSessionID')){
				throw Error('sendToastByNotificationType: supportSessionID is missing in data');
			} else {

				toast(t('notifications.supportSession.newMessage.title'), {
					description: t('notifications.supportSession.newMessage.message'),
					duration: 300000,
					icon:<HiOutlineInformationCircle
						className="h-6 w-6 text-yellow-400"
						aria-hidden="true"
					/>,
					closeButton: true,
					action: {
						label: t('notifications.actions.deeplink'),
						onClick: () => navigate(APP_ROUTES.getSupportSessionRoute(data.get('supportSessionID') ?? ''))
					}
				});
			}
			break;

		case NotificationType.SupportSessionInvitation:

			if (!data.has('supportSessionID')){
				throw Error('sendToastByNotificationType: supportSessionID is missing in data');
			} else {

				toast(t('notifications.supportSession.invited.title'), {
					description: t('notifications.supportSession.invited.message'),
					duration: 300000,
					icon:<HiOutlineInformationCircle
						className="h-6 w-6 text-yellow-400"
						aria-hidden="true"
					/>,
					closeButton: true,
					action: {
						label: t('notifications.actions.deeplink'),
						onClick: () => navigate(APP_ROUTES.getSupportSessionRoute(data.get('supportSessionID') ?? ''))
					}
				});
			}
			break;
		}
	};

	// ====================================================================================================================
	const createUnreadNotification = (notificationID:string, notificationType:NotificationType, data:Map<string,string>):NotificationDisplayed => {

		if(!data){
			throw Error('createUnreadNotification: data missing');
		}

		switch (notificationType) {

		case NotificationType.SupportSessionMessage:
			if (!data.has('supportSessionID')){
				throw Error('createUnreadNotification: supportSessionID is missing in data');
			} else {
				return {
					id:notificationID,
					title:t('notifications.supportSession.newMessage.title'),
					deeplink: APP_ROUTES.getSupportSessionRoute(data.get('supportSessionID') ?? ''),
					status:NotificationStatus.hint,
					message:t('notifications.supportSession.newMessage.message')
				};
			}

		case NotificationType.SupportSessionInvitation:
			if (!data.has('supportSessionID')){
				throw Error('createUnreadNotification: supportSessionID is missing in data');
			} else {
				return {
					id:notificationID,
					title:t('notifications.supportSession.invited.title'),
					deeplink: APP_ROUTES.getSupportSessionRoute(data.get('supportSessionID') ?? ''),
					status:NotificationStatus.hint,
					message:t('notifications.supportSession.invited.message')
				};
			}

		default: throw Error('createUnreadNotification: NotificationType is invalid');
		}
	};
	// ====================================================================================================================

	const readNotification = (notificationID:string) => {
		readNotificationTrigger({notificationID});
		const indexToDelte:number = unreadNotifications.findIndex((item) => item.id === notificationID);

		const newArr:NotificationDisplayed[] = unreadNotifications;
		newArr.splice(indexToDelte, 1);

		setUnreadNotifications(newArr);
	};

	const readAllNotifications = () => {
		readAllNotificationsTrigger();
		setUnreadNotifications([]);
	};

	const value = {unreadNotifications, readNotification, readAllNotifications};
	return (
		<NotificationContext.Provider value={value}>
			{props.children}
		</NotificationContext.Provider>
	);
};