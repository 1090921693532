import {Modal} from '../modal/Modal';
import React from 'react';
import {
	ArrowDownTrayIcon,
	MagnifyingGlassMinusIcon,
	MagnifyingGlassPlusIcon,
	XMarkIcon
} from '@heroicons/react/20/solid';
import {endPointGetImage} from '../../constants';
import uuid from 'react-uuid';
import axios from 'axios';


interface Props  {
    imageID:string
	isVisible: boolean
	onClose: () => void
}

export const ImageDetailedView = (props:Props) => {


	return (
		<Modal isOpen={props.isVisible} handleClose={props.onClose}>
			<div className="relative z-10">
				<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
				<div className="fixed z-10 inset-0 overflow-hidden">

					<div className="flex flex-row justify-end">
						<div className={'pt-5 pr-5'}>
							<XMarkIcon
								className="h-12 w-12 text-gray-50 bg-gray-500 hover:bg-gray-600 rounded-full cursor-pointer"
								aria-hidden="true"
								type={'button'}
								onClick={props.onClose}
							/>
						</div>
					</div>

					<div className={'w-full h-full flex justify-center'}>
						<div className={'w-full h-full flex flex-col gap-8 place-items-center'}>
							<img
								src={endPointGetImage(props.imageID)}
								className="w-400 h-max object-scale-down"
								alt={''}
								id={'img' + uuid()}
							/>

							<div className={'py-2 px-2 flex gap-8 text-gray-50 bg-gray-500 border-none rounded-xl'}>
								<ArrowDownTrayIcon
									className="h-8 w-8 hover:text-gray-300 cursor-pointer"
									aria-hidden="true"
									type={'button'}
									onClick={()=> window.open(axios.defaults.baseURL + '/files/' + props.imageID + '/download', '_blank')}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Modal>
	);
};