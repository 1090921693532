import {useState} from 'react';


type UsePagination = () => {
    nextPageToken:string
    loadMore: () => void
    newToken: (token:string)=> void
}

export const usePagination:UsePagination = () => {

	const [currentToken, setCurrentToken] = useState<string>('');
	const [nextPageToken, setNextPageToken] = useState<string>('');

	const newToken = (token:string) => {
		setCurrentToken(token);
	};

	const loadMore = () => {
		setNextPageToken(currentToken);
	};

	return {
		loadMore,
		nextPageToken,
		newToken
	};
};