import React, {useState} from 'react';
import axios from 'axios';
import GetCountryByCode, {Country} from '../../../../utils/country';
import {ENDPOINTS} from '../../../../constants';
import Button from '../../../../components/button/Button';
import {Modal} from '../../../../components/modal/Modal';
import {IconType} from '../../../../components/button/IconButton';
import {addressTitileRegex, cityRegEx, streetNumberRegEx, streetRegEx, zipRegEx} from '../../../../utils/regex';
import AddressSelection from './AddressSelection';
import {Address, Company} from '../../../../interfaces';


interface Props {
	company: Company
    address: Address
	isVisible: boolean
	onClose: () => void
	onSuccess: () => void
}

export default function EditAddressModal({isVisible, onClose, onSuccess, company, address}:Props){

	const [inputFieldError, setInputFieldError] = useState<string>('');

	const [title, setTitle] = useState<string>(address.title);
	const [country, setCountry] = useState<Country>(GetCountryByCode(address.country));
	const [zip, setZip] = useState<string>(address.zip);
	const [city, setCity] = useState<string>(address.city);
	const [street, setStreet] = useState<string>(address.street);
	const [streetNumber, setStreetNumber] = useState<string>(address.streetNumber);


	const onFinishButtonClick = async () => {
		setInputFieldError('');

		if (title === '' || !addressTitileRegex.test(title)) {
			setInputFieldError('Standortname ungültig');
		} else if (zip === '' || !zipRegEx.test(zip)){
			setInputFieldError('Postleitzahl ungültig');
		} else if (city === '' || !cityRegEx.test(city)) {
			setInputFieldError('Stadt ungültig');
		} else if (street === '' || !streetRegEx.test(street)) {
			setInputFieldError('Straße ungültig');
		} else if (streetNumber === '' || !streetNumberRegEx.test(streetNumber)) {
			setInputFieldError('Straßennummer ungültig');
		} else {
			const newAddresses = company.addresses;

			const indexToReplace = newAddresses.findIndex((e) =>{
				return ((e.country === address.country)
					&& (e.city === address.city)
					&& (e.zip === address.zip)
					&& (e.street === address.street)
					&& (e.streetNumber === address.streetNumber));
			});

			newAddresses[indexToReplace] = {
				country: country.code.trim(), zip: zip.trim(), city: city.trim(),
				street: street.trim(), streetNumber: streetNumber.trim(), title: title.trim()
			};
			
			axios.put(ENDPOINTS.updateUserCompany,
				{'addresses':newAddresses})
				.then(function (response) {
					onSuccess();
				})
				.catch(errors => {
					setInputFieldError('Neuer Standort hinzufügen fehlgeschlagen.');
				});
		}
	};

	return(
		<Modal isOpen={isVisible} handleClose={()=>onClose()}>
			<div className="relative z-10">
				<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
				<div className="fixed z-10 inset-0 overflow-y-auto">
					<div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">

						<div className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden
								shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6">


							<div className="flex flex-row justify-end">
								<button
									type="button"
									className="text-2xl"
									onClick={()=>onClose()}>
										X
								</button>
							</div>

							<AddressSelection
								title={title}
								setTitle={setTitle}
								country={country}
								setCountry={setCountry}
								zip={zip}
								setZip={setZip}
								city={city}
								setCity={setCity}
								street={street}
								setStreet={setStreet}
								streetNumber={streetNumber}
								setStreetNumber={setStreetNumber}/>

							<div className="flex justify-end py-4">
								<Button
									onClick={()=>onFinishButtonClick()}
									label="Fertig"
									icon={IconType.CHECK}/>
							</div>

							{inputFieldError != '' &&
								<div className="flex justify-center">
									<div className="text-red-600 py-2">{inputFieldError}</div>
								</div>
							}
						</div>
					</div>
				</div>
			</div>
		</Modal>
	);
}
