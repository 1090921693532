import React from 'react';
import classNames from 'classnames';

interface Props {
	label: string
	title?: string
	id?: string
	onChange?: React.ChangeEventHandler<HTMLInputElement>;
	checked?: boolean
	description?: string
	disabled?: boolean
	wrapperClasses?: string
}

const CheckBox = ({onChange, id, checked = false, label, title, description, disabled, wrapperClasses}: Props) => {
	return (
		<div className={classNames('relative flex items-start', wrapperClasses)}>
			<div className="flex h-5 items-center pt-1">
				<input
					checked={checked}
					id={id}
					aria-describedby="comments-description"
					name="comments"
					type="checkbox"
					className="h-4 w-4 rounded border-gray-300"
					onChange={onChange}
					disabled={disabled}
					title={title}
				/>
			</div>
			<div className="ml-3">
				<label
					htmlFor={id}
					title={title}
				>
					{label}
				</label>
				
				<p id={id}>
					{description}
				</p>
			</div>
		</div>
	);
};

export default CheckBox;
