import React, {useEffect, useState} from 'react';
import {PropertyFilter, PropertyFilterItem} from './components/PropertyFilter';
import {Popover} from '@headlessui/react';
import {RangeFilter, RangeFilterItem} from './components/RangeFilter';
import {AppliedFilter, Filter, FilterType, PropertyValues} from '../../interfaces';
import classNames from 'classnames';

interface Props {
	filters: Filter[]
	onChange: (newAppliedFilters: AppliedFilter[]) => void
	wrapperClasses?: string
}


export const FilterList: React.FC<Props> = (
	{
		filters,
		onChange,
		wrapperClasses
	}: Props) => {

	const [rangeFilterItems, setRangeFilterItems] = useState<RangeFilterItem[]>([]);
	const [propertyFilterItems, setPropertyFilterItems] = useState<PropertyFilterItem[]>([]);


	// creating range-and proptery-filter-items from given filters
	useEffect(() => {
		const newRangeFilterItems: RangeFilterItem[] = [];
		const newPropertyFilterItems: PropertyFilterItem[] = [];

		if (filters) {
			for (let i = 0; i < filters.length; i++) {

				if (filters[i].filterType === FilterType.RangeFilter) {
					newRangeFilterItems.push(
						{
							id: filters[i].id,
							name: filters[i].name,
							unit: filters[i].unit ?? '',
							possibleValues: filters[i].filterValues.rangeValues,
							selectedMinValue: filters[i].selectedFilterValues?.rangeMin,
							selectedMaxValue: filters[i].selectedFilterValues?.rangeMax,
							relevance: filters[i].filterValues.relevance
						}
					);
				} else if (filters[i].filterType === FilterType.PropertyFilter) {
					newPropertyFilterItems.push(
						{
							id: filters[i].id,
							name: filters[i].name,
							selectedValues: filters[i].selectedFilterValues?.propertyValues ?? [],
							possibleValues: filters[i].filterValues.propertyValues.map((propValue: PropertyValues) => propValue.value),
							relevance: filters[i].filterValues.relevance
						}
					);
				}
			}
		}
		setRangeFilterItems(newRangeFilterItems);
		setPropertyFilterItems(newPropertyFilterItems);
	}, [JSON.stringify(filters)]);


	function buildAppliedFilters(propertyFilterItems: PropertyFilterItem[], rangeFilterItems: RangeFilterItem[]): AppliedFilter[] {

		const appliedFilters: AppliedFilter[] = [];

		for (let i = 0; i < rangeFilterItems.length; i++) {
			if (rangeFilterItems[i].selectedMinValue || rangeFilterItems[i].selectedMaxValue) {
				appliedFilters.push(
					{
						filterId: rangeFilterItems[i].id,
						propertyValues: undefined,
						maxRange: rangeFilterItems[i].selectedMaxValue,
						minRange: rangeFilterItems[i].selectedMinValue
					}
				);
			}
		}

		for (let i = 0; i < propertyFilterItems.length; i++) {
			if (propertyFilterItems[i].selectedValues && propertyFilterItems[i].selectedValues.length > 0) {
				appliedFilters.push(
					{
						filterId: propertyFilterItems[i].id,
						propertyValues: propertyFilterItems[i].selectedValues,
						minRange: undefined,
						maxRange: undefined
					}
				);
			}
		}

		return appliedFilters;
	}


	function onChangePropertyFilterItem(changedItem: PropertyFilterItem) {
		const newPropertyFilterItems: PropertyFilterItem[] = structuredClone(propertyFilterItems);

		const indexToReplace: number = propertyFilterItems.findIndex((item) => item.id === changedItem.id);

		newPropertyFilterItems[indexToReplace] = changedItem;

		onChange(buildAppliedFilters(newPropertyFilterItems, rangeFilterItems));
	}


	function onChangeRangeFilterItem(changedItem: RangeFilterItem) {
		const newRangeFilterItems: RangeFilterItem[] = structuredClone(rangeFilterItems);

		const indexToReplace: number = rangeFilterItems.findIndex((item) => item.id === changedItem.id);
		if (indexToReplace < 0) {
			throw new Error('onChangeRangeFilterItem cant find id of changed object in propertyFilterItems');
		}

		newRangeFilterItems[indexToReplace] = changedItem;

		onChange(buildAppliedFilters(propertyFilterItems, newRangeFilterItems));
	}

	// TODO sort them after relevance
	return (
		<div className={classNames(wrapperClasses)}>
			<div className="flex items-center justify-between">
				<Popover.Group className=" sm:flex sm:items-baseline sm:space-x-8">
					{
						propertyFilterItems && propertyFilterItems.map((item) => (
							<div key={'propertyFilterList' + item.id}>
								<PropertyFilter
									propertyFilterItem={item}
									onChangePropertyFilterItem={onChangePropertyFilterItem}/>
							</div>
						))
					}
					{
						rangeFilterItems && rangeFilterItems.map((item) => (
							<div key={'rangeFilterList' + item.id}>
								<RangeFilter
									rangeFilterItem={item}
									onChangeRangeFilterItem={onChangeRangeFilterItem}/>
							</div>
						))
					}
				</Popover.Group>
			</div>
		</div>
	);
};

