import {getEmptyVariant} from './emptyVariant';
import uuid from 'react-uuid';
import {Constants} from '../constants';
import {Property, SaleType, Variant} from '../interfaces';
import {API} from '../api';
import {useErrorHandling} from '../providers/error/ErrorProvider';
import Necessity = Constants.Necessity;


export class VariantBuilder {

	constructor(state: VariantBuilderState, variant?: Variant) {
		if (variant) {
			this.displayed = variant;
		} else {
			this.displayed = getEmptyVariant();
		}
		this.state = state;
		this.id = uuid();
	}

	// id to identify in map
	private readonly id: string;

	// displayed variant in UI
	private displayed: Variant;

	// state defines which api-call is made
	private state: VariantBuilderState = VariantBuilderState.display;

	getId(): string {
		return this.id;
	}

	setState(state: VariantBuilderState) {
		this.state = state;
	}

	getState(): VariantBuilderState {
		return this.state;
	}

	getDisplayed(): Variant {
		return this.displayed;
	}

	setChange(change: VariantChangeble) {
		this.displayed = {
			...this.displayed,
			...change,
			effectiveSale: change.saleChangeable ? {
				percentage: change.saleChangeable?.percentage,
				endDate: change.saleChangeable?.endDate,
				price: undefined,
				saleType: SaleType.normalSaleType,
				buyerId: undefined
			} : undefined,
			properties: change.properties,
		};
	}

	getChange(): VariantChangeble {
		return {
			active: this.displayed.active,
			amountAvailable: this.displayed.amountAvailable,
			manufacturerArticleNumber: this.displayed.manufacturerArticleNumber,
			price: this.displayed.price,
			shortDescription: this.displayed.shortDescription,
			longDescription: this.displayed.longDescription,
			properties: this.displayed.properties,
			saleChangeable: this.displayed.effectiveSale ?
				{
					percentage: this.displayed.effectiveSale.percentage,
					endDate: this.displayed.effectiveSale.endDate
				}
				: undefined
		};
	}

	/*
	* set changes or new properties. Keep values of unchanged properties
	*/
	adjustProperties(inProperties: Property[]) {
		let newProperties: Property[] = this.getDisplayed().properties;

		// variation property
		const variantProperty: Property | undefined = inProperties.find(
			(elem) => elem.necessity === Necessity.Variation);

		if (variantProperty) {
			const indexToDelete: number = newProperties.findIndex((elem) => (elem.necessity === Necessity.Variation)
				&& (elem.filterId !== variantProperty.filterId));

			if (indexToDelete !== -1) {
				newProperties.splice(indexToDelete, 1);
				newProperties.push(variantProperty);
			}
		}

		// universal properties
		const universalProperties: Property[] = structuredClone(inProperties.filter(
			elem => elem.necessity === Necessity.Universal));
		newProperties = newProperties.filter(elem => elem.necessity !== Necessity.Universal).concat(universalProperties);

		// specific properties
		const specificProperties: Property[] = structuredClone(inProperties.filter(
			elem => elem.necessity === Necessity.Specific));

		let newSpecificPropterties: Property[] = [];

		for (let j = 0; j < specificProperties.length; j++) {
			const index: number = newProperties.findIndex(elem => elem.filterId === specificProperties[j].filterId);
			if (index >= 0) {
				newSpecificPropterties = newSpecificPropterties.concat(structuredClone(newProperties[index]));
			} else {
				newSpecificPropterties = newSpecificPropterties.concat(structuredClone(specificProperties[j]));
			}
		}
		newProperties = newProperties.filter(elem => elem.necessity !== Necessity.Specific).concat(newSpecificPropterties);

		this.setChange({...this.getChange(), properties: newProperties});
	}

	async apiCall(productId: string) {
		const errorHandling = useErrorHandling();

		switch (this.state) {
		case VariantBuilderState.create:
			try {
				await API.Variant.create(
					productId,
					this.displayed.properties,
					this.displayed.manufacturerArticleNumber,
					this.displayed.active,
					this.displayed.shortDescription,
					this.displayed.longDescription,
					this.displayed.price,
					this.displayed.amountAvailable,
					this.getChange().saleChangeable);
			} catch (e: any) {
				errorHandling(e);
			}

			break;

		case VariantBuilderState.update:
			if (this.displayed.id && this.displayed.productId) {
				try {
					await API.Variant.update(
						this.displayed.productId,
						this.displayed.id,
						this.displayed.active,
						this.displayed.price,
						this.displayed.effectiveSale ?
							{
								percentage: this.displayed.effectiveSale.percentage,
								endDate: this.displayed.effectiveSale.endDate
							}
							: undefined,
						this.displayed.amountAvailable,
						this.displayed.manufacturerArticleNumber,
						this.displayed.shortDescription,
						this.displayed.longDescription,
						this.displayed.properties);
				} catch (e: any) {
					errorHandling(e);
				}
			} else {
				throw new Error('updateVariant failed');
			}
			break;

		case VariantBuilderState.delete:
			if (this.displayed.id && this.displayed.productId) {
				try {
					await API.Variant.delete(this.displayed.productId, this.displayed.id);
				} catch (e: any) {
					errorHandling(e);
				}
			} else {
				throw new Error('deleteVariant failed');
			}
			break;
		}
	}
}

export enum VariantBuilderState {
    display,
    create,
    update,
    delete
}

export type VariantChangeble = {
    active: boolean
    amountAvailable: number
    manufacturerArticleNumber: string
    price: number
    shortDescription: string
    longDescription: string
    properties: Property[]
    saleChangeable: SaleChangeble | undefined;
}

export type SaleChangeble = {
    percentage: number
    endDate: string | undefined
}